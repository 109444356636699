import {
	FETCH_RESEND_OTP_REQUEST,
	FETCH_RESEND_OTP_SUCCESS,
	FETCH_RESEND_OTP_FAILURE,
} from '../../types/user';
import * as CryptoJS from "crypto-js";
import settings from '../../settings';

import { apiToastSuccess, apiToastError, getDialCodeFromCountryCode } from '../../modules/utils';
import { postApi } from '../api';
import { countryList } from '../../modules/countryList';

export const fetchResendOtpRequest = () => ({
	type: FETCH_RESEND_OTP_REQUEST,
});

export const fetchResendOtpSuccess = (data) => ({
	type: FETCH_RESEND_OTP_SUCCESS,
	data,
});

export const fetchResendOtpFailure = (error) => ({
	type: FETCH_RESEND_OTP_FAILURE,
	error,
});

export const fetchResendOtp = (data) => (dispatch) => {
	const formData = {};

	dispatch(fetchResendOtpRequest());

	return postApi(settings.apiUrl.user.resendOtp, formData)
		.then((data) => {
			if (data?.data?.responseCode === 200) {
				if (data?.data?.responseData?.user?.authentication_token) {
					localStorage.setItem(
						'authToken',
						data.data.responseData.user.authentication_token,
					);
					const loginData = {
						id: data.data.responseData.user.id,
						user_type: data.data.responseData.user.user_type,
						authorities: data.data.responseData.user.user_profile_details.authorities,
						currency_code: data.data.responseData.user.currency_code,
						email: data.data.responseData.user.email,
						first_name: data.data.responseData.user.first_name,
						last_name: data.data.responseData.user.last_name,
						name: data.data.responseData.user.name,
						company_name: data.data.responseData.user.user_profile_details.organization_name,
						registration_date: data.data.responseData.user.registration_date,
						subscription_plan: data.data.responseData.user.subscription_details.purchase_sku_name,
						subscription_status:
							data.data.responseData.user.subscription_details.user_purchase_sku == "com.freetrial" ?
								data.data.responseData.user.subscription_details.free_trail_days > 0 ? "Trialing" : "Trial Ended" :
								data.data.responseData.user.subscription_details.purchase_sku_name && data.data.responseData.user.subscription_details.remaining_days > 0 ? "Active" :
									"Expired",
						email_verified: data.data.responseData.user.is_verfied,
						subscription_expires_at: data.data.responseData.user.subscription_details.next_billing_timestamp,
						organization_name: data.data.responseData.user.user_profile_details.organization_name,
						phone_country_code: data.data.responseData.user.user_profile_details.phone_country_code,
						phone_number: data.data.responseData.user.user_profile_details.phone,
						alternate_phone_country_code: data.data.responseData.user.user_profile_details.alternate_phone_country_code,
						alternate_phone_number: data.data.responseData.user.user_profile_details.alternate_phone,
						company_country: data.data.responseData.user.user_profile_details.country,
						company_dial_code: countryList().find(d => d.name == data.data.responseData.user.user_profile_details.country),
						location: {
							lat: data.data.responseData.user.user_profile_details.latitude,
							long: data.data.responseData.user.user_profile_details.longitude
						}
					}
					localStorage.setItem(
						"fieldCampLogin",
						CryptoJS.AES.encrypt(JSON.stringify(loginData), "fieldCampLogin")
					);

					let name = data.data.responseData.user.first_name + " " + data.data.responseData.user.last_name;
					let phone_number = (data.data.responseData.user.user_profile_details.phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.phone_country_code) : "+1") + "-" + data.data.responseData.user.user_profile_details.phone
					let alternate_phone_number = (data.data.responseData.user.user_profile_details.alternate_phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.alternate_phone_country_code) : "+1") + "-" + data.data.responseData.user.user_profile_details.alternate_phone
					process.env.REACT_APP_ENVIRONMENT == "LIVE" && window.hj('identify', data.data.responseData.user.id, {
						'user_id': data.data.responseData.user.id,
						'email': data.data.responseData.user.email,
						'name': data.data.responseData.user.name ? data.data.responseData.user.name : name,
						'user_role': data.data.responseData.user.user_type,
						'mixpanel_link': `https://mixpanel.com/project/2262869/view/2809985/app/profile#distinct_id=${data.data.responseData.user.id}`,
						'registration_date': data.data.responseData.user.registration_date,
						'subscription_plan': data.data.responseData.user.subscription_details.purchase_sku_name,
						'subscription_status':
							data.data.responseData.user.subscription_details.user_purchase_sku == "com.freetrial" ?
								data.data.responseData.user.subscription_details.free_trail_days > 0 ? "Trialing" : "Trial Ended" :
								data.data.responseData.user.subscription_details.purchase_sku_name && data.data.responseData.user.subscription_details.remaining_days > 0 ? "Active" :
									"Expired",
						'organization_name': data.data.responseData.user.user_profile_details.organization_name ? data.data.responseData.user.user_profile_details.organization_name : "N/A",
						'phone_country_code': data.data.responseData.user.user_profile_details.phone ? (data.data.responseData.user.user_profile_details.phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.phone_country_code) : "+1") : "N/A",
						'phone_number': data.data.responseData.user.user_profile_details.phone ? phone_number : "N/A",
						'alternate_phone_country_code': data.data.responseData.user.user_profile_details.alternate_phone ? (data.data.responseData.user.user_profile_details.alternate_phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.alternate_phone_country_code) : "+1") : "N/A",
						'alternate_phone_number': data.data.responseData.user.user_profile_details.alternate_phone ? alternate_phone_number : "N/A",
					});
				}

				apiToastSuccess(data);
				dispatch(fetchResendOtpSuccess(data));
			} else {
				apiToastError(data);
				dispatch(fetchResendOtpFailure(data));
			}
		})
		.catch((error) => {
			apiToastError(error);
			dispatch(fetchResendOtpFailure(error));
		});
};
