import axios from 'axios';
import { showToast } from './modules/utils';

export default {
	setupInterceptors: (store) => {
		axios.interceptors.response.use(
			function (response) {
				
				
				if (response?.data?.responseCode == 401) {
					if(response?.data?.responseMessage == 'Need to Confirm your Account with OTP'){
						return response
					}else{

						showToast(response?.data?.responseMessage, 0);
						localStorage.clear();
						window.location.href = `${process.env.PUBLIC_URL}/login`;
					}
				}
				else if (response?.data?.responseCode == 402) {
					
					// && response?.data?.responseMessage == `You don't have any active subscription`){
					window.location.href = (window.location.origin == "https://my.fieldcamp.com" || window.location.origin == "http://my.fieldcamp.com") ? '/pricing-plans' : '/fieldcamp/pricing-plans';
				}
				else {
					return response;
				}
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	},
};
