export const FETCH_JOB_LIST_REQUEST = 'FETCH_JOB_LIST_REQUEST';
export const FETCH_JOB_LIST_SUCCESS = 'FETCH_JOB_LIST_SUCCESS';
export const FETCH_JOB_LIST_FAILURE = 'FETCH_JOB_LIST_FAILURE';

export const FETCH_JOB_DELETE_REQUEST = 'FETCH_JOB_DELETE_REQUEST';
export const FETCH_JOB_DELETE_SUCCESS = 'FETCH_JOB_DELETE_SUCCESS';
export const FETCH_JOB_DELETE_FAILURE = 'FETCH_JOB_DELETE_FAILURE';

export const FETCH_JOB_ADD_REQUEST = 'FETCH_JOB_ADD_REQUEST';
export const FETCH_JOB_ADD_SUCCESS = 'FETCH_JOB_ADD_SUCCESS';
export const FETCH_JOB_ADD_FAILURE = 'FETCH_JOB_ADD_FAILURE';

export const FETCH_JOB_UPDATE_REQUEST = 'FETCH_JOB_UPDATE_REQUEST';
export const FETCH_JOB_UPDATE_SUCCESS = 'FETCH_JOB_UPDATE_SUCCESS';
export const FETCH_JOB_UPDATE_FAILURE = 'FETCH_JOB_UPDATE_FAILURE';

export const FETCH_CATEGORY_STAFF_REQUEST = 'FETCH_CATEGORY_STAFF_REQUEST';
export const FETCH_CATEGORY_STAFF_SUCCESS = 'FETCH_CATEGORY_STAFF_SUCCESS';
export const FETCH_CATEGORY_STAFF_FAILURE = 'FETCH_CATEGORY_STAFF_FAILURE';


export const FETCH_JOB_DETAIL_REQUEST = 'FETCH_JOB_DETAIL_REQUEST';
export const FETCH_JOB_DETAIL_SUCCESS = 'FETCH_JOB_DETAIL_SUCCESS';
export const FETCH_JOB_DETAIL_FAILURE = 'FETCH_JOB_DETAIL_FAILURE';

export const FETCH_MANAGE_JOB_REQUEST = 'FETCH_MANAGE_JOB_REQUEST';
export const FETCH_MANAGE_JOB_SUCCESS = 'FETCH_MANAGE_JOB_SUCCESS';
export const FETCH_MANAGE_JOB_FAILURE = 'FETCH_MANAGE_JOB_FAILURE';

export const FETCH_JOB_HISTORY_UPDATE_REQUEST = 'FETCH_JOB_HISTORY_UPDATE_REQUEST';
export const FETCH_JOB_HISTORY_UPDATE_SUCCESS = 'FETCH_JOB_HISTORY_UPDATE_SUCCESS';
export const FETCH_JOB_HISTORY_UPDATE_FAILURE = 'FETCH_JOB_HISTORY_UPDATE_FAILURE';

export const FETCH_SCHEDULE_JOB_LIST_REQUEST = 'FETCH_SCHEDULE_JOB_LIST_REQUEST';
export const FETCH_SCHEDULE_JOB_LIST_SUCCESS = 'FETCH_SCHEDULE_JOB_LIST_SUCCESS';
export const FETCH_SCHEDULE_JOB_LIST_FAILURE = 'FETCH_SCHEDULE_JOB_LIST_FAILURE';
