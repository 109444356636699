export const FETCH_STAFF_LIST_REQUEST = 'FETCH_STAFF_LIST_REQUEST';
export const FETCH_STAFF_LIST_SUCCESS = 'FETCH_STAFF_LIST_SUCCESS';
export const FETCH_STAFF_LIST_FAILURE = 'FETCH_STAFF_LIST_FAILURE';

export const FETCH_STAFF_DELETE_REQUEST = 'FETCH_STAFF_DELETE_REQUEST';
export const FETCH_STAFF_DELETE_SUCCESS = 'FETCH_STAFF_DELETE_SUCCESS';
export const FETCH_STAFF_DELETE_FAILURE = 'FETCH_STAFF_DELETE_FAILURE';

export const FETCH_STAFF_ADD_REQUEST = 'FETCH_STAFF_ADD_REQUEST';
export const FETCH_STAFF_ADD_SUCCESS = 'FETCH_STAFF_ADD_SUCCESS';
export const FETCH_STAFF_ADD_FAILURE = 'FETCH_STAFF_ADD_FAILURE';

export const FETCH_STAFF_UPDATE_REQUEST = 'FETCH_STAFF_UPDATE_REQUEST';
export const FETCH_STAFF_UPDATE_SUCCESS = 'FETCH_STAFF_UPDATE_SUCCESS';
export const FETCH_STAFF_UPDATE_FAILURE = 'FETCH_STAFF_UPDATE_FAILURE';

export const FETCH_STAFF_DETAIL_REQUEST = 'FETCH_STAFF_DETAIL_REQUEST';
export const FETCH_STAFF_DETAIL_SUCCESS = 'FETCH_STAFF_DETAIL_SUCCESS';
export const FETCH_STAFF_DETAIL_FAILURE = 'FETCH_STAFF_DETAIL_FAILURE';

export const FETCH_ACTIVE_INACTIVE_STAFF_REQUEST = 'FETCH_ACTIVE_INACTIVE_STAFF_REQUEST';
export const FETCH_ACTIVE_INACTIVE_STAFF_SUCCESS = 'FETCH_ACTIVE_INACTIVE_STAFF_SUCCESS';
export const FETCH_ACTIVE_INACTIVE_STAFF_FAILURE = 'FETCH_ACTIVE_INACTIVE_STAFF_FAILURE';

export const FETCH_STAFF_COMPLETED_JOBS_REQUEST = 'FETCH_STAFF_COMPLETED_JOBS_REQUEST';
export const FETCH_STAFF_COMPLETED_JOBS_SUCCESS = 'FETCH_STAFF_COMPLETED_JOBS_SUCCESS';
export const FETCH_STAFF_COMPLETED_JOBS_FAILURE = 'FETCH_STAFF_COMPLETED_JOBS_FAILURE';

export const FETCH_CHANGE_STAFF_PASSWORD_REQUEST = 'FETCH_CHANGE_STAFF_PASSWORD_REQUEST';
export const FETCH_CHANGE_STAFF_PASSWORD_SUCCESS = 'FETCH_CHANGE_STAFF_PASSWORD_SUCCESS';
export const FETCH_CHANGE_STAFF_PASSWORD_FAILURE = 'FETCH_CHANGE_STAFF_PASSWORD_FAILURE';