export const FETCH_STATIC_CONTENT_REQUEST = 'FETCH_STATIC_CONTENT_REQUEST';
export const FETCH_STATIC_CONTENT_SUCCESS = 'FETCH_STATIC_CONTENT_SUCCESS';
export const FETCH_STATIC_CONTENT_FAILURE = 'FETCH_STATIC_CONTENT_FAILURE';

export const FETCH_UPDATE_STATIC_CONTENT_REQUEST = 'FETCH_UPDATE_STATIC_CONTENT_REQUEST';
export const FETCH_UPDATE_STATIC_CONTENT_SUCCESS = 'FETCH_UPDATE_STATIC_CONTENT_SUCCESS';
export const FETCH_UPDATE_STATIC_CONTENT_FAILURE = 'FETCH_UPDATE_STATIC_CONTENT_FAILURE';

export const FETCH_IP2LOC_REQUEST = 'FETCH_IP2LOC_REQUEST';
export const FETCH_IP2LOC_SUCCESS = 'FETCH_IP2LOC_SUCCESS';
export const FETCH_IP2LOC_FAILURE = 'FETCH_IP2LOC_FAILURE';
