import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { generateRandomId } from '../../../modules/utils';

const GooglePlaces = (props) => {
	const SuggestionsList = ({
		getInputProps,
		getSuggestionItemProps,
		suggestions,
		loading,
	}) => (
		<div className="autocomplete-root">
			<input
				{...getInputProps({
					placeholder: 'Street 1',

					className: 'location-search-input form-control',
				})}
				name={name}
				autoComplete="new-password"
				disabled={disabled}
			/>
			<div className="autocomplete-dropdown-container">
				{loading && <div>Loading...</div>}
				{suggestions.map((suggestion, key) => (
					<div
						key={`${key}-${generateRandomId()}`}
						{...getSuggestionItemProps(suggestion)}
					>
						<span>{suggestion.description}</span>
					</div>
				))}
			</div>
		</div>
	);

	const { address, onChangeVal, onSelectVal, name = '', disabled } = props;
	return (
		<PlacesAutocomplete
			value={address}
			onChange={onChangeVal}
			onSelect={onSelectVal}
		>
			{SuggestionsList}
		</PlacesAutocomplete>
	);
};

export default GooglePlaces;
