import React, { Component } from 'react';

import banner from '../../../assets/images/banner.png';

export default class SiteWelcome extends Component {
	render() {
		return (
			<section className="landing_sec">
				<div
					className="banner"
					style={{
						backgroundImage: `url(${banner})`,
					}}
				>
					<div className="custom-container h-100">
						<div className="row h-100">
							<div className="col-xl-5 col-lg-6 col-md-8 col-12 align-self-center">
								<div className="content">
									<h1 className="bold">
										Grow Your Business <br />
										With Us.
									</h1>
									<p className="pr-5">
										Lorem ipsum dolor sit amet, pri erant verear scaevola in, ne
										diam fierent consetetur his, eos reque iriure veritus te.
										Omnium voluptatum honestatis has an, an duis reprimique cum,
										cu pro quas choro doming.
									</p>
									<button className="btn btn-cyan" type="button">
										Join Now
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
