import {
	FETCH_DASHBOARD_JOB_COUNT_REQUEST,
	FETCH_DASHBOARD_JOB_COUNT_SUCCESS,
	FETCH_DASHBOARD_JOB_COUNT_FAILURE,
} from '../../types/dashboard';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

const JobCountReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_DASHBOARD_JOB_COUNT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_DASHBOARD_JOB_COUNT_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_DASHBOARD_JOB_COUNT_FAILURE:
			return {
				...state,
				isLoading: false,
				data: null,
				error: action.error.response,
			};
		default:
			return state;
	}
};

export default JobCountReducer;
