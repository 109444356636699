import {
	FETCH_FORGET_PASSWORD_REQUEST,
	FETCH_FORGET_PASSWORD_SUCCESS,
	FETCH_FORGET_PASSWORD_FAILURE,
} from '../../types/user';
import { postApi } from '../api';
import { apiToastSuccess, apiToastError } from '../../modules/utils';

export const fetchForgetPasswordRequest = () => ({
	type: FETCH_FORGET_PASSWORD_REQUEST,
});

export const fetchForgetPasswordSuccess = (data) => ({
	type: FETCH_FORGET_PASSWORD_SUCCESS,
	data,
});

export const fetchForgetPasswordFailure = (error) => ({
	type: FETCH_FORGET_PASSWORD_FAILURE,
	error,
});

export const fetchForgetPassword = (formData) => (dispatch) => {
	dispatch(fetchForgetPasswordRequest());

	return postApi('/users/password', formData)
		.then((data) => {
			if (data?.data?.responseCode === 200) {
				console.log(data)
				process.env.REACT_APP_ENVIRONMENT == "LIVE" && window.hj('identify', null, {
					'email': formData.email,
				});
				apiToastSuccess(data);
				dispatch(fetchForgetPasswordSuccess(data));
			} else {
				console.log(data)
				process.env.REACT_APP_ENVIRONMENT == "LIVE" && window.hj('identify', null, {
					'email': formData.email,
				});
				apiToastError(data);
				dispatch(fetchForgetPasswordFailure(data));
			}
		})
		.catch((error) => {
			apiToastError(error);
			dispatch(fetchForgetPasswordFailure(error));
		});
};
