export const FETCH_CREATE_JOB_FORM_REQUEST = 'FETCH_CREATE_JOB_FORM_REQUEST';
export const FETCH_CREATE_JOB_FORM_SUCCESS = 'FETCH_CREATE_JOB_FORM_SUCCESS';
export const FETCH_CREATE_JOB_FORM_FAILURE = 'FETCH_CREATE_JOB_FORM_FAILURE';

export const FETCH_UPDATE_JOB_FORM_REQUEST = 'FETCH_UPDATE_JOB_FORM_REQUEST';
export const FETCH_UPDATE_JOB_FORM_SUCCESS = 'FETCH_UPDATE_JOB_FORM_SUCCESS';
export const FETCH_UPDATE_JOB_FORM_FAILURE = 'FETCH_UPDATE_JOB_FORM_FAILURE';

export const FETCH_DELETE_JOB_FORM_REQUEST = 'FETCH_DELETE_JOB_FORM_REQUEST';
export const FETCH_DELETE_JOB_FORM_SUCCESS = 'FETCH_DELETE_JOB_FORM_SUCCESS';
export const FETCH_DELETE_JOB_FORM_FAILURE = 'FETCH_DELETE_JOB_FORM_FAILURE';

export const FETCH_GET_JOB_FORM_REQUEST = 'FETCH_GET_JOB_FORM_REQUEST';
export const FETCH_GET_JOB_FORM_SUCCESS = 'FETCH_GET_JOB_FORM_SUCCESS';
export const FETCH_GET_JOB_FORM_FAILURE = 'FETCH_GET_JOB_FORM_FAILURE';

export const FETCH_LIST_JOB_FORM_REQUEST = 'FETCH_LIST_JOB_FORM_REQUEST';
export const FETCH_LIST_JOB_FORM_SUCCESS = 'FETCH_LIST_JOB_FORM_SUCCESS';
export const FETCH_LIST_JOB_FORM_FAILURE = 'FETCH_LIST_JOB_FORM_FAILURE';

export const FETCH_SUBMIT_JOB_FORM_REQUEST = 'FETCH_SUBMIT_JOB_FORM_REQUEST';
export const FETCH_SUBMIT_JOB_FORM_SUCCESS = 'FETCH_SUBMIT_JOB_FORM_SUCCESS';
export const FETCH_SUBMIT_JOB_FORM_FAILURE = 'FETCH_SUBMIT_JOB_FORM_FAILURE';

export const FETCH_GET_EMAIL_JOB_FORM_REQUEST = 'FETCH_GET_EMAIL_JOB_FORM_REQUEST';
export const FETCH_GET_EMAIL_JOB_FORM_SUCCESS = 'FETCH_GET_EMAIL_JOB_FORM_SUCCESS';
export const FETCH_GET_EMAIL_JOB_FORM_FAILURE = 'FETCH_GET_EMAIL_JOB_FORM_FAILURE';

export const FETCH_SEND_EMAIL_JOB_FORM_REQUEST = 'FETCH_SEND_EMAIL_JOB_FORM_REQUEST';
export const FETCH_SEND_EMAIL_JOB_FORM_SUCCESS = 'FETCH_SEND_EMAIL_JOB_FORM_SUCCESS';
export const FETCH_SEND_EMAIL_JOB_FORM_FAILURE = 'FETCH_SEND_EMAIL_JOB_FORM_FAILURE';

export const FETCH_GET_DOWNLOAD_JOB_FORM_REQUEST = 'FETCH_GET_DOWNLOAD_JOB_FORM_REQUEST';
export const FETCH_GET_DOWNLOAD_JOB_FORM_SUCCESS = 'FETCH_GET_DOWNLOAD_JOB_FORM_SUCCESS';
export const FETCH_GET_DOWNLOAD_JOB_FORM_FAILURE = 'FETCH_GET_DOWNLOAD_JOB_FORM_FAILURE';