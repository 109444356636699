import { Formik } from 'formik';
import React, { Component } from 'react';
import { displayFormErrors } from '../../modules/validations';
import { fetchVerifyUser } from '../../actions/user/verifyUser';
import { fetchResendOtp } from '../../actions/user/resendOtp';
import { connect } from 'react-redux';
import OtpVerificationForm from '../common/site/OtpVerification/form';
import { showToast } from '../../modules/utils';

class OtpVerification extends Component {
	state = {
		otp: '',
		isEnableResendBtn: false,
		isTimerRunning: true,
	};
	onHandleVerifyOtp = () => {
		const { history } = this.props;

		if (!this.state.otp) {
			showToast('Please type a verification code', 0);
			return;
		}

		const values = {
			user_code: this.state.otp,
		};
		this.props.fetchVerifyUser(values).then(() => {
			const { verifyUserData } = this.props;
			if (verifyUserData) {
				history.push('/');
			}
		});
	};

	resendOtp = () => {
		this.props.fetchResendOtp({}).then(() => {
			const { resendOtpData } = this.props;
			if (resendOtpData) {
				this.setState({ isEnableResendBtn: false, isTimerRunning: true });
			}
		});
	};

	handleOtpChange = (otp) => this.setState({ otp });

	onCompleteTimer = () => {
		this.setState({ isEnableResendBtn: true, isTimerRunning: false });
	};

	render() {
		const { userData } = this.props;
		const { otp, isEnableResendBtn, isTimerRunning } = this.state;

		return (
			<React.Fragment>
				<h4 className="text-center pt-5 pb-5">Verify your email address</h4>

				<OtpVerificationForm
					userData={userData}
					onSubmitForm={this.onHandleVerifyOtp}
					otp={otp}
					resendOtp={this.resendOtp}
					verifyUserLoading={this.props.verifyUserLoading}
					onCompleteTimer={this.onCompleteTimer}
					handleOtpChange={this.handleOtpChange}
					isEnableResendBtn={isEnableResendBtn}
					isTimerRunning={isTimerRunning}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		verifyUserLoading: state.verifyUser.isLoading,
		verifyUserData: state.verifyUser.data,

		resendOtpLoading: state.resendOtp.isLoading,
		resendOtpData: state.resendOtp.data,
	};
};

const mapDispatchToProps = {
	fetchVerifyUser,
	fetchResendOtp,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);
