export const FETCH_USER_CATEGORY_REQUEST = 'FETCH_USER_CATEGORY_REQUEST';
export const FETCH_USER_CATEGORY_SUCCESS = 'FETCH_USER_CATEGORY_SUCCESS';
export const FETCH_USER_CATEGORY_FAILURE = 'FETCH_USER_CATEGORY_FAILURE';

export const FETCH_SERVICES_LIST_REQUEST = 'FETCH_SERVICES_LIST_REQUEST';
export const FETCH_SERVICES_LIST_SUCCESS = 'FETCH_SERVICES_LIST_SUCCESS';
export const FETCH_SERVICES_LIST_FAILURE = 'FETCH_SERVICES_LIST_FAILURE';


export const FETCH_DELETE_SERVICE_REQUEST = 'FETCH_DELETE_SERVICE_REQUEST';
export const FETCH_DELETE_SERVICE_SUCCESS = 'FETCH_DELETE_SERVICE_SUCCESS';
export const FETCH_DELETE_SERVICE_FAILURE = 'FETCH_DELETE_SERVICE_FAILURE';

export const FETCH_UPDATE_USER_CATEGORY_REQUEST = 'FETCH_UPDATE_USER_CATEGORY_REQUEST';
export const FETCH_UPDATE_USER_CATEGORY_SUCCESS = 'FETCH_UPDATE_USER_CATEGORY_SUCCESS';
export const FETCH_UPDATE_USER_CATEGORY_FAILURE = 'FETCH_UPDATE_USER_CATEGORY_FAILURE';

export const FETCH_COMPANY_DETAILS_REQUEST = 'FETCH_COMPANY_DETAILS_REQUEST';
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS';
export const FETCH_COMPANY_DETAILS_FAILURE = 'FETCH_COMPANY_DETAILS_FAILURE';