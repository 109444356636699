import {
    FETCH_GET_EMAIL_JOB_FORM_REQUEST,
    FETCH_GET_EMAIL_JOB_FORM_SUCCESS,
    FETCH_GET_EMAIL_JOB_FORM_FAILURE,
} from '../../types/jobForm';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const GetEmailJobFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_EMAIL_JOB_FORM_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_GET_EMAIL_JOB_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data.data,
                error: null,
            };
        case FETCH_GET_EMAIL_JOB_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.error.data,
            };
        default:
            return state;
    }
};

export default GetEmailJobFormReducer;
