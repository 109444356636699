export const FETCH_PAID_UNPAID_INVOICE_REQUEST = 'FETCH_PAID_UNPAID_INVOICE_REQUEST';
export const FETCH_PAID_UNPAID_INVOICE_SUCCESS = 'FETCH_PAID_UNPAID_INVOICE_SUCCESS';
export const FETCH_PAID_UNPAID_INVOICE_FAILURE = 'FETCH_PAID_UNPAID_INVOICE_FAILURE';

export const FETCH_GENERATE_ADD_INVOICE_REQUEST = 'FETCH_GENERATE_ADD_INVOICE_REQUEST';
export const FETCH_GENERATE_ADD_INVOICE_SUCCESS = 'FETCH_GENERATE_ADD_INVOICE_SUCCESS';
export const FETCH_GENERATE_ADD_INVOICE_FAILURE = 'FETCH_GENERATE_ADD_INVOICE_FAILURE';

export const FETCH_UNPAID_INVOICE_REQUEST = 'FETCH_UNPAID_INVOICE_REQUEST';
export const FETCH_UNPAID_INVOICE_SUCCESS = 'FETCH_UNPAID_INVOICE_SUCCESS';
export const FETCH_UNPAID_INVOICE_FAILURE = 'FETCH_UNPAID_INVOICE_FAILURE';

export const FETCH_INVOICE_DUE_DAYS_REQUEST = 'FETCH_INVOICE_DUE_DAYS_REQUEST';
export const FETCH_INVOICE_DUE_DAYS_SUCCESS = 'FETCH_INVOICE_DUE_DAYS_SUCCESS';
export const FETCH_INVOICE_DUE_DAYS_FAILURE = 'FETCH_INVOICE_DUE_DAYS_FAILURE';