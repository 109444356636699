import axios from 'axios';
import queryString from "query-string"
import { countryList } from '../modules/countryList';
import { getDialCodeFromCountryCode, timestampToDate } from '../modules/utils';

export const mixpostApi = (userDetail, event_name, eventData) => {

	const mixPostValue = {
		people: {
			id: userDetail?.id,
			email: userDetail?.email,
			social_type: "email",
			account_type: "company",
			user_role: "company",
			first_name: userDetail?.first_name,
			last_name: userDetail?.last_name,
		},
		event_track: {
			event_name: event_name,
			event_data: {

				so_distinct_id: userDetail?.id,
				...eventData
			}
		}
	}

	const mixPostTestUserValue = {
		people: {
			id: userDetail?.id,
			email: userDetail?.email,
			social_type: "email",
			account_type: "company",
			user_role: "company",
			first_name: userDetail?.first_name,
			last_name: userDetail?.last_name,
			is_test_user: userDetail?.is_test_user
		}
	}


	return (
		process.env.REACT_APP_ENVIRONMENT == "LIVE" ?
			axios.post(
				`https://funnel.fieldcamp.com/so-event-analytics`,
				event_name == "isTestUser" ? mixPostTestUserValue : mixPostValue,
			) :
			axios.post(
				`https://devfunnel.fieldcamp.com/so-event-analytics`,
				event_name == "isTestUser" ? mixPostTestUserValue : mixPostValue,
			)
	)
};

export const mixSignuppostApi = (userDetail, event_name, eventData, socialType, urlCookie, ip) => {
	let url_params = urlCookie && queryString.parse(urlCookie)

	const mixPostValue = {
		people: {
			id: userDetail?.id,
			email: userDetail?.email,
			social_type: socialType,
			country: countryList().find(d => d.code == ip?.country) ? countryList().find(d => d.code == ip?.country).name : ip?.country,
			city: ip?.city,
			region: ip?.region,
			account_type: "company",
			user_role: "company",
			first_name: userDetail?.first_name,
			last_name: userDetail?.last_name,
			phone_number: `${getDialCodeFromCountryCode(userDetail?.user_profile_details?.phone_country_code)}` + `${userDetail?.user_profile_details?.phone}`,
			total_admin: 0,
			total_branches: 0,
			total_customer: 0,
			total_invoices: 0,
			total_jobs: 0,
			total_staff: 0,
			email_verified: "No",
			registered_on: userDetail?.registration_date,
			subscription_platform: "Stripe",
			subscription_id: userDetail.subscription_details.subscription_id,
			subscription_expires_at: timestampToDate(userDetail.subscription_details.next_billing_timestamp),
			subscription_plan: userDetail.subscription_details.purchase_sku_name,
			subscription_status: userDetail.subscription_details.user_purchase_sku == "com.freetrial" ?
				userDetail.subscription_details.free_trail_days > 0 ? "Trialing" : "Trial Ended" :
				userDetail.subscription_details.purchase_sku_name && userDetail.subscription_details.remaining_days > 0 ? "Active" :
					"Expired",
			subscription_trial_started: userDetail?.registration_date,
			subscription_trial_ended: timestampToDate(userDetail.subscription_details.next_billing_timestamp),
			subscription_trial_interval: userDetail.subscription_details.app_free_trial_days,
			z_campaign_name: url_params?.u_cname,
			u_referrer_url: url_params?.u_referrer_url,
			u_source: url_params?.u_source,
			u_medium: url_params?.u_medium,
			u_initial_referrer: url_params?.u_initial_referrer,
			u_referring_domain: url_params?.u_referring_domain,
			app_platform: 'web',
			app_version: process.env.REACT_APP_FIELDCAMP_VERSION
		},
		event_track: {
			event_name: event_name,
			event_data: {
				so_distinct_id: userDetail?.id,
				...eventData,
				"Subscription Plan": url_params?.subscription_plan,
				"Subscription Price": url_params?.subscription_price,
				so_os: "web"
			}
		}

	}

	return (
		process.env.REACT_APP_ENVIRONMENT == "LIVE" ?
			axios.post(
				`https://funnel.fieldcamp.com/so-event-analytics`,
				mixPostValue,
			) :
			axios.post(
				`https://devfunnel.fieldcamp.com/so-event-analytics`,
				mixPostValue,
			)
	)
};

export const mixPricingFormpostApi = (userDetail, event_name, formData) => {
	const mixPostValue = {
		people: {
			id: userDetail?.id,
			email: formData?.email,
			account_type: 'company',
			first_name: userDetail?.first_name,
			last_name: userDetail?.last_name,
			user_message: formData?.user_message,
			contact_number: formData?.contact_number,
		},
		event_track: {
			event_name: event_name,
			event_data: {
				so_distinct_id: userDetail?.id,
			},
		},
	};
	return process.env.REACT_APP_ENVIRONMENT == "LIVE" ?
		axios.post(
			`https://funnel.fieldcamp.com/so-event-analytics`,
			mixPostValue,
		) :
		axios.post(
			`https://devfunnel.fieldcamp.com/so-event-analytics`,
			mixPostValue,
		)
};


export const mixpostSignupWizardApi = (userDetail, event_name, eventData) => {
	const mixPostValue = {
		people: {
			id: userDetail?.id,
			email: userDetail?.email,
			social_type: "email",
			account_type: "company",
			user_role: "company",
			first_name: userDetail?.first_name,
			last_name: userDetail?.last_name,
			company_size: eventData.company_size,
			why_fieldcamp: eventData.why_fieldcamp,
			company_industry: eventData.company_industry
		},
		event_track: {
			event_name: event_name,
			event_data: {

				so_distinct_id: userDetail?.id,
				...eventData
			}
		}
	}
	return (
		process.env.REACT_APP_ENVIRONMENT == "LIVE" ?
			axios.post(
				`https://funnel.fieldcamp.com/so-event-analytics`,
				mixPostValue,
			) :
			axios.post(
				`https://devfunnel.fieldcamp.com/so-event-analytics`,
				mixPostValue,
			)
	)
};
