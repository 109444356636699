export const FETCH_DASHBOARD_JOB_COUNT_REQUEST = 'FETCH_DASHBOARD_JOB_COUNT_REQUEST';
export const FETCH_DASHBOARD_JOB_COUNT_SUCCESS = 'FETCH_DASHBOARD_JOB_COUNT_SUCCESS';
export const FETCH_DASHBOARD_JOB_COUNT_FAILURE = 'FETCH_DASHBOARD_JOB_COUNT_FAILURE';

export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

export const FETCH_ACTIVE_STAFF_LIST_REQUEST = 'FETCH_ACTIVE_STAFF_LIST_REQUEST';
export const FETCH_ACTIVE_STAFF_LIST_SUCCESS = 'FETCH_ACTIVE_STAFF_LIST_SUCCESS';
export const FETCH_ACTIVE_STAFF_LIST_FAILURE = 'FETCH_ACTIVE_STAFF_LIST_FAILURE';