import axios from 'axios';
import {
	FETCH_LOGIN_REQUEST,
	FETCH_LOGIN_SUCCESS,
	FETCH_LOGIN_FAILURE,
	ACTION_LOGOUT,
} from '../../types/user';
import settings from '../../settings';
import * as CryptoJS from 'crypto-js';

import {
	apiToastSuccess,
	apiToastError,
	deviceType,
	getDialCodeFromCountryCode,
	showToast,
} from '../../modules/utils';
import { countryList } from '../../modules/countryList';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module';

export const fetchLoginRequest = () => ({
	type: FETCH_LOGIN_REQUEST,
});

export const fetchLoginSuccess = (data) => ({
	type: FETCH_LOGIN_SUCCESS,
	data,
});

export const fetchLoginFailure = (error) => ({
	type: FETCH_LOGIN_FAILURE,
	error,
});

export const actionLogout = () => ({
	type: ACTION_LOGOUT,
});

export const setLogout = () => (dispatch) => {
	dispatch(actionLogout());
	localStorage.removeItem('authToken');
	window.location.reload();
};

export const fetchLogin = (data) => (dispatch) => {
	const apiUrl = `${settings.endPoint}/users/sign_in`;

	const formData = {
		...data,
		device_type: deviceType(),
	};

	dispatch(fetchLoginRequest());

	return axios
		.post(apiUrl, formData, { headers: { 'X-FC-App-Platform': 2 } })
		.then((data) => {
			if (data?.data?.responseCode === 200 || data?.data?.responseCode === 401) {
				console.log(
					'data?.data?.responseData?.user',
					data?.data?.responseData?.user,
				);
				if (data?.data?.responseData?.user?.authentication_token) {
					localStorage.setItem(
						'authToken',
						data.data.responseData.user.authentication_token,
					);
					localStorage.setItem(
						'authName',
						`${data.data.responseData.user.first_name} ${data.data.responseData.user.last_name}`,
					);
					localStorage.setItem('authEmail', data.data.responseData.user.email);
					localStorage.setItem(
						'userType',
						data.data.responseData.user.user_type,
					);
					localStorage.setItem(
						'dateFormat',
						data.data.responseData.user.date_format,
					);
					localStorage.setItem(
						'timeFormat',
						data.data.responseData.user.time_format,
					);
					localStorage.setItem(
						'organization_name',
						data.data.responseData.user.user_profile_details.organization_name,
					);
					localStorage.setItem(
						'branchId',
						data.data.responseData.user.branch_id,
					);
					const loginData = {
						id: data.data.responseData.user.id,
						user_type: data.data.responseData.user.user_type,
						authorities:
							data.data.responseData.user.user_profile_details.authorities,
						currency_code: data.data.responseData.user.currency_code,
						email: data.data.responseData.user.email,
						first_name: data.data.responseData.user.first_name,
						last_name: data.data.responseData.user.last_name,
						name: data.data.responseData.user.name,
						company_name: data.data.responseData.user.user_profile_details.organization_name,
						registration_date: data.data.responseData.user.registration_date,
						subscription_plan: data.data.responseData.user.subscription_details.purchase_sku_name,
						subscription_status:
							data.data.responseData.user.subscription_details.user_purchase_sku == "com.freetrial" ?
								data.data.responseData.user.subscription_details.free_trail_days > 0 ? "Trialing" : "Trial Ended" :
								data.data.responseData.user.subscription_details.purchase_sku_name && data.data.responseData.user.subscription_details.remaining_days > 0 ? "Active" :
									"Expired",
						email_verified: data.data.responseData.user.is_verfied,
						subscription_expires_at: data.data.responseData.user.subscription_details.next_billing_timestamp,
						organization_name: data.data.responseData.user.user_profile_details.organization_name,
						phone_country_code: data.data.responseData.user.user_profile_details.phone_country_code,
						phone_number: data.data.responseData.user.user_profile_details.phone,
						alternate_phone_country_code: data.data.responseData.user.user_profile_details.alternate_phone_country_code,
						alternate_phone_number: data.data.responseData.user.user_profile_details.alternate_phone,
						company_country: data.data.responseData.user.user_profile_details.country,
						company_dial_code: countryList().find(d => d.name == data.data.responseData.user.user_profile_details.country),
						location: {
							lat: data.data.responseData.user.user_profile_details.latitude,
							long: data.data.responseData.user.user_profile_details.longitude
						}
					}
					localStorage.setItem(
						'fieldCampLogin',
						CryptoJS.AES.encrypt(JSON.stringify(loginData), 'fieldCampLogin'),
					);

					let name = data.data.responseData.user.first_name + " " + data.data.responseData.user.last_name;
					let phone_number = (data.data.responseData.user.user_profile_details.phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.phone_country_code) : "+1") + "-" + data.data.responseData.user.user_profile_details.phone
					let alternate_phone_number = (data.data.responseData.user.user_profile_details.alternate_phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.alternate_phone_country_code) : "+1") + "-" + data.data.responseData.user.user_profile_details.alternate_phone
					process.env.REACT_APP_ENVIRONMENT == "LIVE" && window.hj('identify', data.data.responseData.user.id, {
						'user_id': data.data.responseData.user.id,
						'email': data.data.responseData.user.email,
						'name': data.data.responseData.user.name ? data.data.responseData.user.name : name,
						'user_role': data.data.responseData.user.user_type,
						'mixpanel_link': `https://mixpanel.com/project/2262869/view/2809985/app/profile#distinct_id=${data.data.responseData.user.id}`,
						'registration_date': data.data.responseData.user.registration_date,
						'subscription_plan': data.data.responseData.user.subscription_details.purchase_sku_name,
						'subscription_status':
							data.data.responseData.user.subscription_details.user_purchase_sku == "com.freetrial" ?
								data.data.responseData.user.subscription_details.free_trail_days > 0 ? "Trialing" : "Trial Ended" :
								data.data.responseData.user.subscription_details.purchase_sku_name && data.data.responseData.user.subscription_details.remaining_days > 0 ? "Active" :
									"Expired",
						'organization_name': data.data.responseData.user.user_profile_details.organization_name ? data.data.responseData.user.user_profile_details.organization_name : "N/A",
						'phone_country_code': data.data.responseData.user.user_profile_details.phone ? (data.data.responseData.user.user_profile_details.phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.phone_country_code) : "+1") : "N/A",
						'phone_number': data.data.responseData.user.user_profile_details.phone ? phone_number : "N/A",
						'alternate_phone_country_code': data.data.responseData.user.user_profile_details.alternate_phone ? (data.data.responseData.user.user_profile_details.alternate_phone_country_code ? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details.alternate_phone_country_code) : "+1") : "N/A",
						'alternate_phone_number': data.data.responseData.user.user_profile_details.alternate_phone ? alternate_phone_number : "N/A",
					});
					process.env.REACT_APP_ENVIRONMENT == "LIVE" && new Cookies().set('userId', data.data.responseData.user.id);
				}

				const tagManagerArgs = {
					gtmId: 'GTM-MLX77DM',
					dataLayer: {
						event: "Login",
						dlvuserid: data.data.responseData.user.id
					}
				}

				process.env.REACT_APP_ENVIRONMENT == "LIVE" && TagManager.initialize(tagManagerArgs)

				data?.data?.responseCode === 401 ?
					showToast("Welcome !!! You have Successfully log in !!!", 1)
					: apiToastSuccess(data)
				dispatch(fetchLoginSuccess(data));
			} else {
				data.data.responseCode !== 401 && data?.data?.responseCode !== 204 && apiToastError(data);
				data.data.responseCode !== 401 && process.env.REACT_APP_ENVIRONMENT == "LIVE" && window.hj('identify', data.data?.responseData ? data.data.responseData.user.id : null, {
					'email': formData.email,
				});
				dispatch(fetchLoginFailure(data));
			}
		})
		.catch((error) => {
			apiToastError(data);
			dispatch(fetchLoginFailure(error));
		});
};