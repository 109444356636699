export const FETCH_NOTIFICATION_LIST_REQUEST = 'FETCH_NOTIFICATION_LIST_REQUEST';
export const FETCH_NOTIFICATION_LIST_SUCCESS = 'FETCH_NOTIFICATION_LIST_SUCCESS';
export const FETCH_NOTIFICATION_LIST_FAILURE = 'FETCH_NOTIFICATION_LIST_FAILURE';

export const FETCH_DESTROY_ALL_NOTIFICATION_REQUEST = 'FETCH_DESTROY_ALL_NOTIFICATION_REQUEST';
export const FETCH_DESTROY_ALL_NOTIFICATION_SUCCESS = 'FETCH_DESTROY_ALL_NOTIFICATION_SUCCESS';
export const FETCH_DESTROY_ALL_NOTIFICATION_FAILURE = 'FETCH_DESTROY_ALL_NOTIFICATION_FAILURE';

export const FETCH_MARK_AS_READ_NOTIFICATION_REQUEST = 'FETCH_MARK_AS_READ_NOTIFICATION_REQUEST';
export const FETCH_MARK_AS_READ_NOTIFICATION_SUCCESS = 'FETCH_MARK_AS_READ_NOTIFICATION_SUCCESS';
export const FETCH_MARK_AS_READ_NOTIFICATION_FAILURE = 'FETCH_MARK_AS_READ_NOTIFICATION_FAILURE';

export const FETCH_MARK_AS_READ_ALL_NOTIFICATION_REQUEST = 'FETCH_MARK_AS_READ_ALL_NOTIFICATION_REQUEST';
export const FETCH_MARK_AS_READ_ALL_NOTIFICATION_SUCCESS = 'FETCH_MARK_AS_READ_ALL_NOTIFICATION_SUCCESS';
export const FETCH_MARK_AS_READ_ALL_NOTIFICATION_FAILURE = 'FETCH_MARK_AS_READ_ALL_NOTIFICATION_FAILURE';