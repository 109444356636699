export const FETCH_NOTIFICATION_SETTING_REQUEST =
	'FETCH_NOTIFICATION_SETTING_REQUEST';
export const FETCH_NOTIFICATION_SETTING_SUCCESS =
	'FETCH_NOTIFICATION_SETTING_SUCCESS';
export const FETCH_NOTIFICATION_SETTING_FAILURE =
	'FETCH_NOTIFICATION_SETTING_FAILURE';

export const FETCH_ENABLE_REMINDER_REQUEST = 'FETCH_ENABLE_REMINDER_REQUEST';
export const FETCH_ENABLE_REMINDER_SUCCESS = 'FETCH_ENABLE_REMINDER_SUCCESS';
export const FETCH_ENABLE_REMINDER_FAILURE = 'FETCH_ENABLE_REMINDER_FAILURE';

export const FETCH_TEMPLATE_VARIABLE_REQUEST =
	'FETCH_TEMPLATE_VARIABLE_REQUEST';
export const FETCH_TEMPLATE_VARIABLE_SUCCESS =
	'FETCH_TEMPLATE_VARIABLE_SUCCESS';
export const FETCH_TEMPLATE_VARIABLE_FAILURE =
	'FETCH_TEMPLATE_VARIABLE_FAILURE';

export const FETCH_SETTING_DETAIL_REQUEST = 'FETCH_SETTING_DETAIL_REQUEST';
export const FETCH_SETTING_DETAIL_SUCCESS = 'FETCH_SETTING_DETAIL_SUCCESS';
export const FETCH_SETTING_DETAIL_FAILURE = 'FETCH_SETTING_DETAIL_FAILURE';

export const FETCH_RESET_TEMPLATE_REQUEST = 'FETCH_RESET_TEMPLATE_REQUEST';
export const FETCH_RESET_TEMPLATE_SUCCESS = 'FETCH_RESET_TEMPLATE_SUCCESS';
export const FETCH_RESET_TEMPLATE_FAILURE = 'FETCH_RESET_TEMPLATE_FAILURE';

export const FETCH_UPDATE_SETTING_REQUEST = 'FETCH_UPDATE_SETTING_REQUEST';
export const FETCH_UPDATE_SETTING_SUCCESS = 'FETCH_UPDATE_SETTING_SUCCESS';
export const FETCH_UPDATE_SETTING_FAILURE = 'FETCH_UPDATE_SETTING_FAILURE';
