export const FETCH_PROPERTY_LIST_REQUEST = 'FETCH_PROPERTY_LIST_REQUEST';
export const FETCH_PROPERTY_LIST_SUCCESS = 'FETCH_PROPERTY_LIST_SUCCESS';
export const FETCH_PROPERTY_LIST_FAILURE = 'FETCH_PROPERTY_LIST_FAILURE';

export const FETCH_PROPERTY_ADD_REQUEST = 'FETCH_PROPERTY_ADD_REQUEST';
export const FETCH_PROPERTY_ADD_SUCCESS = 'FETCH_PROPERTY_ADD_SUCCESS';
export const FETCH_PROPERTY_ADD_FAILURE = 'FETCH_PROPERTY_ADD_FAILURE';

export const FETCH_PROPERTY_DELETE_REQUEST = 'FETCH_PROPERTY_DELETE_REQUEST';
export const FETCH_PROPERTY_DELETE_SUCCESS = 'FETCH_PROPERTY_DELETE_SUCCESS';
export const FETCH_PROPERTY_DELETE_FAILURE = 'FETCH_PROPERTY_DELETE_FAILURE';

export const FETCH_PROPERTY_UPDATE_REQUEST = 'FETCH_PROPERTY_UPDATE_REQUEST';
export const FETCH_PROPERTY_UPDATE_SUCCESS = 'FETCH_PROPERTY_UPDATE_SUCCESS';
export const FETCH_PROPERTY_UPDATE_FAILURE = 'FETCH_PROPERTY_UPDATE_FAILURE';

export const FETCH_PROPERTY_DETAILS_REQUEST = 'FETCH_PROPERTY_DETAILS_REQUEST';
export const FETCH_PROPERTY_DETAILS_SUCCESS = 'FETCH_PROPERTY_DETAILS_SUCCESS';
export const FETCH_PROPERTY_DETAILS_FAILURE = 'FETCH_PROPERTY_DETAILS_FAILURE';