import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
	cilApplicationsSettings,
	cilSpeedometer,
	cilSun,
	cilMoon,
	cilBell,
	cilUser,
	cilAccountLogout,
	cilChatBubble,
	cilSettings,
	cilPeople,
	cilBuilding,
	cilDataTransferDown,
	cilXCircle,
} from '@coreui/icons';

export const icons = Object.assign(
	{},
	{
		sygnet,
		logo,
		logoNegative,
	},
	{
		cilApplicationsSettings,
		cilSpeedometer,
		cilSun,
		cilMoon,
		cilBell,
		cilUser,
		cilAccountLogout,
		cilChatBubble,
		cilSettings,
		cilPeople,
		cilBuilding,
		cilDataTransferDown,
		cilXCircle,
	},
);
