import {
	FETCH_VERIFY_USER_REQUEST,
	FETCH_VERIFY_USER_SUCCESS,
	FETCH_VERIFY_USER_FAILURE,
} from '../../types/user';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

const VerifyUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_VERIFY_USER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_VERIFY_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_VERIFY_USER_FAILURE:
			return {
				...state,
				isLoading: false,
				data: null,
				error: action.error.data,
			};
		default:
			return state;
	}
};

export default VerifyUserReducer;
