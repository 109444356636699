const initialState = {
	sidebarShow: 'responsive',
	asideShow: false,
	darkMode: false,
	error: null,
};

const ThemeReducer = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'set':
			return { ...state, ...rest };
		default:
			return state;
	}
};

export default ThemeReducer;
