import React, { Component } from 'react';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '../../../../modules/utils';
import SocialButton from '../Button/SocialButton';
//import facebookIcon from '../../../../assets/images/facebook.svg'
//import googleIcon from '../../../../assets/images/google.svg'

export default class CommonSocialLogin extends Component {
	render() {
		const { onSocialLoginSuccess, onSocialLoginFailure } = this.props;

		return (
			<div className="social_media">
				{/* <SocialButton
					provider="facebook"
					appId={FACEBOOK_APP_ID}
					onLoginSuccess={onSocialLoginSuccess}
					onLoginFailure={onSocialLoginFailure}
					key={'facebook'}
					comefrom="facebookLogin"
					className="btn fb_btn btn-block"
				>
					<i className="icon"></i> Login with Facebook
				</SocialButton> */}
				<SocialButton
					provider="google"
					appId={GOOGLE_CLIENT_ID}
					onLoginSuccess={onSocialLoginSuccess}
					onLoginFailure={onSocialLoginFailure}
					key={'google'}
					className="btn btn-block google_btn btn-google"
					scope={'https://www.googleapis.com/auth/user.gender.read'}
				>
					<i className="icon"></i> Continue with Google
				</SocialButton>
			</div>
		);
	}
}
