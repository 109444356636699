import React, { Component } from 'react';

export default class SiteSupportMail extends Component {
	render() {
		return (
			<section className="email_sec bg_cyan pt-5 pb-5">
				<div className="custom-container">
					<div className="row">
						<div className="col-md-9 col-12">
							<h3 className="text-white m-0">
								<span className="bold d-block">Send an email to get assist</span>
							</h3>
						</div>
						<div className="col-md-3 col-12 align-self-center">
							<button type="button" className="btn btn-white-stroke">
								Send
							</button>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
