import React, { Component } from 'react';

import SiteHeader from '../common/site/Header';
import SiteWelcome from '../common/site/Welcome';
import SiteSupportMail from '../common/site/SupportMail';
import SiteFooter from '../common/site/Footer';

import '../../assets/css/bootstrap.min.css';
// import '../../assets/fonts/maisonNeue/font.css';
import '../../assets/css/style.css';

export default class Services extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="wrapper">
					<SiteHeader />

					<div className="page_content service_detail_page">
						<SiteWelcome />

						<section className="center_feature_sec pb-80 pt-80">
							<div className="custom-container">
								<div className="row">
									<div className="col-12 col-lg-10 col-xl-8 mx-auto">
										<div className="content">
											<h3 className="bold text-center">Services</h3>
											<p className="mb-4 text-center">
												Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
												stet cotidieque referrentur. Cu mea mucius docendi,
												veniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quoveniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quo
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>

						<SiteSupportMail />
					</div>
				</div>
				<SiteFooter />
			</React.Fragment>
		);
	}
}
