import * as Yup from 'yup';
import moment from 'moment';

const positiveValMessage = 'Entered value should be positive number';
const minValMessage = 'Minimum value should be 4';
const maxValMessage = 'Maximum value should be 100';

export const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const generalSettingsFormValidations = (opt = {}) => {
	const { user_type_check = '' } = opt;
	return Yup.object().shape({
		categories: validator('requiredMultiSelectCategory')
	});
};

export const signupWizardFormValidations = (opt = {}) => {
	const { user_type_check = '' } = opt;
	return Yup.object().shape({
		isPhoneRequired: Yup.boolean(),
		organization_name: validator('required'),
		province: validator('required').nullable(),
		city: validator('required').nullable(),
		country: validator('required').nullable(),
		street1: validator('required').nullable(),
		address: validator('required').nullable(),
		// currency_code: validator('required').nullable(),
		categories: Yup.object().required().nullable(),
		// todoFC: validator('requiredMultiSelectCategory'),
		companySize: validator('required').nullable(),
		phone: Yup.string().when('isPhoneRequired', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
	});
};

export const validator = (type) => {
	switch (type) {
		case 'startDateTime':
			return Yup.date().required('Required').min(moment(), 'Invalid');
		case 'emailRequired':
			return Yup.string().email('Invalid').required('Required');
		case 'emailType':
			return Yup.string().email('Invalid');
		case 'intPositiveRequired':
			return Yup.number().positive(positiveValMessage).required('Required');
		case 'intRequired':
			return Yup.number()
				.min(4, minValMessage)
				.max(100, maxValMessage)
				.required('Required');
		case 'notRequired':
			return Yup.string().notRequired();
		case 'minLengthNumber':
			return Yup.string()
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(4, 'Please type valid mobile number');
		case 'minLengthNumberRequired':
			return Yup.string()
				.required('Required')
				.matches(/^[0-9]+$/, 'Must be only digits')
				.min(4, 'Please type valid mobile number');
		// .max(5, 'Must be exactly 5 digits');
		case 'requiredMultiSelect':
			return Yup.array()
				.min(1, 'Kindly give at least one access to admin')
				.required('Required');
		case 'requiredMultiSelectCategory':
			return Yup.array()
				.min(1, 'Kindly select at least one category')
				.required('Required');
		case 'website':
			return Yup.string().matches(
				/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
				'Enter correct url!',
			);
		case 'intTax':
			return Yup.number()
				.positive('Please type a valid tax rate.')
				.max(100, 'Please type a valid tax rate.')
				.required('Required');
		case 'password':
			return Yup.string().required('Required');
		// .matches(
		// 	// /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
		// 	/^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})$/,
		// 	// /^(?=.*[A-Z])(?=.*[a-zA-Z!#$%&? "])[a-zA-Z0-9!#$%&?]{8,20}$/,
		// 	'Password must have atleast 8 characters, including atleast one uppercase and one number',
		// );
		case 'emailOrReferenceRequired':
			return Yup.string().required(
				'Plese type either reference or email details.',
			);
		case 'jobReason':
			return Yup.string().required('Please select a reason');
		case 'required':
		default:
			return Yup.string().required('Required');
	}
};

export const displayFormErrors = (
	key = '',
	errors = {},
	touched = {},
	submitCount = 1,
) => {
	if (errors[key] !== undefined && errors[key] && submitCount) {
		return (
			<div className="text-danger input-feedback font12">{errors[key]}</div>
		);
	}
	return null;
};

export const registerValidations = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
		password: validator('password'),
		first_name: validator('required'),
		last_name: validator('required'),
		phone: validator('required'),
	});
};

export const mailValidation = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
	});
};

export const registerNextStepValidations = () => {
	return Yup.object().shape({
		name: validator('required'),
		street1: validator('required'),
	});
};

export const loginValidations = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
		password: validator('required'),
	});
};

export const forgotPasswordValidations = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
	});
};

export const addCustomerFormValidations = () => {
	return Yup.object().shape({
		first_name: validator('required'),
		last_name: validator('required'),
		// company_address: validator('required'),
		// email: validator('emailType'),
		// phone: validator('minLengthNumber'),
		// alternate_no: validator('minLengthNumber'),
		website: validator('website'),
		// address: validator('required'),
		street1: validator('required'),
		// billing_address: Yup.string().when('same_as_property_address', {
		// 	is: false,
		// 	then: validator('required'),
		// 	otherwise: validator('notRequired'),
		// }),
		billing_street1: Yup.string().when('same_as_property_address', {
			is: false,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		// email: Yup.string().when('visit_reminder', {
		// 	is: false,
		// 	then: validator('emailType'),
		// 	otherwise: validator('emailRequired'),
		// }),
	});
};
export const editCustomerFormValidations = () => {
	return Yup.object().shape({
		first_name: validator('required'),
		last_name: validator('required'),
		// company_address: validator('required'),
		// email: validator('emailType'),
		// phone: validator('minLengthNumber'),
		// alternate_no: validator('minLengthNumber'),
		website: validator('website'),
		// address: validator('required'),
		// street1: validator('required'),
		// billing_address: Yup.string().when('same_as_property_address', {
		// 	is: false,
		// 	then: validator('required'),
		// 	otherwise: validator('notRequired'),
		// }),
		billing_street1: Yup.string().when('same_as_property_address', {
			is: false,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		// email: Yup.string().when('visit_reminder', {
		// 	is: false,
		// 	then: validator('emailType'),
		// 	otherwise: validator('emailRequired'),
		// }),
	});
};

export const editProfileFormValidation = (opt = {}) => {
	const { user_type_check = '' } = opt;
	return Yup.object().shape({
		first_name:
			(user_type_check == 'company' || user_type_check == 'individual') &&
			validator('required').nullable(),
		last_name:
			(user_type_check == 'company' || user_type_check == 'individual') &&
			validator('required').nullable(),
		name:
			(user_type_check == 'staff_admin' || user_type_check == 'branch_head') &&
			validator('required'),
		org_email: user_type_check == 'company' && validator('required'),
		phone: user_type_check == 'company' && validator('required'),
		// alternate_phone: user_type_check == 'company' && validator('required'),
		organization_name: user_type_check == 'company' && validator('required'),
		province: user_type_check == 'company' && validator('required').nullable(),
		city: user_type_check == 'company' && validator('required').nullable(),
		country: user_type_check == 'company' && validator('required').nullable(),
		street1: user_type_check == 'company' && validator('required').nullable(),
		address:
			(user_type_check == 'staff_admin' || user_type_check == 'branch_head') &&
			validator('required').nullable(),
	});
};
export const addPropertyFormValidations = () => {
	return Yup.object().shape({
		// address: validator('required'),
		street1: validator('required'),
	});
};

export const jobFormValidation = () => {
	return Yup.object().shape({
		// address: validator('required'),
		// street1: validator('required'),
	});
};

export const calendarSettingsValidation = () => {
	return Yup.object().shape({
		Sunday_job_max_time: Yup.string().when('Sunday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Sunday_job_min_time: Yup.string().when('Sunday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Sunday_job_max_time } = this.parent;
						return isSameOrBefore(value, Sunday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Monday_job_max_time: Yup.string().when('Monday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Monday_job_min_time: Yup.string().when('Monday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Monday_job_max_time } = this.parent;
						return isSameOrBefore(value, Monday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Tuesday_job_max_time: Yup.string().when('Tuesday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Tuesday_job_min_time: Yup.string().when('Tuesday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Tuesday_job_max_time } = this.parent;
						return isSameOrBefore(value, Tuesday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Wednesday_job_max_time: Yup.string().when('Wednesday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Wednesday_job_min_time: Yup.string().when('Wednesday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Wednesday_job_max_time } = this.parent;
						return isSameOrBefore(value, Wednesday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Thursday_job_max_time: Yup.string().when('Thursday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Thursday_job_min_time: Yup.string().when('Thursday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Thursday_job_max_time } = this.parent;
						return isSameOrBefore(value, Thursday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Friday_job_max_time: Yup.string().when('Friday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Friday_job_min_time: Yup.string().when('Friday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Friday_job_max_time } = this.parent;
						return isSameOrBefore(value, Friday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),

		Saturday_job_max_time: Yup.string().when('Saturday', {
			is: true,
			then: validator('required'),
			otherwise: validator('notRequired'),
		}),
		Saturday_job_min_time: Yup.string().when('Saturday', {
			is: true,
			then: Yup.string()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'job_min_time_test',
					'Business open time must be before Business close time.',
					function (value) {
						const { Saturday_job_max_time } = this.parent;
						return isSameOrBefore(value, Saturday_job_max_time);
					},
				),
			otherwise: validator('notRequired'),
		}),
	});
}

export const addUpdateVisitValidation = () => {
	return Yup.object().shape({
		job_end_time: validator('required'),
		// staff_id_array: Yup.array().min(1, 'Kindly select atleast one Staff'),
		preffered_time: Yup.string()
			.test('not empty', 'Start time cant be empty', function (value) {
				return !!value;
			})
			.test(
				'preffered_time_test',
				'Start time must be before end time',
				function (value) {
					const { job_end_time } = this.parent;
					return isSameOrBefore(value, job_end_time);
				},
			),
	});
};

export const sendEmailJobFormValidation = () => {
	return Yup.object().shape({
		// email: validator('emailRequired')
	});
}

// export const addUpdateJobValidation = () => {
// 	return Yup.object().shape({
// 		customer_id: validator('required'),
// 		property_id: validator('required').nullable(),
// 		preffered_time: validator('required'),
// 		job_end_time: validator('required'),
// 		category_id: validator('required'),
// 		startDateArray: Yup.array().min(1, 'Kindly select atleast one Date'),
// 		staff_id_array: Yup.array().min(1, 'Kindly select atleast one Staff'),
// 		endDate: Yup.string().when('interval', {
// 			is: 1 || 2 || 3 || 4,
// 			then: validator('required').nullable(),
// 			otherwise: validator('notRequired'),
// 		}),
// 		preffered_time: Yup.string()
// 			.test('not empty', 'Start time cant be empty', function (value) {
// 				return !!value;
// 			})
// 			.test(
// 				'preffered_time_test',
// 				'Start time must be before end time',
// 				function (value) {
// 					const { job_end_time } = this.parent;
// 					return isSameOrBefore(value, job_end_time);
// 				},
// 			),
// 	});
// };

export const addUpdateJobValidation = () => {
	return Yup.object().shape({
		jobType: Yup.string(),
		scheduleLater: Yup.boolean(),
		customerSetting: Yup.boolean(),
		customer_id: validator('required'),
		property_id: validator('required').nullable(),
		preffered_time: Yup.mixed().when("scheduleLater", { is: false, then: validator('required'), otherwise: Yup.mixed().notRequired() }),
		job_end_time: Yup.mixed().when("scheduleLater", { is: false, then: validator('required').nullable(), otherwise: Yup.mixed().notRequired() }),
		category_id: validator('required'),
		startDateArray: Yup.mixed().when("scheduleLater", { is: false, then: Yup.array().min(1, 'Kindly select atleast one Date'), otherwise: Yup.array().notRequired() }),
		// staff_id_array: Yup.mixed().when("scheduleLater", { is: false, then: Yup.array().min(1, 'Kindly select atleast one Staff'), otherwise: Yup.array().notRequired() }),
		endDate: Yup.string().when('jobType', {
			is: "recurring",
			// then: Yup.string().when('interval', {
			// 	is: 1 || 2 || 3 || 4,
			// 	then: validator('required').nullable(),
			// 	otherwise: validator('notRequired'),
			// }),
			then: validator('required').nullable(),
			otherwise: validator('notRequired'),
		}),

		lineItems: Yup.mixed().when("customerSetting", { is: true, then: Yup.array().notRequired(), otherwise: Yup.array().min(1, 'Kindly add atleast one line item.') }),
		preffered_time: Yup.mixed().when("scheduleLater", {
			is: false,
			then: Yup.string().nullable()
				.test('not empty', 'Required', function (value) {
					return !!value;
				})
				.test(
					'preffered_time_test',
					'Start time must be before end time',
					function (value) {
						const { job_end_time } = this.parent;
						return isSameOrBefore(value, job_end_time);
					},
				),
			otherwise: Yup.mixed().notRequired()
		})
	});
};

export const UpdateJobValidation = () => {
	return Yup.object().shape({
		// scheduleLater: Yup.boolean(),
		customerSetting: Yup.boolean(),
		// customer_id: validator('required'),
		// property_id: validator('required').nullable(),
		// preffered_time: Yup.mixed().when("scheduleLater", { is: false, then: validator('required'), otherwise: Yup.mixed().notRequired() }),
		// job_end_time: Yup.mixed().when("scheduleLater", { is: false, then: validator('required'), otherwise: Yup.mixed().notRequired() }),
		category_id: validator('required'),
		// startDateArray: Yup.mixed().when("scheduleLater", { is: false, then: Yup.array().min(1, 'Kindly select atleast one Date'), otherwise: Yup.array().notRequired() }),
		// staff_id_array: Yup.mixed().when("scheduleLater", { is: false, then: Yup.array().min(1, 'Kindly select atleast one Staff'), otherwise: Yup.array().notRequired() }),
		// endDate: Yup.string().when('interval', {
		// 	is: 1 || 2 || 3 || 4,
		// 	then: validator('required').nullable(),
		// 	otherwise: validator('notRequired'),
		// }),
		lineItems: Yup.mixed().when("customerSetting", { is: true, then: Yup.array().notRequired(), otherwise: Yup.array().min(1, 'Kindly add atleast one line item.') }),
		jobEditWarning: Yup.boolean().oneOf([true], "Please agree to these to update the job.")
		// preffered_time: Yup.mixed().when("scheduleLater", {
		// 	is: false,
		// 	then: Yup.string()
		// 		.test('not empty', 'Start time cant be empty', function (value) {
		// 			return !!value;
		// 		})
		// 		.test(
		// 			'preffered_time_test',
		// 			'Start time must be before end time',
		// 			function (value) {
		// 				const { job_end_time } = this.parent;
		// 				return isSameOrBefore(value, job_end_time);
		// 			},
		// 		),
		// 	otherwise: Yup.mixed().notRequired()
		// })
	});
};

const isSameOrBefore = (startTime, endTime) => {
	return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};

export const sendAttachmentValidation = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
	});
};

export const assignStaffToJobValidation = () => {
	return Yup.object().shape({
		staff_id: validator('required'),
	});
};

export const jobFinishedNotes = () => {
	return Yup.object().shape({
		jobfinished_notes: validator('required'),
	});
};

export const jobFinishSignature = () => {
	return Yup.object().shape({
		signature: validator('required'),
	});
};

export const cancelJobReasons = () => {
	return Yup.object().shape({
		cancel_job_reasons: validator('jobReason'),
	});
};

export const addUpdateStaffValidation = (opt = {}) => {
	const { staffType = '' } = opt;
	return Yup.object().shape({
		role: Yup.string(),
		name: validator('required'),
		color: validator('required'),
		email: validator('emailRequired'),
		// phone: validator('minLengthNumberRequired'),
		phone: validator('required'),
		// expertise: staffType === 'staff' && validator('requiredMultiSelect'),
		// authorityVal: validator('requiredMultiSelect'),
		authorityVal: Yup.mixed().when("role", { is: 'staff_admin', then: validator('requiredMultiSelect'), otherwise: Yup.mixed().notRequired() }),
		// address: validator('required'),
	});
};

export const contactSupportValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		email: validator('emailRequired'),
		description: validator('required'),
	});
};

export const addUpdateBranchValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		address: validator('required'),
		street1: validator('required'),
		city: validator('required'),
		province: validator('required'),
		country: validator('required'),
		email: validator('emailRequired'),
		// phone: validator('minLengthNumberRequired'),
		phone: validator('required'),
		alternate_no: validator('minLengthNumber'),
	});
};

export const assignBranchHeadToJobValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		email: validator('emailRequired'),
		// phone: validator('minLengthNumberRequired'),
		phone: validator('required'),
		address: validator('required'),
	});
};

export const companyNameValidations = () => {
	return Yup.object().shape({
		company_name: validator('required'),
	});
};

export const addEditTaxValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		rate: validator('intTax'),
	});
};

export const addUpdateServicesValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		currency_code: validator('required'),
		amount: validator('required'),
		category_id: validator('required'),
	});
};

export const contactTeamValidations = () => {
	return Yup.object().shape({
		email: validator('emailRequired'),
		name: validator('required'),
		phone: validator('required'),
	});
};

export const addUpdateServiceValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		currency_code: validator('required'),
		amount: validator('required'),
		product_type: validator("required"),
		// category_id: validator('required')
	});
};

export const addUpdateProductServiceValidation = () => {
	return Yup.object().shape({
		name: validator('required'),
		currency_code: validator('required'),
		amount: validator('required'),
		product_type: validator("required"),
		category_id: validator('required')
	});
};

export const changePasswordValidation = () => {
	return Yup.object().shape({
		old_password: validator('required'),
		password: validator('required'),
		password_confirmation: Yup.string()
			.required('Required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'), //validator('required'),
	});
};

export const changeStaffPasswordValidation = () => {
	return Yup.object().shape({
		// old_password: validator('required'),
		password: validator('required'),
		password_confirmation: Yup.string()
			.required('Required')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'), //validator('required'),
	});
};

export const acceptPaymentCashValidation = () => {
	return Yup.object().shape(
		{
			reference: Yup.string().when('email', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
			email: Yup.string().when('reference', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
		},
		[['reference', 'email']],
	);
};
export const acceptPaymentChequeValidation = () => {
	return Yup.object().shape(
		{
			cheque_number: validator('required'),
			bank_name: validator('required'),
			bank_branch: validator('required'),
			reference: Yup.string().when('email', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
			email: Yup.string().when('reference', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
		},
		[['reference', 'email']],
	);
};

export const acceptPaymentStripeValidation = () => {
	return Yup.object().shape(
		{
			cardholder_name: validator('required'),
			reference: Yup.string().when('email', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
			email: Yup.string().when('reference', {
				is: (value) => !value,
				then: validator('emailOrReferenceRequired'),
				otherwise: validator('notRequired'),
			}),
		},
		[['reference', 'email']],
	);
};

export const addDiscountFormValidations = (opt) => {
	const { isCustomDiscount, discountType, subTotal } = opt;
	return Yup.object().shape({
		discountVal:
			isCustomDiscount &&
			(discountType == 'percentage'
				? Yup.number()
					.min(1, 'Please type a valid Discount Percentage.')
					.max(100, 'Please type a valid Discount Percentage.')
					.required('Required')
				: Yup.number()
					.min(1, 'Please type a valid Discount Amount.')
					.max(subTotal, 'Please type a valid Discount Amount.')
					.required('Required')),
	});
};

export const generalSettingValidation = () => {
	return Yup.object().shape({
		timezone: validator('required'),
	});
};
