import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo@2x.png';
import location from '../../../assets/images/location.png';
import email from '../../../assets/images/email.png';
import phone from '../../../assets/images/phone.png';

export default class SiteFooter extends Component {
	render() {
		return (
			<footer>
				<div className="footer_top pt-5 pb-5">
					<div className="custom-container">
						<div className="row">
							<div className="col-md-4 col-12">
								<div className="column column1">
									<img src={logo} alt="Logo" className="img-fluid f_logo mb-4" />
									<p className="pr-5">
										Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
										stet cotidieque referrentur. Cu mea mucius docendi, veniam
										periculis eu cum, quo diam affert an. Nibh nostro no duo,
									</p>
								</div>
							</div>
							<div className="col-md-4 col-12">
								<div className="column column2">
									<h5 className="bold">Contact Info</h5>
									<div className="contact_detail">
										<div className="contact_row">
											<img
												src={location}
												alt=""
												className="img-fluid"
												width="22px"
											/>
											<p>
												2 St Gaberial Walk, <br />
												California
											</p>
										</div>
										<div className="contact_row">
											<img src={email} alt="" className="img-fluid" width="24px" />
											<p>info@servicecall.com</p>
										</div>
										<div className="contact_row">
											<img src={phone} alt="" className="img-fluid" width="23px" />
											<p>+1 454 45841</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12">
								<div className="column column3">
									<h5 className="bold">Quick Links</h5>
									<div className="row">
										<div className="col-sm-6 col-6">
											<ul>
												<li>
													<Link to="/services">Services</Link>
												</li>
												<li>
													<Link to="/features">Features</Link>
												</li>
												<li>
													<Link to="/pricing">Pricing</Link>
												</li>
											</ul>
										</div>
										<div className="col-sm-6 col-6">
											<ul>
												<li>
													<Link to="/blog">Blog</Link>
												</li>
												<li>
													<Link to="/faq">FAQs</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer_bottom text-center">
					<div className="custom-container">
						<p className="copyrights">Copyrights. All rights Reserved @2019</p>
					</div>
				</div>
			</footer>
		);
	}
}
