import React, { Component } from 'react';
import {
	secondsToTime,
	padValue,
	isObjectIsEmpty,
} from '../../../../modules/utils';

export default class Timer extends Component {
	constructor(props) {
		super(props);
		this.state = { time: {}, seconds: props.timeInSec };
		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
	}

	componentDidMount() {
		this.startTimer();
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	// componentWillReceiveProps(nextProps) {
	// 	console.log(
	// 		'TCL ~ Timer ~ componentWillReceiveProps ~ nextProps',
	// 		nextProps,
	// 	);
	// 	console.log(
	// 		'TCL ~ Timer ~ componentWillReceiveProps ~ this.props',
	// 		this.props,
	// 	);
	// 	return;
	// 	if (!nextProps.isEnableResendBtn) {
	// 		this.timer = 0;
	// 		this.setState({ seconds: nextProps.timeInSec }, () => {
	// 			this.startTimer();
	// 		});
	// 	}
	// }

	startTimer = () => {
		const timeLeftVar = secondsToTime(this.state.seconds);
		this.setState({ time: timeLeftVar });

		if (this.timer === 0 && this.state.seconds > 0) {
			this.timer = setInterval(this.countDown, 1000);
		}
	};

	countDown = () => {
		const { onCompleteTimer } = this.props;
		let seconds = this.state.seconds - 1;
		this.setState({
			time: secondsToTime(seconds),
			seconds: seconds,
		});

		if (seconds === 0) {
			clearInterval(this.timer);
			if (onCompleteTimer) onCompleteTimer();
		}
	};

	render() {
		const { time } = this.state;

		if (isObjectIsEmpty(time)) {
			return '';
		}

		return (
			<React.Fragment>
				{padValue(time.min.toString(), 2, 0)} :{' '}
				{padValue(time.sec.toString(), 2, 0)}
			</React.Fragment>
		);
	}
}
