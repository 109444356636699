import React, { Component } from 'react';
import {
    CRow,
    CCol,
    CCard,
    CCardBody,
    CCardFooter,
    CButton,
    CInput,
    CSelect,
    CFormGroup,
    CLabel,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter, CTextarea, CInputCheckbox
} from '@coreui/react';
import LaddaButton, { SLIDE_UP } from '@zumper/react-ladda';
import closeSvg from '../../assets/images/close.svg'

export class SignUpModal extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        const {
            isOpen,
            toggle,
        } = this.props;

        return (
            <div>
                <CModal show={isOpen} onClose={toggle} size="lg" className="add-modal">
                    <CModalHeader closeButton>
                        <h5 className="modal-title">
                            FieldCamp
                        </h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => { this.props.toggle() }}>
                            <span aria-hidden="true"><img src={closeSvg} alt="Close" /></span>
                        </button>
                    </CModalHeader>
                    <CModalBody>
                        <span className="font16 colorBlack">
                            The Email address doesn't exist in our records. Please create new account with this email.
                        </span>
                    </CModalBody>
                    <CCardFooter className="mt-3">
                        <div className="text-right mb-0">
                            <CButton
                                style={{ height: "35px", padding: 0 }}
                                className="mr-2 btn btn-outline-secondary font14"
                                // color="secondary"
                                onClick={() => {
                                    this.props.toggle()
                                }}
                            >
                                No
                            </CButton>
                            <CButton
                                style={{ height: "35px", padding: 0 }}
                                className="btn btn-primary font14"
                                color="primary"
                                onClick={() => {
                                    this.props?.pushLoc?.push({ pathname: '/register', state: { email: this.props.values.email } })
                                }}
                            >
                                Signup
                            </CButton>
                        </div>
                    </CCardFooter>
                </CModal>
            </div>
        );
    }
}

export default SignUpModal
