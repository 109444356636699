import axios from 'axios';

// import { setLogout } from './auth/checkToken';

import settings from '../settings';

export const apiConfig = () => {
	if (localStorage.getItem('authToken')) {
		return {
			headers: {
				Authorization: localStorage.authToken,
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE',
				'X-FC-App-Platform': 2
			},
		};
	}
	else if (localStorage.getItem("verifyUser") && localStorage.getItem("verifyToken")) {
		return {
			headers: {
				Authorization: localStorage.verifyToken,
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE',
				'X-FC-App-Platform': 2
			},

		};
	}
	else {
		return {
			headers: {
				// Authorization: localStorage.verifyToken,
				// 'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE',
				'X-FC-App-Platform': 2
			},

		};
	}
	return {};
};

export const getApi = (url, apiVal = null) => {

	let paramStr = '';
	if (apiVal) {
		paramStr = Object.keys(apiVal)
			.map(function (k) {
				return encodeURIComponent(k) + '=' + encodeURIComponent(apiVal[k]);
			})
			.join('&');

		if (paramStr && paramStr !== '') paramStr = '?' + paramStr;
	}

	const _url = `${settings.endPoint}${url}${paramStr}`;

	// console.log('TCL ~ getApi ~ `${settings.endPoint}${url}${paramStr}`', {
	// 	url: `${settings.endPoint}${url}${paramStr}`,
	// });

	return axios.get(
		`${settings.endPoint}${url}${paramStr}`.replace(/[^\x20-\x7E]/g, ''),
		apiConfig(),
	);
};

export const postApi = (url, apiData) => {
	console.log(apiData);
	console.log(apiConfig());
	return axios.post(
		`${settings.endPoint}${url}`.replace(/[^\x20-\x7E]/g, ''),
		apiData,
		apiConfig(),
	);
};

export const putApi = (url, apiData) => {

	return axios.put(
		`${settings.endPoint}${url}`.replace(/[^\x20-\x7E]/g, ''),
		apiData,
		apiConfig(),
	);
};

export const patchApi = (url, apiData) => {
	return axios.patch(
		`${settings.endPoint}${url}`.replace(/[^\x20-\x7E]/g, ''),
		apiData,
		apiConfig(),
	);
};

export const deleteApi = (url, apiVal = null) => {
	let paramStr = '';
	if (apiVal) {
		paramStr = Object.keys(apiVal)
			.map(function (k) {
				return encodeURIComponent(k) + '=' + encodeURIComponent(apiVal[k]);
			})
			.join('&');

		if (paramStr && paramStr !== '') paramStr = '?' + paramStr;
	}
	return axios.delete(`${settings.endPoint}${url}${paramStr}`, apiConfig());
};

export const setupInterceptors = (store) => {
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if ([401].includes(error.response.status)) {
				// store.dispatch(setLogout());
				window.location.reload();
			}

			return Promise.reject(error);
		},
	);
};
