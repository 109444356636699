import {
	FETCH_ADMIN_AUTHORITY_REQUEST,
	FETCH_ADMIN_AUTHORITY_SUCCESS,
	FETCH_ADMIN_AUTHORITY_FAILURE,
} from '../../types/common';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

const AdminAuthoritiesReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ADMIN_AUTHORITY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_ADMIN_AUTHORITY_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_ADMIN_AUTHORITY_FAILURE:
			return {
				...state,
				isLoading: false,
				data: null,
				error: action.error.data,
			};
		default:
			return state;
	}
};

export default AdminAuthoritiesReducer;
