import { Formik } from 'formik';
import React, { Component } from 'react';
import locationGreyImg from '../../assets/images/location_gray.png';
import {
	displayFormErrors,
	registerNextStepValidations,
} from '../../modules/validations';
import { mixpostApi } from '../../mixpanel/api'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import GooglePlaces from '../common/site/GooglePlaces';
import { decryptLoginData } from '../../modules/utils'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
export default class SetupType extends Component {
	handleAddressSelect = async (address, setFieldValue) => {
		const geoCodeAddress = geocodeByAddress(address);

		await geoCodeAddress
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				console.log('Success', latLng);
				setFieldValue('latitude', latLng.lat);
				setFieldValue('longitude', latLng.lng);
			})
			.catch((error) => console.error('Error', error));

		await geoCodeAddress
			.then((results) => {
				console.log('TCL ~ SetupType ~ .then ~ results', results);

				results[0].address_components.forEach((d) => {
					if (d.types.includes('country')) {
						setFieldValue('country', d.long_name);
					}

					if (
						d.types.includes('political') &&
						d.types.includes('administrative_area_level_1')
					) {
						setFieldValue('province', d.long_name);
					}

					if (d.types.includes('locality')) {
						setFieldValue('city', d.long_name);
					}

					if (d.types.includes('route')) {
						setFieldValue('street1', d.long_name);
					}

					if (
						d.types.includes('sublocality') &&
						d.types.includes('sublocality_level_2')
					) {
						setFieldValue('street2', d.long_name);
					}

					if (d.types.includes('postal_code')) {
						setFieldValue('zipcode', d.long_name);
					}
				});
				mixpostApi(decryptLoginData(), 'place_api_click_event')

			})
			.catch((error) => console.error('Error', error));
	};

	render() {
		const {
			selectedType,
			onHandleSetupTypeDataSubmit,
			gotoNextStep,
		} = this.props;
		return (
			<React.Fragment>
				<h4 className="text-center pt-5 pb-5">
					{selectedType == 'individual' ? 'Individual' : 'Company'} Setup
				</h4>
				<Formik
					initialValues={{
						name: '',
						address: '',
						suite_no: '',
						street1: '',
						street2: '',
						city: '',
						province: '',
						country: '',
						zipcode: '',
						website: '',
						phone: '',
						phone_country_code: '91',
						alternate_no: '',
						alternate_phone_country_code: '91',
						latitude: '',
						longitude: '',
					}}
					validationSchema={registerNextStepValidations()}
					onSubmit={onHandleSetupTypeDataSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						submitCount,
						setFieldValue,
					}) => {
						const showError = (key) =>
							displayFormErrors(key, errors, touched, submitCount);

						return (
							<form onSubmit={handleSubmit}>
								<div className="form_input mb-3">
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">
													{selectedType == 'individual'
														? 'Individual'
														: 'Organization'}{' '}
													Name
												</label>
												<input
													type="text"
													name="name"
													className="form-control"
													value={values.name}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('name')}
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Address</label>
												<div className="addr_icon">
													<GooglePlaces
														onChangeVal={(val) => {
															setFieldValue('address', val);
														}}
														onSelectVal={(val) => {
															setFieldValue('address', val);
															this.handleAddressSelect(val, setFieldValue);
														}}
														address={values.address}
														name="address"
													/>
													{showError('address')}
													<img
														src={locationGreyImg}
														alt=""
														className="img-fluid icon"
														width="16px "
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Suit</label>
												<input
													type="text"
													name="suite_no"
													className="form-control"
													value={values.suite_no}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('suite_no')}
											</div>
										</div>

										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Street1</label>
												<input
													type="text"
													name="street1"
													className="form-control"
													value={values.street1}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('street1')}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Street2</label>
												<input
													type="text"
													name="street2"
													className="form-control"
													value={values.street2}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('street2')}
											</div>
										</div>

										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">City</label>
												<input
													type="text"
													name="city"
													className="form-control"
													value={values.city}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('city')}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">State</label>
												<input
													type="text"
													name="province"
													className="form-control"
													value={values.province}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('province')}
											</div>
										</div>

										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Country</label>
												<input
													type="text"
													name="country"
													className="form-control"
													value={values.country}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('country')}
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Zip code</label>
												<input
													type="text"
													className="form-control"
													placeholder="360 002"
													name="zipcode"
													value={values.zipcode}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('zipcode')}
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Website</label>
												<input
													type="text"
													className="form-control"
													placeholder="www.example.com"
													name="website"
													value={values.website}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('website')}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">Phone Number</label>
												<div className="phone_no">
													<div className="code">
														<PhoneInput
															country={decryptLoginData()?.company_dial_code?.code == "CA" ? "ca" : "us"}
															// country="us"
															inputProps={{
																name: 'phone_country_code',
																tabindex: "-1"
															}}
															value={values.phone_country_code}
															onChange={(val) => {
																setFieldValue('phone_country_code', val);
															}}
															countryCodeEditable={false}
															enableSearch={true}
															inputClass="select2 disablePhoneInput"
															placeholder=""
														/>
														{showError('phone_country_code')}
													</div>
													<div className="number">
														<input
															type="text"
															className="form-control"
															placeholder="(202) 555-5555"
															name="phone"
															value={values.phone}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														{showError('phone')}
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="form-group">
												<label className="control_label">
													Alternate Phone Number
												</label>
												<div className="phone_no">
													<div className="code">
														<PhoneInput
															country={decryptLoginData()?.company_dial_code?.code == "CA" ? "ca" : "us"}
															// country="us"
															inputProps={{
																name: 'alternate_phone_country_code',
																tabindex: "-1"
															}}
															value={values.alternate_phone_country_code}
															onChange={(val) => {
																setFieldValue(
																	'alternate_phone_country_code',
																	val,
																);
															}}
															countryCodeEditable={false}
															enableSearch={true}
															inputClass="select2 disablePhoneInput"
															placeholder=""
														/>
														{showError('alternate_phone_country_code')}
													</div>
													<div className="number">
														<input
															type="text"
															className="form-control"
															placeholder="(202) 555-5555"
															name="alternate_no"
															value={values.alternate_no}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														{showError('alternate_no')}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="submit_btn modal_footer text-right ">
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											gotoNextStep('chooseType');
										}}
									>
										Back
									</button>
									<button
										type="submit"
										onClick={handleSubmit}
										className="btn btn-primary ml-2"
									>
										Next
									</button>
								</div>
							</form>
						);
					}}
				</Formik>
			</React.Fragment>
		);
	}
}
