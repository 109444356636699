import React, { Component } from 'react';

import SiteHeader from '../common/site/Header';
import SiteWelcome from '../common/site/Welcome';
import SiteSupportMail from '../common/site/SupportMail';
import SiteFooter from '../common/site/Footer';

import ss from '../../assets/images/ss.png';
import ss2 from '../../assets/images/ss2.png';

import '../../assets/css/bootstrap.min.css';
// import '../../assets/fonts/maisonNeue/font.css';
import '../../assets/css/style.css';

export default class Features extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="wrapper">
					<SiteHeader />

					<div className="page_content">
						<SiteWelcome />

						<section className="center_feature_sec pb-80 pt-80">
							<div className="custom-container">
								<div className="row">
									<div className="col-12 col-lg-10 col-xl-8 mx-auto">
										<div className="content">
											<h3 className="bold text-center">
												Main Features Of Service Call
											</h3>
											<p className="mb-4 text-center">
												Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
												stet cotidieque referrentur. Cu mea mucius docendi,
												veniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quoveniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quo
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="feature_screenshot pt-5 pb-5">
							<div className="custom-container">
								<div className="feature_detail">
									<div className="row">
										<div className="col-md-6 col-12 align-self-center">
											<div className="img text-center">
												<img src={ss} alt="" className="img-fluid" />
											</div>
										</div>
										<div className="col-md-6 col-12 align-self-center">
											<div className="detail">
												<h3 className="bold mb-5">Creating Jobs</h3>
												<div className="feature_desc mb-4">
													<p className="feature_title bold">Assigning Team</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
												<div className="feature_desc pt-2">
													<p className="feature_title bold">Job Schedule</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="feature_bg pt-5 pb-5">
							<div className="custom-container">
								<div className="feature_detail">
									<div className="row">
										<div className="col-md-6 col-12 align-self-center">
											<div className="detail">
												<h3 className="bold mb-5">Payment Processing</h3>
												<div className="feature_desc mb-4">
													<p className="feature_title bold">Creating Staff</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
												<div className="feature_desc pt-2">
													<p className="feature_title bold">Job Schedule</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-12 align-self-center">
											<div className="img text-center">
												<img src={ss2} alt="" className="img-fluid" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="feature_screenshot pt-5 pb-5">
							<div className="custom-container">
								<div className="feature_detail">
									<div className="row">
										<div className="col-md-6 col-12 align-self-center">
											<div className="img text-center">
												<img src={ss} alt="" className="img-fluid" />
											</div>
										</div>
										<div className="col-md-6 col-12 align-self-center">
											<div className="detail">
												<h3 className="bold mb-5">Customer Management</h3>
												<div className="feature_desc mb-4">
													<p className="feature_title bold">Creating Customers</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
												<div className="feature_desc pt-2">
													<p className="feature_title bold">Creating Contract</p>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<SiteSupportMail />
					</div>
				</div>
				<SiteFooter />
			</React.Fragment>
		);
	}
}
