import {
	FETCH_CATEGORY_REQUEST,
	FETCH_CATEGORY_SUCCESS,
	FETCH_CATEGORY_FAILURE,
} from '../../types/services';
import settings from '../../settings';
import { getApi } from '../api';

export const fetchCategoryRequest = () => ({
	type: FETCH_CATEGORY_REQUEST,
});

export const fetchCategorySuccess = (data) => ({
	type: FETCH_CATEGORY_SUCCESS,
	data,
});

export const fetchCategoryFailure = (error) => ({
	type: FETCH_CATEGORY_FAILURE,
	error,
});

export const fetchCategory = (data) => (dispatch) => {
	const formData = {
		...data,
		is_web_request: true,
	};

	dispatch(fetchCategoryRequest());

	return getApi(settings.apiUrl.services.category, formData)
		.then((data) => {
			if (data?.data?.responseCode === 200) {
				dispatch(fetchCategorySuccess(data));
			} else {
				dispatch(fetchCategoryFailure(data));
			}
		})
		.catch((error) => {
			dispatch(fetchCategoryFailure(error));
		});
};
