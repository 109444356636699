const settings = {
	endPoint: process.env.REACT_APP_API_ENDPOINT,
	debounceWait: process.env.REACT_APP_DEBOUNCE_WAIT,

	apiUrl: {
		user: {
			profileDetail: '/users/user_profile_get',
			socialLogin: '/registrations/social_login',
			verifyUser: '/users/verify_user',
			resendOtp: '/users/resend_otp',
			updateProfileAllUser: '/users/update_profile_all_users',
			changePassword: '/users/password',
			getTeamMember: '/users/get_team_members',
			generalDetail: '/users/general_data',
			fcSetup: '/users/update_fc_setup',
		},
		common: {
			countries: '/countries',
			stateList: '/states',
			cityList: '/cities',
			adminAuthority: '/admin_authorities',
			languages: '/languages',
			currencies: '/currencies',
			cancelJobReasons: '/static_contents/cancel_job_reasons',
		},
		services: {
			category: '/categories',
			detail: '/services',
			add: '/services',
			update: '/services',
			delete: '/services',
			services: '/services/list_services',
		},
		dashboard: {
			jobCount: '/dashboard/day_wise_job_count',
			dashboard: '/dashboard',
			activeStaffList: '/dashboard/active_staffs',
		},
		invoice: {
			painUnpaidInvoice: '/jobs/paid_unpaid_invoices',
			generateInvoice: '/jobs/get_invoice',
			invoiceDueDays: '/settings/fetch_invoice_due_days'
		},

		earning: {
			earning: '/earnings',
			graph: '/earnings/earning_graph',
			list: '/earnings/listings',
		},
		branch: {
			list: '/branches',
			detail: '/branches',
			add: '/branches',
			update: '/branches',
			changeStatus: '/branches/branch_operations',
			addBranchHead: '/branches/add_branch_head',
			updateBranchHead: '/branches/update_branch_head',
			removeBranchHead: '/branches/remove_branch_head',
		},
		job: {
			list: '/jobs/job_listing',
			delete: '/jobs',
			add: '/jobs',
			update: '/jobs',
			categoryStaff: '/jobs/category_staffs',
			detail: '/jobs',
			manageJob: '/jobs/manage_job',
			jobHistoryUpdate: '/jobs/job_history_update',
		},
		tax: {
			list: '/taxes',
			add: '/taxes',
			update: '/taxes',
			delete: '/taxes',
		},
		company: {
			userCategory: '/categories/listing_of_user_categories',
			updateUserCategory: '/categories/update_category_for_user',
			getServices: '/categories/{categoryId}/services',
			deleteCategory: '/categories',
			getCompany: '/companies',
		},
		customer: {
			list: '/customers/list_customers',
			add: '/customers',
			update: '/customers',
			delete: '/customers',
			detail: '/customers',
		},
		property: {
			list: '/properties',
			add: '/properties',
			update: '/properties',
			delete: '/properties',
			detail: '/properties',
		},
		contract: {
			sendAttachment: '/contracts/send_attachment',
		},
		staff: {
			list: '/staffs/staffs_listing',
			delete: '/staffs',
			add: '/staffs',
			update: '/staffs/update_staff',
			detail: '/staffs',
			activeInactiveStaff: '​/​staffs​/staff_active_inactive',
			completedJobs: '/​​staffs​/​get_staff_jobs',
			changeStaffPassword: '/staffs/change_password'
		},
		staticContent: {
			getContent: '/static_contents/type_request',
			updateContent: '/static_contents',
		},
		notification: {
			list: '/notifications',
			destroyAll: '/notifications/destroy_all',
			markRead: '/notifications/{id}/mark_as_read',
			markReadAll: '​/notifications​/mark_as_read_for_all_notifications',
		},
		setting: {
			getCustomerSetting: '/settings/get_customer_setting',
			updateCustomerSetting: '/settings/update_customer_setting',
			updateTeamSetting: '/settings/update_team_setting',
			updateJobSetting: '/settings/update_job_setting',
			updateVerificationSetting: '/users/select_status',
			stripeCode: '/get_stripe_token',
		},
		contact: {
			contactToSupport: '/contact_us',
		},
		payment: {
			addMasterTransaction: '/master_transactions',
			stripe_payment_configurataion:
				'/payment_configuration/stripe_payment_configurataion',
			addSubscription: '/subscriptions/',
			generateInvoicePaid: "/jobs/bulk_cashout"
		},
		subsucription: {
			getPlanList: '/plans',
		},
		companyNotification: {
			templateVariables: '/company_notification_settings/template_variables',
			settingsList: '/company_notification_settings',
			updateSetting: '/company_notification_settings​',
			enableSetting: '/company_notification_settings',
		},
		customerNotification: {
			updateSetting: '/customer_notification_settings',
			getSetting: '/customer_notification_settings'
		},
		jobForm: {
			form: '/forms',
			submitForm: '/jobs/save_job_form',
			emailForm: '/company_templates',
			downloadForm: '/jobs/download_saved_job_form',
			sendEmail: '/client_notifications'
		},
		schedule: {
			dispatchMap: '/jobs/dispatch_map',
			calendarSettings: '/settings/update_job_setting'
		}
	},
};

export default settings;
