import React, { Component } from 'react';
import { CModal, CModalHeader, CModalBody } from '@coreui/react';
import OtpVerificationForm from './form';
import closeSvg from "../../../../assets/images/close.svg"
// import "../../../../assets/css/design.css"
export default class OtpVerificationModal extends Component {

	render() {
		const {
			isOpen,
			toggle,
			onHandleVerifyOtp,
			resendOtp,
			otp,
			onCompleteTimer,
			handleOtpChange,
			userData,
			isEnableResendBtn,
			isTimerRunning,
		} = this.props;

		return (
			<CModal show={isOpen} onClose={toggle} size="lg" className="add-modal">
				<CModalHeader closeButton>
					<h5 className="modal-title">
						Email Verification
					</h5>
					<button type="button" className="close" aria-label="Close" onClick={() => { this.props.toggle() }}>
						<span aria-hidden="true"><img src={closeSvg} alt="Close" /></span>
					</button>
				</CModalHeader>
				<CModalBody className="text-center">
					<OtpVerificationForm
						onSubmitForm={onHandleVerifyOtp}
						verifyUserLoading={this.props.verifyUserLoading}
						otp={otp}
						resendOtp={resendOtp}
						onCompleteTimer={onCompleteTimer}
						handleOtpChange={handleOtpChange}
						userData={userData}
						isEnableResendBtn={isEnableResendBtn}
						isTimerRunning={isTimerRunning}
					/>
				</CModalBody>
			</CModal>
		);
	}
}
