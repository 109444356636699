import React from 'react';
import { CRow, CCol, CLink, CCard, CCardBody } from '@coreui/react';

const aboutUsLinks = [
	{
		name: 'Privacy Policy',
		url: process.env.REACT_APP_PRIVACY_POLICY,
	},
	{
		name: 'Terms of Service',
		url: process.env.REACT_APP_TERMS_OF_SERVICE,
	},
	{
		name: 'About FieldCamp',
		url: process.env.REACT_APP_ABOUT_FIELDCAMP,
	},
	{
		name: 'Share FieldCamp App',
		url: process.env.REACT_APP_SHARE_FIELDCAMP_APP,
	},
];

function AboutUs() {
	return (
		<React.Fragment>
			<CRow>
				<CCol xl="12" md="12" className="mb-3">
					<h3 className="page-title">About Us</h3>
				</CCol>
				<CCol xl="12" md="12" className="mb-3">
					<CCard className="col-md-12">
						{aboutUsLinks.map((link) => {
							return (
								<CCardBody>
									<div className="row col-md-12">
										<CLink
											href={link.url}
											target="_blank"
											style={{
												textDecoration: 'none',
												color: 'rgba(0, 0, 21, 0.5)',
											}}
										>
											{link.name}
										</CLink>
									</div>
								</CCardBody>
							);
						})}
					</CCard>
				</CCol>
			</CRow>
		</React.Fragment>
	);
}

export default AboutUs;
