import { toast } from 'react-toastify';
import moment from 'moment';
import { result } from 'lodash';
import { countryList } from './countryList';
import icon1 from '../assets/images/icon-1.png';
import { green, deepOrange, lightBlue, grey } from '@material-ui/core/colors';
import icn_2 from '../assets/images/icn_2.png';
import icn_3 from '../assets/images/icn_3.png';
import icn_4 from '../assets/images/icn_4.png';
import icn_5 from '../assets/images/icn_5.png';
import icn_6 from '../assets/images/icn_6.png';
import CryptoJS from 'crypto-js';
import { createFilter } from 'react-select';
import { render } from 'react-dom';
import { styled } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const OPERATION_Add = 1;
export const OPERATION_Read = 2;
export const OPERATION_Update = 3;
export const OPERATION_Delete = 4;
export const OPERATION_TrackOwnJob = 5;
export const OPERATION_TrackAllJob = 6;

export const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		paddingTop: "8px"
	},
}));

export const apiToastSuccess = (data) => {
	if (data && data.data && data.data.message) {
		showToast(data.data.message, 1);
	} else if (data && data.data && data.data.responseMessage) {
		showToast(data.data.responseMessage, 1);
	}
};

export const apiToastCustomMessage = (data) => {
	if (data && data.data && data.data.message) {
		// showToast(data.data.message, 1);
		showToast('Payment Method Updated Successfully', 1);
	} else if (data && data.data && data.data.responseMessage) {
		showToast('Payment Method Updated Successfully', 1);

		// console.log('data.data.responseMessage', data.data.responseMessage);
		// showToast(data.data.responseMessage, 1);
	}
};

// showToast('Payment Method Updated Successfully÷', 1);
export const apiToastError = (error) => {
	if (
		error &&
		error.response &&
		error.response.data &&
		error.response.data.responseMessage
	) {
		showToast(error.response.data.responseMessage, 0);
	} else if (error && error.data && error.data.responseMessage) {
		showToast(error.data.responseMessage, 0);
	} else if (error && error.responseMessage) {
		showToast(error.responseMessage, 0);
	}
};

export const showToast = (msg, status) => {
	if (status) {
		toast.success(msg);
	} else {
		toast.error(msg);
	}
};

export const getApiSingleDetails = (data, defaultReturn = null) => {
	if (data && data.responseData && Object.keys(data.responseData).length > 0) {
		return data.responseData;
	}

	return defaultReturn;
};

export const getApiArrayDetails = (data, defaultReturn = null) => {
	if (
		data &&
		data.responseData &&
		Array.isArray(data.responseData) &&
		data.responseData.length > 0
	) {
		return data.responseData;
	}

	return defaultReturn;
};

export const getApiArrayFirstDetails = (data, defaultReturn = null) => {
	if (
		data &&
		data.responseData &&
		Array.isArray(data.responseData) &&
		data.responseData.length > 0 &&
		data.responseData[0]
	) {
		return data.responseData[0];
	}

	return defaultReturn;
};

export const isEmpty = (value) => {
	return (
		value === undefined || value === null || value === '' || value === 'null'
	);
};

export const eventDateTimeFormat = `h:mm A, ddd, DD MMM'YY`;
export const eventTimeFormat = `h:mm A`;
export const timeFormat = 'h:mm A';
export const apiTimeFormat = 'YYYY-MM-DD h:mm:ss';
export const jobSchedulerTimeFormat = 'YYYY-MM-DDThh:mm';
export const currentDateTime = moment().format(apiTimeFormat);
export const noDataText = 'No data Found!';
export const currentTimeStamp = moment().toDate().getTime();
export const pageSize = 10;
export const dateFormat =
	(localStorage.getItem('dateFormat') == 'MMM dd, yyyy'
		? 'MMM D, YYYY'
		: localStorage.getItem('dateFormat')) || 'MMMM D, YYYY';
export const currentDate = moment().format(dateFormat);
export const dateTimeFormat = 'MMM DD, YYYY hh:mm A';
export const currentDayName = moment().format('dddd');

export const showEventDateTime = (start, end) => {
	if (start && end && moment(start).isValid() && moment(end).isValid()) {
		const startDateTime = moment(start).format(eventDateTimeFormat);
		const startTime = moment(start).format(eventTimeFormat);
		const endDateTime = moment(end).format(eventDateTimeFormat);

		let returnString = '';

		if (
			moment(start).isSame(end, 'year') &&
			moment(start).isSame(end, 'month') &&
			moment(start).isSame(end, 'day')
		) {
			returnString = `${startTime} to ${endDateTime}`;
		} else {
			returnString = `${startDateTime} to ${endDateTime}`;
		}

		return returnString.split(':00').join('');
	}
	return '';
};

export const momentToUnix = (momentObj) => {
	if (momentObj) return momentObj.toDate().getTime();
	return '';
};

// export const timestampToDateTime = (d) => {

// 	console.log(d);

// 	if (d.toString().length != 10) {
// 		d = parseInt(d.toString().substring(0, 10));
// 	}

// 	if (d && moment(d).isValid()) {
// 		return moment.unix(d).format(dateTimeFormat);
// 	}
// 	return '';
// };

export const timestampToDateTime = (d) => {
	if (d && d?.toString().length != 10) {
		d = parseInt(d.toString().substring(0, 10));
	}

	if (d && moment(d).isValid()) {
		return localStorage.getItem("dateFormat") ? moment.unix(d).format(localStorage.getItem("dateFormat") == "MMM D,yyyy" ? "MMM D, yyyy" : localStorage.getItem("dateFormat")) : moment.unix(d).format(dateTimeFormat);
	}
	return '';
};

export const groupBy = (array, key) => {
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		);
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result;
	}, {}); // empty object is the initial value for result object
};

export const timestampToDateTimeHistory = (d) => {
	var theDate = new Date(d);
	let dateString = theDate.toLocaleString();
	return dateString;
};

export const timestampToDate = (d) => {
	if (!d) return '';

	if (d.toString().length != 10) {
		d = parseInt(d.toString().substring(0, 10));
	}

	if (d && moment(d).isValid()) {
		return moment.unix(d).format(dateFormat == "MMM D,yyyy" ? "MMM D, yyyy" : dateFormat);
	}
	return '';
};

export const scheduleStartTime = (d) => {
	if (!d) return '';

	if (d.toString().length != 10) {
		d = parseInt(d.toString().substring(0, 10));
	}

	if (d && moment(d).isValid()) {
		return moment.unix(d).format('MMM DD, YYYY');
	}
	return '';
};

export const timestampToTime = (d) => {
	if (!d) return '';
	if (d.toString().length != 10) {
		d = parseInt(d.toString().substring(0, 10));
	}

	if (d && moment(d).isValid()) {
		return moment
			.unix(d)
			.format(localStorage.getItem('timeFormat') == 1 ? 'H:mm' : 'h:mm A');
	}
	return '';
};

export const timestampToHMS = (d, type) => {
	if (!d) return '';

	if (d && moment(d).isValid()) {
		var d = new Date(d);
		if (type == 'date') return d.getDate();
		if (type == 'month') return d.getMonth();
		if (type == 'year') return d.getFullYear();
		if (type == 'hour') return d.getHours();
		if (type == 'min') return d.getMinutes();
	}
	return '';
};

export const timeToHM = (d, type) => {
	if (!d) return '';

	if (d && moment(d).isValid()) {
		var d = new Date(d);
		return `${d.getHours()}:${d.getMinutes()}`;
	}
	return '';
};

export const timestampToMomentObj = (d) => {
	if (!d) return moment();
	return moment(d);
};

export const dateToTimestamp = (d) => {
	if (d.toString().length != 10) {
		d = parseInt(d.toString().substring(0, 10));
	}

	if (d && moment(d).isValid()) {
		return moment.unix(d).format(dateFormat);
	}
	return '';
};

export const getCurrentDateFromSelectedFormat = (format) => {
	return moment().format(format);
};

export const getPages = (totalPage, perPage = 10) => {
	return totalPage ? Math.ceil(totalPage / perPage) : 0;
};

export const reorderArray = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const sortArrayByKey = (data, key) => {
	if (data && data.length > 0) {
		return data.sort((a1, b1) => {
			const a = a1[key];
			const b = b1[key];
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
			// return a.localeCompare(b);
		});
	}
	return data;
};

export const getTimeDiffInMinutes = (start, end) => {
	if (start && end && moment(start).isValid() && moment(end).isValid()) {
		const startDateTime = moment(start);
		const endDateTime = moment(end);
		return moment.duration(endDateTime.diff(startDateTime)).asMinutes();
	}
	return 0;
};

// export const getNotificationTimeDiff = (start, end) => {
// 	let returnVal = '';

// 	if (start && end && moment(start).isValid() && moment(end).isValid()) {
// 		const startDateTime = moment(start);
// 		const endDateTime = moment(end);
// 		moment.duration(endDateTime.diff(startDateTime)).asMinutes();
// 	}
// 	return 0;
// };

export const getDateTimeRange = (start, end, minutes = 30) => {
	if (start && end && moment(start).isValid() && moment(end).isValid()) {
		const startDateTime = moment(start);
		const endDateTime = moment(end);
		const durationInMinutes = moment
			.duration(endDateTime.diff(startDateTime))
			.asMinutes();

		if (durationInMinutes >= minutes) {
			let data = [
				{
					start: startDateTime.format('YYYY-MM-DD HH:mm:ss'),
					end: startDateTime
						.add(minutes, 'minutes')
						.format('YYYY-MM-DD HH:mm:ss'),
				},
			];

			for (let i = 1; i < durationInMinutes / minutes; i++) {
				data = [
					...data,
					{
						start: startDateTime.format('YYYY-MM-DD HH:mm:ss'),
						end: startDateTime
							.add(minutes, 'minutes')
							.format('YYYY-MM-DD HH:mm:ss'),
					},
				];
			}

			return data;
		}
	}

	return [];
};

export const getFileType = (type) => {
	if (type.startsWith('video/')) {
		return 'video';
	}
	if (type.startsWith('image/')) {
		return 'image';
	}
	return type;
};

export const fileToBase64 = (e) => {
	return new Promise((resolve, reject) => {
		if (e && e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const reader = new FileReader();

			// Read file content on file loaded event
			reader.onload = function (event) {
				resolve({
					file: event.target.result,
					type: getFileType(file.type),
				});
			};

			// Convert data to base64
			reader.readAsDataURL(file);
		} else {
			reject(new Error('Something went wrong.'));
		}
	});
};

export const fileToBase64WithoutEvent = (files, cb) => {
	return new Promise((resolve) => {
		const file = files[0];

		var reader = new FileReader();
		// Read file content on file loaded event
		reader.onload = function (event) {
			resolve({
				file: event.target.result,
				type: getFileType(file.type),
			});
		};

		// Convert data to base64
		reader.readAsDataURL(file);
	});
};

export const showEventSegmentTimeRange = (start, end) => {
	if (start && end && moment(start).isValid() && moment(end).isValid()) {
		const startTime = moment(start).format(eventTimeFormat);
		const endTime = moment(end).format(eventTimeFormat);

		if (moment(start).format('A') !== moment(end).format('A')) {
			return `${startTime} to ${endTime}`;
		} else {
			return `${startTime.replace(
				` ${moment(start).format('A')}`,
				'',
			)} to ${endTime}`;
		}
	}
	return '';
};

export const convertUTC = (date, formate) => {
	return moment(date).format(formate);
};

export const setCreateApiData = (formData, state) => {
	if (formData) {
		const authUser = state.authUser.user;

		if (authUser) {
			return {
				...formData,
				org_id: authUser.org_id || undefined,
				organizer_id: authUser.org_id || undefined,
				crtd_by: authUser._id || undefined,
				created_id: authUser._id || undefined,
			};
		}
	}

	return formData;
};

export const setUpdateApiData = (formData, state) => {
	if (formData) {
		const authUser = state.authUser.user;

		if (authUser) {
			return {
				...formData,
				updt_by: authUser._id || undefined,
			};
		}
	}

	return formData;
};

export const prettyText = (string) => {
	return string
		.toLowerCase()
		.replace(/_/g, ' ')
		.split(' ')
		.map((word) => {
			return word[0].toUpperCase() + word.substr(1);
		})
		.join(' ');
};

export const trimString = (string = '', amount = 10) => {
	const stringSplit = typeof string === 'string' ? string.split(' ') : [];
	let outputString = '';
	let isIncomplete = false;

	stringSplit.forEach((str) => {
		if (str.length + outputString.length + 1 <= amount) {
			outputString += ` ${str}`;
		} else {
			isIncomplete = true;
		}
	});

	if (isIncomplete) {
		outputString += '...';
	}

	return outputString;
};

export const getEventStatusText = (status) => {
	switch (status) {
		case 0:
			return 'Inactive';
		case 1:
			return 'Publish';
		case 2:
			return 'Reject';
		case 3:
			return 'Archived';
		case 4:
		default:
			return 'Draft';
	}
};

export const eventSegmentEditLink = (item, eventDetails = null) => {
	if (item.module_key && item.data) {
		const mainId = item.data.new_module_id || item.data._id;
		if (mainId) {
			if (item.module_key === 'breakout_session') {
				return `/breakout/${mainId}`;
			} else if (item.module_key === 'group_networking') {
				return `/group-networking/${mainId}`;
			} else if (item.module_key === 'live_stage') {
				return `/live-stream/${mainId}`;
			} else if (item.module_key === 'live_streaming') {
				return `/live-stream/${mainId}`;
			} else if (item.module_key === 'networking') {
				return `/one-to-one-networking/${mainId}`;
			} else if (item.module_key === 'sponsor_networking') {
				return `/sponsor-networking/${mainId}`;
			}
		}
	}

	return '';
};

export const dateSameDay = (start, end) => {
	if (
		start &&
		end &&
		moment(start).isValid() &&
		moment(end).isValid() &&
		moment(start).isSame(end, 'year') &&
		moment(start).isSame(end, 'month') &&
		moment(start).isSame(end, 'day')
	) {
		return true;
	}
	return false;
};

export const getAllHours = () => {
	let minutes = [];

	for (let i = 0; i < 24; i++) {
		minutes.push(i);
	}

	return minutes;
};

export const allHours = getAllHours();

export const getAllMinutes = () => {
	let minutes = [];

	for (let i = 0; i < 60; i++) {
		minutes.push(i);
	}

	return minutes;
};

export const allMinutes = getAllMinutes();

export const removeDuplicates = (data, key) => {
	if (isValidArray(data) && key) {
		return data.filter((obj, pos, arr) => {
			return arr.map((mapObj) => mapObj[key]).indexOf(obj[key]) === pos;
		});
	}
	return [];
};

export const isValidArray = (data) => {
	return data && Array.isArray(data) && data.length > 0;
};

export const getCurrencySymbol = (currency) => {
	if (['INR', '₹'].includes(currency)) {
		return '₹';
	} else if (['USD', '$'].includes(currency)) {
		return '$';
	} else {
		return currency?.replace(/[\d\., ]/g, '');
	}
};

export const sortSelectOptionWithLabels = (data) => {
	if (data !== undefined && data && data.length > 0) {
		return data.sort((a1, b1) => {
			const a = a1.label.toLowerCase();
			const b = b1.label.toLowerCase();
			return a.localeCompare(b);

			// if (a < b) return -1;
			// if (a > b) return 1;
			// return 0;
		});
	}
	return data;
};

export const generateRandomId = (length = 1) => {
	const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
	return randomLetter + Date.now();
};

export const getSocialLinkKey = (link) => {
	let key = '';

	switch (true) {
		case link.includes('linkedin'):
			key = 'linkedin_id';
			break;
		case link.includes('twitter'):
			key = 'twitter_id';
			break;
		case link.includes('insta'):
			key = 'insta_id';
			break;
		case link.includes('gmail'):
			key = 'gmail_id';
			break;
		case link.includes('facebook'):
			key = 'facebook_id';
			break;
		default:
			break;
	}

	return key;
};

export const getStartDateForEvent = () => {
	const checkDate = moment().set({
		second: 0,
		millisecond: 0,
	});

	const checkMinutes = checkDate.minutes();

	if (checkMinutes > 44) {
		return checkDate.add(1, 'hours').set({
			minute: 0,
		});
	} else if (checkMinutes > 29) {
		return checkDate.set({
			minute: 45,
		});
	} else if (checkMinutes > 14) {
		return checkDate.set({
			minute: 30,
		});
	} else {
		return checkDate.set({
			minute: 15,
		});
	}
};

export const getProviderName = (val) => {
	if (val == 'facebook') {
		return 'Facebook';
	} else if (val == 'google') {
		return 'Google';
	}
};

export const deviceType = () => {
	return 2; //web
};

export const getCountryCodeFromDialCode = (code) => {
	if (!code || code == undefined || code == '') return '';
	const country = code.charAt(0) == '+' ? countryList().find((val) => val.dial_code == code) : countryList().find((val) => val.dial_code == '+' + code);
	return country ? country.code : '';
};

export const getDialCodeFromCountryCode = (countryCode) => {
	console.log(countryCode)
	let code = ""
	if (!countryCode || countryCode == undefined || countryCode == '')
		code = "+1";

	else if (countryCode.charAt(0) == '+') {
		code = countryCode
	}

	else {
		code = countryList().find((val) => val.code == countryCode)?.dial_code;
	}
	// console.log(
	// 	'🚀 TCL file: utils.js TCL line 570 TCL getDialCodeFromCountryCode TCL country',
	// 	country,
	// );
	return code ? code : '+1';
};

export const formatPhoneNumber = (code, phone) => {
	let str = '';
	if (code) str += code + '-';
	if (phone) str += phone;
	return str;
};

export const phoneNumber = (phone) => {
	let str = '';
	if (phone) {
		str += 'tel:';
		str += phone.replace(/-/g, '');
	}
	return str;
};

export const padValue = (str, max, padBy) => {
	str = str.toString();
	return str.length < max ? padValue(padBy + str, max, padBy) : str;
};

export const secondsToTime = (secs) => {
	let hours = Math.floor(secs / (60 * 60));

	let divisor_for_minutes = secs % (60 * 60);
	let minutes = Math.floor(divisor_for_minutes / 60);

	let divisor_for_seconds = divisor_for_minutes % 60;
	let seconds = Math.ceil(divisor_for_seconds);

	let obj = {
		hour: hours,
		min: minutes,
		sec: seconds,
	};
	return obj;
};

export const isObjectIsEmpty = (obj) => {
	return obj && Object.values(obj).length < 1;
};

export const _price = (num) => {
	if (typeof num == 'string') num = Number(num);
	if (!num) num = 0;
	return !isEmpty(num) ? num.toFixed(2) : '';
};

export const staffStatusArr = [
	{
		name: 'Unassigned',
		status: 0,
	},
	{
		name: 'Assigned',
		status: 1,
	},
	{
		name: 'Staff On The Way',
		status: 2,
	},
	{
		name: 'Staff Reached',
		status: 3,
	},
	{
		name: 'Service Started',
		status: 4,
	},
	{
		name: 'Invoice Created',
		status: 5,
	},
	{
		name: 'Completed',
		status: 6,
	},
	{
		name: 'Job Cancelled',
		status: 7,
	},
	{
		name: 'Job Declined',
		status: 8,
	},
];

export const activeJobStatusIconsArr = [
	{
		name: 'Staff On The Way',
		status: 2,
		visited: icn_2,
	},
	{
		name: 'Staff Reached',
		status: 3,
		visited: icn_3,
	},
	{
		name: 'Service Started',
		status: 4,
		visited: icn_4,
	},
	{
		name: 'Invoice Created',
		status: 5,
		visited: icn_5,
	},
	{
		name: 'Completed',
		status: 6,
		visited: icn_6,
	},
];

export const invoiceTimeLineArr = [
	{
		type: 'created_at',
		text: 'Job has been created by {created_by}',
		class: 'created',
	},
	{
		type: 'invoice_generated_at',
		text: 'The invoice has been generated.',
		class: 'invoice',
	},
	{
		type: 'scheduled_at',
		text: 'Job scheduled by {scheduled_by} and assigned to ',
		class: 'assigned',
	},
	{
		type: 'payment_at',
		text: 'The payment has been processed.',
		class: 'payment',
	},
	{
		type: 'receipt_at',
		text: 'The receipt has been generated',
		class: 'receipt',
	},
];

export const displayStaffStatus = (status) => {
	console.log('CK ~ displayStaffStatus ~ status', status);
	if (!status || status == undefined || status == '') return '';
	const staffStatus = staffStatusArr.find((val) => val.status == status);
	return staffStatus ? staffStatus.name : '';
};

let street1 = '';
export const getDetailsFromGoogleAddress = (address, type) => {
	let val = '';

	// console.log(address, type);
	address.forEach((d) => {
		// console.log(d);
		if (type == 'get-country' && d.types.includes('country')) {
			val = d.long_name;
			return false;
		} else if (
			type == 'get-state' &&
			d.types.includes('political') &&
			d.types.includes('administrative_area_level_1')
		) {
			val = d.long_name;
			return false;
		} else if (type == 'get-city' && d.types.includes('locality')) {
			val = d.long_name;
			return false;
		} else if (
			type == 'get-street1' &&
			(d.types.includes('street_number') ||
				d.types.includes('route') ||
				d.types.includes('sublocality') ||
				d.types.includes('sublocality_level_1') ||
				d.types.includes('"administrative_area_level_2"'))
		) {
			street1 = d.types.includes('street_number')
				? d.long_name
				: d.types.includes('route')
					? d.long_name
					: d.types.includes('sublocality')
						? d.long_name
						: d.types.includes('sublocality_level_1')
							? d.long_name
							: d.types.includes('administrative_area_level_2')
								? d.long_name
								: '';
			val = val + street1 + ', ';
			return false;
		} else if (
			type == 'get-street2' &&
			d.types.includes('route') &&
			d.types.includes('sublocality_level_1')
		) {
			val = d.long_name;
			return false;
		} else if (type == 'get-post-code' && d.types.includes('postal_code')) {
			val = d.long_name;
			return false;
		}
	});

	return val;
};

export const getByKeyFromObject = (obj, key, _default = '') => {
	return obj && obj[key] ? obj[key] : _default;
};

export const getInitials = (string) => {
	if (string) {
		let names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}
};

export const dayFilter = [
	{ value: 'day', name: '1D' },
	{ value: 'week', name: '1W' },
	{ value: 'month', name: '1M' },
	{ value: 'year', name: '1Y' },
	{ value: 'all', name: 'All' },
];

export const getJobStatusText = (status) => {
	switch (status) {
		case 0:
			// return 'Unassigned';
			return 'Job Scheduled';
		case 1:
			// return 'Assigned';
			return 'On the way';
		case 2:
			// return 'On the Way';
			return 'Staff Reached';
		case 3:
			// return 'Staff reached';
			return 'Job Started';
		case 4:
			// return 'Service Started';
			return 'Job Finished';
		case 5:
			// return 'Invoice Generated';
			return 'Generate Invoice';
		case 6:
			// return 'Completed';
			return 'Invoice Paid';
		case 7:
			return 'Job Cancelled';
		case 8:
			return 'Job Declined';
		case 9:
			return 'UnPaid';
		case 10:
			return 'Paid';
		case 11:
			return 'Active Jobs';
		case 12:
			return 'All Jobs';
		case 13:
		default:
			return '';
	}
};

export const getParentJobStatusText = (status, jobVisit) => {
	switch (status) {
		case 0:
			return 'Unassigned';
		// return 'Job Scheduled';
		case 1:
			return 'Assigned';
		// return 'On the way';
		case 2:
			return 'Staff On The Way';
		// return 'Staff Reached';
		case 3:
			return 'Staff Reached';
		// return 'Job Started';
		case 4:
			return `${jobVisit ? jobVisit : 'Job'} Started`;
		case 5:
			// return 'Invoice Generated';
			return 'Invoice Generated';
		case 6:
			// return 'Job Finished';
			return `${jobVisit ? jobVisit : 'Job'} Completed`;
		case 7:
			return `${jobVisit ? jobVisit : 'Job'} Cancelled`;
		case 8:
			return `${jobVisit ? jobVisit : 'Job'} Declined`;
		case 11:
			return `Unscheduled ${jobVisit ? jobVisit : 'Job'}`;

		case 13:
		default:
			return '';
	}
};

export const getJobStatusTextForList = (status, jobVisit) => {
	switch (status) {
		case 0:
			return 'Unassigned';
		// return 'Job Scheduled';
		case 1:
			return 'Assigned';
		// return 'On the way';
		case 2:
			return 'Staff On The Way';
		// return 'Staff Reached';
		case 3:
			return 'Reached';
		// return 'Job Started';
		case 4:
			return `${jobVisit} Started`;
		case 5:
			// return 'Invoice Generated';
			return 'Generate Invoice';
		case 6:
			// return 'Job Finished';
			return `${jobVisit} Completed`;
		case 7:
			return `${jobVisit} Cancelled`;
		case 8:
			return `${jobVisit} Declined`;

		case 11:
			return `Unscheduled ${jobVisit}`;

		case 99:
			return `Payment Processed`;

		case 100:
			return `Receipt Generated`;

		case 13:
		default:
			return '';
	}
};

export const getJobStatus = [
	{
		id: 1,
		text: '', //status active, pending completed
		color: lightBlue,
	},
	{
		id: 2,
		text: '', //status active, pending completed
		color: grey,
	},
	{
		id: 3,
		text: '', //status active, pending completed
		color: green,
	},
];

export const schedulerJobStatus = [
	{
		value: 12,
		label: 'All Visits',
		color: lightBlue,
	},
	{
		value: 11,
		label: 'Active',
		color: lightBlue,
	},
	{
		value: '1',
		label: 'Pending',
		color: grey,
	},
	{
		value: '6,7',
		label: 'Completed',
		color: green,
	},
];

export const Priority = [
	{
		value: 0,
		label: 'All Priorities',
	},
	{
		value: 1,
		label: 'Low',
	},
	{
		value: 2,
		label: 'Medium',
	},
	{
		value: 3,
		label: 'High',
	},
];

export const getSchedulerJobStatus = (status) => {
	switch (status) {
		case 11:
			return 1;
		case 0:
			return 2;
		case 6:
			return 3;
		default:
			return 0;
	}
};

export const discountTypeArr = [
	{ value: 0, text: 'percentage' },
	{ value: 1, text: 'amount' },
];

export const getDiscountTypeVal = (text) => {
	const data = discountTypeArr.find((d) => text == d.text);
	if (data) return data.value;
	return '';
};

export const getDiscountTypeText = (val) => {
	const data = discountTypeArr.find((d) => val == d.value);
	return data?.text || '';
};

export const discountOnArr = [
	{ value: 0, text: 'gross' },
	{ value: 1, text: 'net' },
];

export const getDiscountOnVal = (text) => {
	const data = discountOnArr.find((d) => text == d.text);
	if (data) return data.value;
	return '';
};

export const getDiscountOnText = (val) => {
	const data = discountOnArr.find((d) => val == d.value);
	return data?.text || '';
};

export const asterisk = <span className="text-danger">*</span>;

export const checkHasNextElement = (arr, index) => {
	return isValidArray(arr) && arr.length > index;
};

export const commonLanguageArr = [
	{ code: 'en', name: 'English (U.K.)' },
	{ code: 'cs', name: 'Chinese Simplified' },
	{ code: 'fr', name: 'French' },
	{ code: 'es', name: 'Spanish' },
];

export const commonDateFormateArr = [
	{ code: 'MM/dd/yyyy', format: 'MM/D/yyyy' },
	{ code: 'dd-MM-yyyy', format: 'D-MM-yyyy' },
	{ code: 'MM-dd-yyyy', format: 'MM-D-yyyy' },
	{ code: 'MMM dd,yyyy', format: 'MMM D,yyyy' },
	{ code: 'dd/MM/yyyy', format: 'D/MM/yyyy' },
];

export const commonTimeFormateArr = [
	{ lbl: 'Use 12-hour (Am/Pm) clock', val: 0 },
	{ lbl: 'User 24-hour clock', val: 1 },
];

export const GOOGLE_CLIENT_ID =
	'578454538842-8mlcrpg31b69lfjcdvddc6nee4ihaha4.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '1264717650550840';
export const GOOGLE_MAP_KEY = 'AIzaSyCLhZBERT2eXD9miE-uHjNZ_H6W1QWFoY4';

export const paymentMethods = [
	{
		label: 'Cash',
		name: 'cash',
	},
	{
		label: 'Check',
		name: 'check',
	},
	{
		label: 'Paypal',
		name: 'paypal',
	},
	{
		label: 'Stripe',
		name: 'stripe',
	},
];

export const getQueryParam = (props, param) => {
	// console.log(window.location);

	const search_str =
		props.location !== undefined
			? props.location.search
			: window.location.search;
	const query = new URLSearchParams(search_str);
	return query.get(param);
};

export const isValidObject = (data) => {
	return typeof data === 'object' && data
		? Object.keys(data).length > 0
		: false;
};

export const getCustomerBillingAddress = (customer) => {
	return isValidArray(customer?.properties)
		? customer.properties.find((d) => !!d?.is_billing_address)
		: {};
};

export const showCustomerBillingAddress = (customer) => {
	const address = getCustomerBillingAddress(customer);
	if (isValidObject(address)) {
		return {
			line1: [address?.suite_no, address?.street1].filter((d) => d).join(', '),
			line2: [address?.street2].filter((d) => d).join(', '),
			line3: [address?.city, address?.province, address?.postal_code]
				.filter((d) => d)
				.join(', '),
		};
	}
	return null;
};

export const getDiscountAmount = (
	discountOn,
	discountType,
	discountVal,
	totalPrice,
	tax = 0,
) => {
	let discountAmount = 0;

	if (discountType === 'percentage') {
		if (discountOn === 'gross') {
			discountAmount = (totalPrice * discountVal) / 100;
		} else if (discountOn === 'net') {
			const totalTaxAndPrice = parseFloat(totalPrice) + parseFloat(tax);
			discountAmount = (totalTaxAndPrice * discountVal) / 100;
		}
	} else {
		discountAmount = discountVal;
	}

	return _price(discountAmount);
};

export const getJobStatusColors = (status) => {
	let bgColor = '';
	let textColor = '#000';
	switch (true) {
		case status >= 2 && status <= 5:
			bgColor = '#4A6DFF';
			textColor = '#fff';
			break;
		case status === 6:
			bgColor = '#6ae2b1';
			break;
		case status === 7:
			bgColor = '#ED6060';
			break;
		case status === 8:
			bgColor = '#FFFDF5<';
			break;
		default:
			bgColor = '#C0C1C9';
			break;
	}
	return { bgColor, textColor };
};

export const decryptLoginData = () => {
	const value = CryptoJS.AES.decrypt(
		localStorage.getItem('fieldCampLogin'),
		'fieldCampLogin',
	);
	return JSON.parse(value.toString(CryptoJS.enc.Utf8));
};

export const customFilter = createFilter({
	ignoreCase: true,
	ignoreAccents: true,
	stringify: (object) => object.value,
	trim: true,
	matchFrom: 'any',
});

export const customStylesSelect = {
	control: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '30px',
	}),

	container: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '30px',
		padding: '0 !important',
		'font-size': '14px',
	}),

	singleValue: (provided, state) => ({
		...provided,
		'padding-bottom': '2px',
	}),

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected && '#0054d9',
		'&:hover': {
			backgroundColor: 'rgba(212, 230, 255, 0.8)',
			cursor: 'pointer',
			color: state.isSelected && '#000',
		},
	}),

	indicatorSeparator: (provided, state) => ({
		...provided,
		'margin-top': '4px',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		'margin-top': '-4px',
	}),
};

export const customStylesSelect2 = {
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected && '#0054d9',
		'&:hover': {
			backgroundColor: 'rgba(212, 230, 255, 0.8)',
			cursor: 'pointer',
			color: state.isSelected && '#000',
		},
	}),
};

export const customStylesSelect3 = {
	control: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '33px',
	}),

	container: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '30px',
		padding: '0 !important',
		'font-size': '14px',
	}),

	singleValue: (provided, state) => ({
		...provided,
		'padding-bottom': '2px',
	}),

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected && '#0054d9',
		'&:hover': {
			backgroundColor: 'rgba(212, 230, 255, 0.8)',
			cursor: 'pointer',
			color: state.isSelected && '#000',
		},
	}),

	indicatorSeparator: (provided, state) => ({
		...provided,
		'margin-top': '4px',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		'margin-top': '-4px',
	}),
};

export const customStylesSelect4 = {
	control: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '35px',
	}),

	container: (provided, state) => ({
		...provided,
		'min-height': 'auto !important',
		height: '30px',
		padding: '0 !important',
		'font-size': '14px',
	}),

	singleValue: (provided, state) => ({
		...provided,
		'padding-bottom': '2px',
	}),

	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected && '#0054d9',
		'&:hover': {
			backgroundColor: 'rgba(212, 230, 255, 0.8)',
			cursor: 'pointer',
			color: state.isSelected && '#000',
		},
	}),

	indicatorSeparator: (provided, state) => ({
		...provided,
		'margin-top': '4px',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		'margin-top': '-4px',
	}),
};

export const DecreptionEvents = () => {
	const value = CryptoJS.AES.decrypt(
		sessionStorage.getItem('events'),
		'fieldCampLogin',
	);
	return JSON.parse(value.toString(CryptoJS.enc.Utf8));
};
export const setEventsData = (events) => {
	sessionStorage.setItem(
		'events',
		CryptoJS.AES.encrypt(JSON.stringify(events), 'fieldCampLogin'),
	);
};

export const DecreptionEventStart = () => {
	const value = CryptoJS.AES.decrypt(
		sessionStorage.getItem('event'),
		'fieldCampLogin',
	);
	return JSON.parse(value.toString(CryptoJS.enc.Utf8));
};
export const setEventsDataStart = (event) => {
	sessionStorage.setItem(
		'event',
		CryptoJS.AES.encrypt(JSON.stringify(event), 'fieldCampLogin'),
	);
};

export const replaceVariable = [
	{
		code: '{{COMPANY_NAME}}',
		name: localStorage.getItem('organization_name') || 'Organization name',
	},
	{
		code: '{{COMPANY_EMAIL}}',
		name: localStorage.getItem('authEmail'),
	},
	{
		code: '{{CLIENT_FIRST_NAME}}',
		name: 'John',
	},
	{
		code: '{{CLIENT_NAME}}',
		name: localStorage.getItem('authName'),
	},
	{
		code: '{{DEFAULT_EMAIL}}',
		name: localStorage.getItem('authEmail'),
	},
	{
		code: '{{CLIENT_PHONE}}',
		name: '9999999999',
	},
	{
		code: '{{CLIENT_EMAIL}}',
		name: localStorage.getItem('authEmail'),
	},
	{
		code: '{{CLIENT_LAST_NAME}}',
		name: 'Doe',
	},
	{
		code: '{{VISIT_ADDRESS}}',
		name: '2 County Court Blvd., Suite 400, Brampton, Ontario L6W 3W8, Canada',
	},
	{
		code: '{{VISIT_TIME}}',
		name: moment(new Date()).format('hh:mm a'),
	},
	{
		code: '{{VISIT_DATE}}',
		name: moment(new Date()).format('DD/MM/YYYY'),
	},
	{
		code: '{{VISIT_CONFIRMATION_LINK}}',
		name: '[View Appointment]',
	},
	{
		code: '{{VISIT_DETAILS}}',
		name: `Date: ${moment(new Date()).format(
			'DD/MM/YYYY',
		)}\nWhere: 2 County Court Blvd., Suite 400, Brampton, Canada\nTime: ${moment(
			new Date(),
		).format('hh:mm a')}`,
	},
];

export const timeZones = [
	{ label: 'International Date Line West (Etc/GMT-12)', value: '-12:00' },
	{ label: 'Midway Island, Samoa (Pacific/Midway)', value: '-11:00' },
	{ label: 'Hawaii (Pacific/Honolulu)', value: '-10:00' },
	{ label: 'Alaska (US/Alaska)', value: '-09:00' },
	{
		label: 'Pacific Time (US & Canada) (America/Los_Angeles)',
		value: '-08:00',
	},
	{ label: 'Tijuana, Baja California (America/Tijuana)', value: '-08:00' },
	{ label: 'Arizona (US/Arizona)', value: '-07:00' },
	{ label: 'Chihuahua, La Paz, Mazatlan (America/Chihuahua)', value: '-07:00' },
	{ label: 'Mountain Time (US & Canada) (US/Mountain)', value: '-07:00' },
	{ label: 'Central America (America/Managua)', value: '-06:00' },
	{ label: 'Central Time (US & Canada) (US/Central)', value: '-06:00' },
	{
		label: 'Guadalajara, Mexico City, Monterrey (America/Mexico_City)',
		value: '-06:00',
	},
	{ label: 'Saskatchewan (Canada/Saskatchewan)', value: '-06:00' },
	{
		label: 'Bogota, Lima, Quito, Rio Branco (America/Bogota)',
		value: '-05:00',
	},
	{ label: 'Eastern Time (US & Canada) (US/Eastern)', value: '-05:00' },
	{ label: 'Indiana (East) (US/East-Indiana)', value: '-05:00' },
	{ label: 'Atlantic Time (Canada) (Canada/Atlantic)', value: '-04:00' },
	{ label: 'Caracas, La Paz (America/Caracas)', value: '-04:00' },
	{ label: 'Manaus (America/Manaus)', value: '-04:00' },
	{ label: 'Santiago (America/Santiago)', value: '-04:00' },
	{ label: 'Newfoundland (Canada/Newfoundland)', value: '-03:30' },
	{ label: 'Brasilia (America/Sao_Paulo)', value: '-03:00' },
	{
		label: 'Buenos Aires, Georgetown (America/Argentina/Buenos_Aires)',
		value: '-03:00',
	},
	{ label: 'Greenland (America/Godthab)', value: '-03:00' },
	{ label: 'Montevideo (America/Montevideo)', value: '-03:00' },
	{ label: 'Mid-Atlantic (America/Noronha)', value: '-02:00' },
	{ label: 'Cape Verde Is. (Atlantic/Cape_Verde)', value: '-01:00' },
	{ label: 'Azores (Atlantic/Azores)', value: '-01:00' },
	{
		label: 'Casablanca, Monrovia, Reykjavik (Africa/Casablanca)',
		value: '+00:00',
	},
	{
		label:
			'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (Etc/Greenwich)',
		value: '+00:00',
	},
	{
		label:
			'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Amsterdam)',
		value: '+01:00',
	},
	{
		label:
			'Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Belgrade)',
		value: '+01:00',
	},
	{
		label: 'Brussels, Copenhagen, Madrid, Paris (Europe/Brussels)',
		value: '+01:00',
	},
	{
		label: 'Sarajevo, Skopje, Warsaw, Zagreb (Europe/Sarajevo)',
		value: '+01:00',
	},
	{ label: 'West Central Africa (Africa/Lagos)', value: '+01:00' },
	{ label: 'Amman (Asia/Amman)', value: '+02:00' },
	{ label: 'Athens, Bucharest, Istanbul (Europe/Athens)', value: '+02:00' },
	{ label: 'Beirut (Asia/Beirut)', value: '+02:00' },
	{ label: 'Cairo (Africa/Cairo)', value: '+02:00' },
	{ label: 'Harare, Pretoria (Africa/Harare)', value: '+02:00' },
	{
		label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Helsinki)',
		value: '+02:00',
	},
	{ label: 'Jerusalem (Asia/Jerusalem)', value: '+02:00' },
	{ label: 'Minsk (Europe/Minsk)', value: '+02:00' },
	{ label: 'Windhoek (Africa/Windhoek)', value: '+02:00' },
	{ label: 'Kuwait, Riyadh, Baghdad (Asia/Kuwait)', value: '+03:00' },
	{
		label: 'Moscow, St. Petersburg, Volgograd (Europe/Moscow)',
		value: '+03:00',
	},
	{ label: 'Nairobi (Africa/Nairobi)', value: '+03:00' },
	{ label: 'Tbilisi (Asia/Tbilisi)', value: '+03:00' },
	{ label: 'Tehran (Asia/Tehran)', value: '+03:30' },
	{ label: 'Abu Dhabi, Muscat (Asia/Muscat)', value: '+04:00' },
	{ label: 'Baku (Asia/Baku)', value: '+04:00' },
	{ label: 'Yerevan (Asia/Yerevan)', value: '+04:00' },
	{ label: 'Kabul (Asia/Kabul)', value: '+04:30' },
	{ label: 'Yekaterinburg (Asia/Yekaterinburg)', value: '+05:00' },
	{ label: 'Islamabad, Karachi, Tashkent (Asia/Karachi)', value: '+05:00' },
	{
		label: 'Chennai, Kolkata, Mumbai, New Delhi (Asia/Calcutta)',
		// value: '+05:30',
		value: 'Asia/Calcutta',
	},
	{ label: 'Sri Jayawardenapura (Asia/Calcutta)', value: '+05:30' },
	{ label: 'Kathmandu (Asia/Katmandu)', value: '+05:45' },
	{ label: 'Almaty, Novosibirsk (Asia/Almaty)', value: '+06:00' },
	{ label: 'Astana, Dhaka (Asia/Dhaka)', value: '+06:00' },
	{ label: 'Yangon (Rangoon) (Asia/Rangoon)', value: '+06:30' },
	{ label: 'Bangkok, Hanoi, Jakarta (Asia/Bangkok)', value: '+07:00' },
	{ label: 'Krasnoyarsk (Asia/Krasnoyarsk)', value: '+07:00' },
	{
		label: 'Beijing, Chongqing, Hong Kong, Urumqi (Asia/Hong_Kong)',
		value: '+08:00',
	},
	{ label: 'Kuala Lumpur, Singapore (Asia/Kuala_Lumpur)', value: '+08:00' },
	{ label: 'Irkutsk, Ulaan Bataar (Asia/Irkutsk)', value: '+08:00' },
	{ label: 'Perth (Australia/Perth)', value: '+08:00' },
	{ label: 'Taipei (Asia/Taipei)', value: '+08:00' },
	{ label: 'Osaka, Sapporo, Tokyo (Asia/Tokyo)', value: '+09:00' },
	{ label: 'Seoul (Asia/Seoul)', value: '+09:00' },
	{ label: 'Yakutsk (Asia/Yakutsk)', value: '+09:00' },
	{ label: 'Adelaide (Australia/Adelaide)', value: '+09:30' },
	{ label: 'Darwin (Australia/Darwin)', value: '+09:30' },
	{ label: 'Brisbane (Australia/Brisbane)', value: '+10:00' },
	{
		label: 'Canberra, Melbourne, Sydney (Australia/Canberra)',
		value: '+10:00',
	},
	{ label: 'Hobart (Australia/Hobart)', value: '+10:00' },
	{ label: 'Guam, Port Moresby (Pacific/Guam)', value: '+10:00' },
	{ label: 'Vladivostok (Asia/Vladivostok)', value: '+10:00' },
	{
		label: 'Magadan, Solomon Is., New Caledonia (Asia/Magadan)',
		value: '+11:00',
	},
	{ label: 'Auckland, Wellington (Pacific/Auckland)', value: '+12:00' },
	{ label: 'Fiji, Kamchatka, Marshall Is. (Pacific/Fiji)', value: '+12:00' },
	{ label: "Nuku'alofa (Pacific/Tongatapu)", value: '+13:00' },
];
export const notification = {
	notification_medium: 'Email',
	client_name: 'Gaurang Bhatt',
	sent_date: 1640009047000,
	to: 'gaurang@example.com',
	subject:
		'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	body: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
	status: 1,
	status_desc: 'Sent',
	notification_type: 'Visit Reminder',
	sent_time: 1640012760000,
};

export const sampleAuthoritySchema = {
	form_name: 'Sample Authority to Proceed',
	attach_to: [
		{
			module: 'jobs',
			actions: ['create'],
			is_active: 0,
		},
	],
	status: 11,
	fields_schema: [
		{
			section: {
				name: 'Sample Form',
				sort_order: 1,
			},
			fields: [
				{
					name: 'Site Contact',
					data_type: 'textbox',
				},
				{
					name: 'Site Contact Mobile',
					data_type: 'textbox',
				},
				{
					name: 'I give you authority to proceed with the work described',
					data_type: 'signature',
				},
			],
		},
	],
};

export const sampleContractSchema = {
	form_name: 'Sample Contract Variation',
	attach_to: [
		{
			module: 'jobs',
			actions: ['create'],
			is_active: 0,
		},
	],
	status: 11,
	fields_schema: [
		{
			section: {
				name: 'Sample Form',
				sort_order: 1,
			},
			fields: [
				{
					name: 'Site Contact Mobile',
					data_type: 'textbox',
				},
				{
					name: 'What price is estimated for the additional work',
					data_type: 'textbox',
				},
				{
					name: 'Select if tax is included or not',
					data_type: 'dropdown_single_choice',
					choices: [
						{
							name: 'Includes tax',
						},
						{
							name: 'Excludes tax',
						},
					],
				},
				{
					name: 'Please sign accepting the variation',
					data_type: 'signature',
				},
			],
		},
	],
};

export const timeDifference = (current, previous) => {

	var msPerMinute = 60 * 1000;
	var msPerHour = msPerMinute * 60;
	var msPerDay = msPerHour * 24;
	var msPerMonth = msPerDay * 30;
	var msPerYear = msPerDay * 365;

	var elapsed = current - previous;

	if (elapsed < msPerMinute) {
		return Math.round(elapsed / 1000) + ' seconds ago';
	}

	else if (elapsed < msPerHour) {
		return Math.round(elapsed / msPerMinute) + (Math.round(elapsed / msPerMinute) == 1 ? ' minute ago' : ' minutes ago');
	}

	else if (elapsed < msPerDay) {
		return Math.round(elapsed / msPerHour) + (Math.round(elapsed / msPerHour) == 1 ? ' hour ago' : ' hours ago');
	}

	else if (elapsed < msPerMonth) {
		return 'approximately ' + Math.round(elapsed / msPerDay) + (Math.round(elapsed / msPerDay) == 1 ? ' day ago' : ' days ago');
	}

	else if (elapsed < msPerYear) {
		return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
	}

	else {
		return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
	}
}