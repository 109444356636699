import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SiteHeader from '../common/site/Header';
import SiteWelcome from '../common/site/Welcome';
import SiteSupportMail from '../common/site/SupportMail';
import SiteFooter from '../common/site/Footer';

import arrow from '../../assets/images/arrow.png';
import notification from '../../assets/images/notification.png';
import payment from '../../assets/images/payment.png';
import task from '../../assets/images/task.png';
import tracking from '../../assets/images/Tracking.png';
import information from '../../assets/images/information.png';
import arrowBlue from '../../assets/images/arrow_blue.png';
import headphones from '../../assets/images/headphones.png';
import img1 from '../../assets/images/img1.png';
import img2 from '../../assets/images/img2.png';
import img3 from '../../assets/images/img3.png';
import ss1 from '../../assets/images/screenshot1.png';
import ss2 from '../../assets/images/screenshot2.png';
import ss3 from '../../assets/images/screenshot3.png';

import '../../assets/css/bootstrap.min.css';
// import '../../assets/fonts/maisonNeue/font.css';
import '../../assets/css/style.css';

export default class Home extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="wrapper">
					<SiteHeader />

					<div className="page_content">
						<SiteWelcome />

						<section className="feature_sec pb-80 pt-80">
							<div className="custom-container">
								<div className="row">
									<div className="col-lg-8 col-12">
										<div className="feature_listing">
											<div className="row">
												<div className="col-md-6 col-12">
													<div className="feature_box">
														<div className="content">
															<img
																src={task}
																alt=""
																className="img-fluid feature_icon"
																width="50px"
															/>
															<h3 className="bold">Schedule Job</h3>
															<p>
																Lorem ipsum dolor sit amet, vel in sint
																delectus, eu mei stet cotidieque
															</p>
															<Link className="link" to={`/home`}>
																Read More{' '}
																<img src={arrow} alt="" className="img-fluid" />
															</Link>
														</div>
													</div>
													<div className="feature_box">
														<div className="content">
															<img
																src={payment}
																alt=""
																className="img-fluid feature_icon"
																width="50px"
															/>
															<h3 className="bold">Payments</h3>
															<p>
																Lorem ipsum dolor sit amet, vel in sint
																delectus, eu mei stet cotidieque
															</p>
															<Link className="link" to={`/home`}>
																Read More{' '}
																<img src={arrow} alt="" className="img-fluid" />
															</Link>
														</div>
													</div>
												</div>
												<div className="col-md-6 col-12 sec_margin">
													<div className="feature_box">
														<div className="content">
															<img
																src={tracking}
																alt=""
																className="img-fluid feature_icon"
																width="59px"
															/>
															<h3 className="bold">Live Tracking</h3>
															<p>
																Lorem ipsum dolor sit amet, vel in sint
																delectus, eu mei stet cotidieque
															</p>
															<Link className="link" to={`/home`}>
																Read More{' '}
																<img src={arrow} alt="" className="img-fluid" />
															</Link>
														</div>
													</div>
													<div className="feature_box">
														<div className="content">
															<img
																src={notification}
																alt=""
																className="img-fluid feature_icon"
																width="39px"
															/>
															<h3 className="bold">Notifications</h3>
															<p>
																Lorem ipsum dolor sit amet, vel in sint
																delectus, eu mei stet cotidieque
															</p>
															<Link className="link" to={`/home`}>
																Read More{' '}
																<img src={arrow} alt="" className="img-fluid" />
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-12 align-self-center">
										<div className="content">
											<h3 className="bold">
												Main Features Of <br />
												Service Call
											</h3>
											<p className="mb-4">
												Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
												stet cotidieque referrentur. Cu mea mucius docendi,
												veniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quoveniam periculis eu cum, quo stet cotidieque
												referrentur. Cu mea mucius docendi, veniam periculis eu
												cum, quo
											</p>
											<Link className="btn btn-blue-stroke" to={`/features`}>
												All Features
											</Link>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="about_sec pb-80">
							<div className="custom-container">
								<h2 className="bold">Why choose us ?</h2>
								<div className="about_detail pt-5">
									<div className="row margin_bottom flex-mobile">
										<div className="col-md-6 col-12 align-self-center">
											<div className="content">
												<h3 className="bold">
													Individual Service <br />
													Provider
												</h3>
												<p className="mb-4">
													Lorem ipsum dolor sit amet, vel in sint delectus, eu
													mei stet cotidieque referrentur. Cu mea mucius
													docendi, veniam periculis eu cum, quo stet cotidieque
													referrentur. Cu mea mucius docendi, veniam periculis
													eu cum, quoveniam periculis eu cum, quo stet
													cotidieque referrentur. Cu mea mucius docendi, veniam
													periculis eu cum, quo
												</p>
												<button type="button" className="btn btn-primary">
													Join Now
												</button>
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div
												className="bg_img right_img"
												style={{ backgroundImage: `url(${img1})` }}
											></div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div
												className="bg_img left_img"
												style={{ backgroundImage: `url(${img2})` }}
											></div>
										</div>
										<div className="col-md-6 col-12 align-self-center">
											<div className="content service_detail">
												<h3 className="bold">
													Service Providing <br />
													Company
												</h3>
												<p className="mb-4">
													Lorem ipsum dolor sit amet, vel in sint delectus, eu
													mei stet cotidieque referrentur. Cu mea mucius
													docendi, veniam periculis eu cum, quo stet cotidieque
													referrentur. Cu mea mucius docendi, veniam periculis
													eu cum, quoveniam periculis eu cum, quo stet
													cotidieque referrentur. Cu mea mucius docendi, veniam
													periculis eu cum, quo
												</p>
												<button type="button" className="btn btn-primary">
													Join Now
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="join_sec bg_cyan pt-5 pb-5">
							<div className="custom-container">
								<div className="row">
									<div className="col-md-9 col-12">
										<h3 className="text-white m-0">
											<span className="bold pb-2 d-block">
												Build Your successfull business With Us.
											</span>
											<span className="medium">Want to know How ?</span>
										</h3>
									</div>
									<div className="col-md-3 col-12 align-self-center">
										<button type="button" className="btn btn-white-stroke">
											Join Now
										</button>
									</div>
								</div>
							</div>
						</section>
						<section className="screenshot_sec pt-80 pb-80">
							<div className="custom-container">
								<div className="row">
									<div className="col-md-6 col-12">
										<div
											className="bg_img mb-3"
											style={{ backgroundImage: `url(${ss3})` }}
										>
											<div className="overlay"></div>
											<Link className="btn btn-primary" to={`/home`}>
												Learn More
											</Link>
										</div>
										<div
											className="bg_img"
											style={{ backgroundImage: `url(${ss1})` }}
										>
											<div className="overlay"></div>
											<Link className="btn btn-primary" to={`/home`}>
												Learn More
											</Link>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div
											className="bg_img full_img"
											style={{ backgroundImage: `url(${ss2})` }}
										>
											<div className="overlay"></div>
											<Link className="btn btn-primary" to={`/home`}>
												Learn More
											</Link>
										</div>
									</div>
								</div>
								<div className="row pt-80">
									<div className="col-md-6 col-12">
										<div className="info_box">
											<div className="icon">
												<img
													src={information}
													alt=""
													className="img-fluid"
													width="50px"
												/>
											</div>
											<div className="desc">
												<h5 className="bold">View common questions</h5>
												<p className="mb-3">
													Lorem ipsum dolor sit amet, vel in sint delectus, eu
													mei stet cotidieque referrentur. Cu mea mucius
													docendi, veniam periculis eu cum, quo Cu mea mucius
													docendi, veniam periculis eu cum, quo
												</p>
												<Link
													className="hover_link bold blue_text"
													to={`/home`}
												>
													Read More{' '}
													<img src={arrowBlue} alt="" className="img-fluid" />
												</Link>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="info_box">
											<div className="icon">
												<img
													src={headphones}
													alt=""
													className="img-fluid"
													width="49px"
												/>
											</div>
											<div className="desc">
												<h5 className="bold">View common questions</h5>
												<p className="mb-3">
													Lorem ipsum dolor sit amet, vel in sint delectus, eu
													mei stet cotidieque referrentur. Cu mea mucius
													docendi, veniam periculis eu cum, quo Cu mea mucius
													docendi, veniam periculis eu cum, quo
												</p>
												<Link
													className="hover_link bold blue_text"
													to={`/home`}
												>
													Read More{' '}
													<img src={arrowBlue} alt="" className="img-fluid" />
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="part_of_service pb-80">
							<div className="row">
								<div className="col-md-6 col-lg-7 col-12 align-self-center">
									<img src={img3} alt="" className="img-fluid sec_img" />
								</div>
								<div className="col-md-6 col-lg-5 col-12 align-self-center">
									<div className="content">
										<h3 className="bold">
											Be a part of Service <br />
											Call today
										</h3>
										<p className="mb-4">
											Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
											stet cotidieque referrentur. Cu mea mucius docendi, veniam
											periculis eu cum, quo stet cotidieque referrentur. Cu mea
											mucius docendi, veniam periculis eu cum, quoveniam
											periculis eu cum, quo stet cotidieque referrentur. Cu mea
											mucius docendi, veniam periculis eu cum, quo
										</p>
										<button type="button" className="btn btn-blue-stroke">
											Learn More
										</button>
									</div>
								</div>
							</div>
						</section>

						<SiteSupportMail />
					</div>
				</div>
				<SiteFooter />
			</React.Fragment>
		);
	}
}
