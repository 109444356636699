export const FETCH_CUSTOMER_SETTING_REQUEST = 'FETCH_CUSTOMER_SETTING_REQUEST';
export const FETCH_CUSTOMER_SETTING_SUCCESS = 'FETCH_CUSTOMER_SETTING_SUCCESS';
export const FETCH_CUSTOMER_SETTING_FAILURE = 'FETCH_CUSTOMER_SETTING_FAILURE';

export const FETCH_UPDATE_CUSTOMER_SETTING_REQUEST =
	'FETCH_UPDATE_CUSTOMER_SETTING_REQUEST';
export const FETCH_UPDATE_CUSTOMER_SETTING_SUCCESS =
	'FETCH_UPDATE_CUSTOMER_SETTING_SUCCESS';
export const FETCH_UPDATE_CUSTOMER_SETTING_FAILURE =
	'FETCH_UPDATE_CUSTOMER_SETTING_FAILURE';

export const FETCH_UPDATE_JOB_SETTING_REQUEST =
	'FETCH_UPDATE_JOB_SETTING_REQUEST';
export const FETCH_UPDATE_JOB_SETTING_SUCCESS =
	'FETCH_UPDATE_JOB_SETTING_SUCCESS';
export const FETCH_UPDATE_JOB_SETTING_FAILURE =
	'FETCH_UPDATE_JOB_SETTING_FAILURE';

export const FETCH_VERIFICATION_SETTING_REQUEST =
	'FETCH_VERIFICATION_SETTING_REQUEST';
export const FETCH_VERIFICATION_SETTING_SUCCESS =
	'FETCH_VERIFICATION_SETTING_SUCCESS';
export const FETCH_VERIFICATION_SETTING_FAILURE =
	'FETCH_VERIFICATION_SETTING_FAILURE';

export const FETCH_STRIPE_CODE_REQUEST = 'FETCH_STRIPE_CODE_REQUEST';
export const FETCH_STRIPE_CODE_SUCCESS = 'FETCH_STRIPE_CODE_SUCCESS';
export const FETCH_STRIPE_CODE_FAILURE = 'FETCH_STRIPE_CODE_FAILURE';

export const FETCH_GET_LOAD_DATA_REQUEST = 'FETCH_GET_LOAD_DATA_REQUEST';
export const FETCH_GET_LOAD_DATA_SUCCESS = 'FETCH_GET_LOAD_DATA_SUCCESS';
export const FETCH_GET_LOAD_DATA_FAILURE = 'FETCH_GET_LOAD_DATA_FAILURE';

export const FETCH_UPDATE_TEAM_SETTING_REQUEST = 'FETCH_UPDATE_TEAM_SETTING_REQUEST';
export const FETCH_UPDATE_TEAM_SETTING_SUCCESS = 'FETCH_UPDATE_TEAM_SETTING_SUCCESS';
export const FETCH_UPDATE_TEAM_SETTING_FAILURE = 'FETCH_UPDATE_TEAM_SETTING_FAILURE';
