import { CFormGroup, CInput, CLabel } from '@coreui/react';
import { connect } from 'react-redux';
import LaddaButton, { SLIDE_UP } from '@zumper/react-ladda';
import { Formik } from 'formik';
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import {
	displayFormErrors,
	registerValidations,
} from '../../modules/validations';
import CommonSocialLogin from '../common/site/Login/CommonSocialLogin';
import "./style.css"
import eyeSvg from "../../assets/images/images/eye.svg"
import eyeCloseSvg from "../../assets/images/images/eyeClose.svg"
import flagSvg from "../../assets/images/images/flag.svg"
import emailSvg from "../../assets/images/images/email.svg"
import { setEventsDataStart, DecreptionEventStart, getDialCodeFromCountryCode } from '../../modules/utils'
import { mixpostApi } from '../../mixpanel/api'
import { fetchIp2Loc } from '../../actions/staticContent/getIp2Loc'
import LoginWithApple2 from '../common/site/Login/AppleLogin2'
class UserForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			togglePassword: false,
		};
	}

	togglePasswordHandler = () => {
		this.setState({
			togglePassword: !this.state.togglePassword,
		});
	};

	// componentDidMount() {
	// 	this.props.fetchIp2Loc().then(() => {
	// 		if (this.props.ip2Loc) {
	// 			console.log(this.props.ip2Loc)
	// 		}
	// 	})
	// }

	render() {
		const {
			onHandleSubmit,
			registerLoading,
			history,
			onSocialLoginSuccess,
			onSocialLoginFailure,
		} = this.props;
		const { togglePassword } = this.state;

		return (
			<React.Fragment>
				<Formik
					initialValues={{
						email: history.location?.state?.email || '',
						password: '',
						first_name: '',
						last_name: '',
						phone: '',
						phone_country_code: this.props.ip2Loc ? getDialCodeFromCountryCode(this.props.ip2Loc.country) : '1',
						website: '',
						language_code: 'en',
					}}
					validationSchema={registerValidations()}
					onSubmit={onHandleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						submitCount,
						setFieldValue
					}) => {
						const showError = (key) =>
							displayFormErrors(key, errors, touched, submitCount);
						return (
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-6 d-hide">
										<div className="form-group">
											<label htmlFor="first_name">First Name</label>
											<input
												type="text"
												className={errors.first_name ? "invalidFormInput form-control" : "form-control"}
												name="first_name"
												value={values.first_name}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder="First Name"
											/>
											{showError('first_name')}
										</div>
									</div>
									<div className="col-md-6 d-hide">
										<div className="form-group">
											<label htmlFor="last_name">Last Name</label>
											<input
												type="text"
												className={errors.last_name ? "invalidFormInput form-control" : "form-control"}
												name="last_name"
												value={values.last_name}
												onChange={handleChange}
												onBlur={handleBlur}
												placeholder="Last Name"
											/>
											{showError('last_name')}
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<label htmlFor="workemail">Email</label>
											<div className="input-wrap">
												<span><img src={emailSvg} alt="Email" /></span>
												<input
													type="text"
													name="email"
													className={errors.email ? "invalidFormInput form-control" : "form-control"}
													placeholder="admin@mybusiness.com"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{showError('email')}
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<label htmlFor="password">Password</label>
											<div className="input-wrap">
												<span><img src={togglePassword ? eyeSvg : eyeCloseSvg} className={!togglePassword && "mt-1"} alt="Eye" onClick={this.togglePasswordHandler} /></span>
												<input
													type={togglePassword ? 'text' : 'password'}
													// type="text"
													name="password"
													className={errors.password ? "invalidFormInput form-control" : "form-control"}
													placeholder="********"
													value={values.password}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{/* <i
										className={
											togglePassword
												? 'fa fa-eye-slash field-icon'
												: 'fa fa-eye field-icon'
										}
										aria-hidden="true"
										onClick={this.togglePasswordHandler}
									></i> */}
												{showError('password')}
											</div>
											<Link to="forgot-password" title="Forgot Password?" className="float-right forgot-link d-show">Forgot Password?</Link>
										</div>
									</div>
									<div className="col-md-12 d-hide">
										<div className="form-group">
											<label htmlFor="phone">Phone Number</label>
											<div className="">
												<div className="row" >
													<div className="col-md-3 col-sm-3 col-xs-3 register_phone" >
														<PhoneInput
															country='us'
															inputProps={{
																name: 'phone_country_code',
																tabindex: "-1"
															}}
															value={values.phone_country_code}
															onChange={(val) => {
																setFieldValue('phone_country_code', val);
															}}
															countryCodeEditable={false}
															enableSearch={true}
															inputClass="select2 disablePhoneInput"
															placeholder=""
															containerClass="form-group"
														/>
														{showError('phone_country_code')}
													</div>
													<div className="col-md-9 col-sm-9 col-xs-9">
														<CInput
															type="text"
															name="phone"
															placeholder="(202) 555-5555"
															autoComplete="phone"
															onChange={handleChange}
															onBlur={handleBlur}
															className={errors.phone ? "invalidFormInput form-control" : "form-control"}
															value={values.phone}
														/>
														{showError('phone')}
													</div>
												</div>
											</div>
											{/* <div className="input-group-outer">
									<div className="input-group">
										<div className="input-group-prepend">
											<span className="input-group-text"><img src={flagSvg} alt="Flag"/></span>
										</div>
										<input type="text" className="form-control" value="+1"/>
									</div>
									<input type="tel" className="form-control" placeholder="(202) 555-555"/>
								</div> */}
										</div>
									</div>
									{/* <CFormGroup>
									<CLabel className="lableHome" htmlFor="phone">Phone Number</CLabel>
									<div className="row" >
										<div className="col-md-3 pr-1 register_phone" >
											<PhoneInput
												country='us'
												inputProps={{
													name: 'phone_country_code',
												}}
												value={values.phone_country_code}
												onChange={(val) => {
													setFieldValue('phone_country_code', val);
												}}
												countryCodeEditable={false}
												enableSearch={true}
												inputClass="select2"
												placeholder=""
												style={{
													borderRadius: '5px',
													height: '43px'
												}}
											/>
											{showError('phone_country_code')}
										</div>
										<div className="col-md-9 pl-1">
											<CInput
												type="text"
												name="phone"
												placeholder="(202) 555-5555"
												autoComplete="phone"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.phone}
											/>
										</div>
									</div>
								</CFormGroup> */}
									{/* <CFormGroup>
									<CLabel className="lableHome" htmlFor="person">Website</CLabel>
									<CInput
										type="text"
										name="website"
										value={values.website}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="http://example.com"
									/>
								</CFormGroup> */}

									<div className="col-md-12">
										<div className="bottom-block">
											<LaddaButton
												type="submit"
												className="btn btn-primary btn-blue btn-text"
												loading={registerLoading}
												style={SLIDE_UP}
												spinnerSize={20}
												onClick={() => {
													// let eventData = DecreptionEventStart();
													// console.log(eventData);
													// if (!eventData.sign_up_create_account_tap) {
													// 	mixpostApi({},'sign_up_create_account_tap').then((data) => {
													// 		console.log(data);
													// 		eventData.sign_up_create_account_tap = true
													// 		setEventsDataStart(eventData)
													// 	})
													// }
												}}
											>
												Create a Free Account
											</LaddaButton>
											<p className="d-hide">By signing up, you agree to the <a href="https://support.fieldcamp.com/main/articles/1615890176578" target="_blank" title="Terms of Use">Terms of Use</a> & <a href="#" title="Privacy Policy" href="https://support.fieldcamp.com/main/articles/1615889170946" target="_blank">Privacy Policy</a></p>
											<div className="or-block"><span>OR</span></div>
											<CommonSocialLogin
												onSocialLoginSuccess={onSocialLoginSuccess}
												onSocialLoginFailure={onSocialLoginFailure}
											/>
											<LoginWithApple2 />
										</div>
									</div>

								</div>
							</form>
						);
					}}
				</Formik>

			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		ip2LocLoading: state.ip2Loc.isLoading,
		ip2Loc: state.ip2Loc?.data || null,
	};
};

const mapDispatchToProps = {
	fetchIp2Loc
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)