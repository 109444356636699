import {
    FETCH_IP2LOC_REQUEST,
    FETCH_IP2LOC_SUCCESS,
    FETCH_IP2LOC_FAILURE,
} from '../../types/staticContent';
import { getApi } from '../api';
import settings from '../../settings';
import axios from 'axios';

export const fetchIp2LocRequest = () => ({
    type: FETCH_IP2LOC_REQUEST,
});

export const fetchIp2LocSuccess = (data) => ({
    type: FETCH_IP2LOC_SUCCESS,
    data,
});

export const fetchIp2LocFailure = (error) => ({
    type: FETCH_IP2LOC_FAILURE,
    error,
});

export const fetchIp2Loc = (formData) => (dispatch) => {
    const passVal = {
        ...formData,
    };

    dispatch(fetchIp2LocRequest());
    console.log(process.env.REACT_APP_ENVIRONMENT)
    return axios.get(process.env.REACT_APP_ENVIRONMENT == "LIVE" ? `https://ipinfo.io?token=bc005cbb961df3` : `https://ipinfo.io?token=b9116f3bad89ca`, passVal)
        .then((data) => {
            if (data?.status === 200) {
                dispatch(fetchIp2LocSuccess(data));
            } else {
                dispatch(fetchIp2LocFailure(data));
            }
        })
        .catch((error) => {
            dispatch(fetchIp2LocFailure(error));
        });
};
