export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';

export const FETCH_SERVICE_ADD_REQUEST = 'FETCH_SERVICE_ADD_REQUEST';
export const FETCH_SERVICE_ADD_SUCCESS = 'FETCH_SERVICE_ADD_SUCCESS';
export const FETCH_SERVICE_ADD_FAILURE = 'FETCH_SERVICE_ADD_FAILURE';

export const FETCH_SERVICE_UPDATE_REQUEST = 'FETCH_SERVICE_UPDATE_REQUEST';
export const FETCH_SERVICE_UPDATE_SUCCESS = 'FETCH_SERVICE_UPDATE_SUCCESS';
export const FETCH_SERVICE_UPDATE_FAILURE = 'FETCH_SERVICE_UPDATE_FAILURE';

export const FETCH_SERVICE_DETAIL_REQUEST = 'FETCH_SERVICE_DETAIL_REQUEST';
export const FETCH_SERVICE_DETAIL_SUCCESS = 'FETCH_SERVICE_DETAIL_SUCCESS';
export const FETCH_SERVICE_DETAIL_FAILURE = 'FETCH_SERVICE_DETAIL_FAILURE';

export const FETCH_SERVICE_DELETE_REQUEST = 'FETCH_SERVICE_DELETE_REQUEST';
export const FETCH_SERVICE_DELETE_SUCCESS = 'FETCH_SERVICE_DELETE_SUCCESS';
export const FETCH_SERVICE_DELETE_FAILURE = 'FETCH_SERVICE_DELETE_FAILURE';
