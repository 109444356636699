import {
	FETCH_SOCIAL_LOGIN_REQUEST,
	FETCH_SOCIAL_LOGIN_SUCCESS,
	FETCH_SOCIAL_LOGIN_FAILURE,
} from '../../types/user';
import * as CryptoJS from 'crypto-js';
import settings from '../../settings';
import ReactGA from 'react-ga';

import {
	apiToastSuccess,
	apiToastError,
	deviceType,
	getDialCodeFromCountryCode,
	timeZones,
} from '../../modules/utils';
import { putApi } from '../api';
import Cookies from 'universal-cookie';
import { mixSignuppostApi } from '../../mixpanel/api';
import { countryList } from '../../modules/countryList';
import TagManager from 'react-gtm-module';
import queryString from "query-string"

export const fetchSocialLoginRequest = () => ({
	type: FETCH_SOCIAL_LOGIN_REQUEST,
});

export const fetchSocialLoginSuccess = (data) => ({
	type: FETCH_SOCIAL_LOGIN_SUCCESS,
	data,
});

export const fetchSocialLoginFailure = (error) => ({
	type: FETCH_SOCIAL_LOGIN_FAILURE,
	error,
});

export const fetchSocialLogin = (data) => (dispatch) => {
	const timeZone = timeZones.find(
		(d) => d?.value === Intl.DateTimeFormat().resolvedOptions().timeZone,
	);
	let formData = {
		...data,
		device_type: deviceType(),
		is_unlimited_activated: true,
		timezone:
			timeZone?.value === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZone?.value,
	};
	const cookies = new Cookies();

	dispatch(fetchSocialLoginRequest());

	let url_params = cookies.get('signup_urlParam') && queryString.parse(cookies.get('signup_urlParam'))

	if (url_params) {
		formData = {
			...formData,
			u_cname: url_params?.u_cname,
			u_referrer_url: url_params?.u_referrer_url,
			u_source: url_params?.u_source,
			u_medium: url_params?.u_medium,
			u_initial_referrer: url_params?.u_initial_referrer,
			u_referring_domain: url_params?.u_referring_domain,
		}
	}

	return putApi(settings.apiUrl.user.socialLogin, formData)
		.then((data) => {
			let eventData;
			if (data?.data?.responseData?.user?.authentication_token) {
				localStorage.setItem(
					'authToken',
					data.data.responseData.user.authentication_token,
				);
				localStorage.setItem(
					'branchId',
					data.data.responseData.user.branch_id,
				);
				const loginData = {
					id: data.data.responseData.user.id,
					user_type: data.data.responseData.user.user_type,
					authorities:
						data.data.responseData.user.user_profile_details.authorities,
					currency_code: data.data.responseData.user.currency_code,
					email: data.data.responseData.user.email,
					first_name: data.data.responseData.user.first_name,
					last_name: data.data.responseData.user.last_name,
					name: data.data.responseData.user.name,
					company_name:
						data.data.responseData.user.user_profile_details.organization_name,
					registration_date: data.data.responseData.user.registration_date,
					subscription_plan:
						data.data.responseData.user.subscription_details.purchase_sku_name,
					subscription_status:
						data.data.responseData.user.subscription_details
							.user_purchase_sku == 'com.freetrial'
							? data.data.responseData.user.subscription_details
								.free_trail_days > 0
								? 'Trialing'
								: 'Trial Ended'
							: data.data.responseData.user.subscription_details
								.purchase_sku_name &&
								data.data.responseData.user.subscription_details
									.remaining_days > 0
								? 'Active'
								: 'Expired',
					email_verified: data.data.responseData.user.is_verfied,
					subscription_expires_at:
						data.data.responseData.user.subscription_details
							.next_billing_timestamp,
					organization_name:
						data.data.responseData.user.user_profile_details.organization_name,
					phone_country_code:
						data.data.responseData.user.user_profile_details.phone_country_code,
					phone_number: data.data.responseData.user.user_profile_details.phone,
					alternate_phone_country_code:
						data.data.responseData.user.user_profile_details
							.alternate_phone_country_code,
					alternate_phone_number:
						data.data.responseData.user.user_profile_details.alternate_phone,
					company_country:
						data.data.responseData.user.user_profile_details.country,
					company_dial_code: countryList().find(
						(d) =>
							d.name ==
							data.data.responseData.user.user_profile_details.country,
					),
					location: {
						lat: data.data.responseData.user.user_profile_details.latitude,
						long: data.data.responseData.user.user_profile_details.longitude
					}
				};
				localStorage.setItem(
					'fieldCampLogin',
					CryptoJS.AES.encrypt(JSON.stringify(loginData), 'fieldCampLogin'),
				);
				eventData = {
					id: data.data.responseData.user.id,
					first_name: data.data.responseData.user.first_name,
					last_name: data.data.responseData.user.last_name,
					email: data.data.responseData.user.email,
					user_type: data.data.responseData.user.user_type,
				};
				let name =
					data.data.responseData.user.first_name +
					' ' +
					data.data.responseData.user.last_name;
				let phone_number =
					(data.data.responseData.user.user_profile_details.phone_country_code
						? data.data.responseData.user.user_profile_details
							.phone_country_code
						: '+1') +
					'-' +
					data.data.responseData.user.user_profile_details.phone;
				let alternate_phone_number =
					(data.data.responseData.user.user_profile_details
						.alternate_phone_country_code
						? data.data.responseData.user.user_profile_details
							.alternate_phone_country_code
						: '+1') +
					'-' +
					data.data.responseData.user.user_profile_details.alternate_phone;
				process.env.REACT_APP_ENVIRONMENT == 'LIVE' &&
					window.hj('identify', data.data.responseData.user.id, {
						user_id: data.data.responseData.user.id,
						email: data.data.responseData.user.email,
						name: data.data.responseData.user.name ? data.data.responseData.user.name : name,
						user_role: data.data.responseData.user.user_type,
						mixpanel_link: `https://mixpanel.com/project/2262869/view/2809985/app/profile#distinct_id=${data.data.responseData.user.id}`,
						registration_date: data.data.responseData.user.registration_date,
						subscription_plan:
							data.data.responseData.user.subscription_details
								.purchase_sku_name,
						subscription_status:
							data.data.responseData.user.subscription_details
								.user_purchase_sku == 'com.freetrial'
								? data.data.responseData.user.subscription_details
									.free_trail_days > 0
									? 'Trialing'
									: 'Trial Ended'
								: data.data.responseData.user.subscription_details
									.purchase_sku_name &&
									data.data.responseData.user.subscription_details
										.remaining_days > 0
									? 'Active'
									: 'Expired',
						organization_name: data.data.responseData.user.user_profile_details
							.organization_name
							? data.data.responseData.user.user_profile_details
								.organization_name
							: 'N/A',
						phone_country_code: data.data.responseData.user.user_profile_details
							.phone
							? data.data.responseData.user.user_profile_details
								.phone_country_code
								? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details
									.phone_country_code)
								: '+1'
							: 'N/A',
						phone_number: data.data.responseData.user.user_profile_details.phone
							? phone_number
							: 'N/A',
						alternate_phone_country_code: data.data.responseData.user
							.user_profile_details.alternate_phone
							? data.data.responseData.user.user_profile_details
								.alternate_phone_country_code
								? getDialCodeFromCountryCode(data.data.responseData.user.user_profile_details
									.alternate_phone_country_code)
								: '+1'
							: 'N/A',
						alternate_phone_number: data.data.responseData.user
							.user_profile_details.alternate_phone
							? alternate_phone_number
							: 'N/A',
					});
				process.env.REACT_APP_ENVIRONMENT == 'LIVE' &&
					new Cookies().set('userId', data.data.responseData.user.id);

			}
			if (data?.data?.responseCode === 200) {
				if (data?.data?.responseData?.is_new_user) {
					ReactGA.event({
						category: 'User',
						action: 'Registration',
						label: 'Registration with Gmail',
					});
					mixSignuppostApi(
						data?.data?.responseData?.user,
						'sign_up_complete',
						eventData,
						'gmail',
						cookies.get('signup_urlParam'),
					);
				}
				const tagManagerArgs = {
					gtmId: 'GTM-MLX77DM',
					dataLayer: {
						event: "Social Login",
						dlvuserid: data.data.responseData.user.id
					}
				}

				process.env.REACT_APP_ENVIRONMENT == "LIVE" && TagManager.initialize(tagManagerArgs)
				apiToastSuccess(data);
				dispatch(fetchSocialLoginSuccess(data));
				cookies.remove('signup_urlParam');
			} else {
				apiToastError(data);
				dispatch(fetchSocialLoginFailure(data));
			}
		})
		.catch((error) => {
			apiToastError(error);
			dispatch(fetchSocialLoginFailure(error));
		});
};
