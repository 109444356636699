export const FETCH_EARNING_REQUEST = 'FETCH_EARNING_REQUEST';
export const FETCH_EARNING_SUCCESS = 'FETCH_EARNING_SUCCESS';
export const FETCH_EARNING_FAILURE = 'FETCH_EARNING_FAILURE';

export const FETCH_EARNING_GRAPH_REQUEST = 'FETCH_EARNING_GRAPH_REQUEST';
export const FETCH_EARNING_GRAPH_SUCCESS = 'FETCH_EARNING_GRAPH_SUCCESS';
export const FETCH_EARNING_GRAPH_FAILURE = 'FETCH_EARNING_GRAPH_FAILURE';

export const FETCH_EARNING_LIST_REQUEST = 'FETCH_EARNING_LIST_REQUEST';
export const FETCH_EARNING_LIST_SUCCESS = 'FETCH_EARNING_LIST_SUCCESS';
export const FETCH_EARNING_LIST_FAILURE = 'FETCH_EARNING_LIST_FAILURE';
