import {
    FETCH_UPDATE_TEAM_SETTING_REQUEST,
    FETCH_UPDATE_TEAM_SETTING_SUCCESS,
    FETCH_UPDATE_TEAM_SETTING_FAILURE,
} from '../../types/setting';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
};

const UpdateTeamSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_UPDATE_TEAM_SETTING_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_UPDATE_TEAM_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data.data,
                error: null,
            };
        case FETCH_UPDATE_TEAM_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.error.data,
            };
        default:
            return state;
    }
};

export default UpdateTeamSettingReducer;
