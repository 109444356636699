import { Formik } from 'formik';
import React, { Component } from 'react';
import { displayFormErrors } from '../../modules/validations';
import { fetchCategory } from '../../actions/services/categories';
import { connect } from 'react-redux';

class ChooseCategory extends Component {
	componentDidMount() {
		this.props.fetchCategory();
	}

	renderCategory = () => {
		const { categoryList, selectCategory, selectedCategoryIds } = this.props;

		return (
			categoryList &&
			categoryList.length > 0 &&
			categoryList.map((category, key) => (
				<div
					className={`service_box ${
						selectedCategoryIds &&
						selectedCategoryIds.length &&
						selectedCategoryIds.includes(category.id)
							? 'selected'
							: ''
					}`}
					onClick={() => selectCategory(category.id)}
					key={category.id}
				>
					<img
						src={category.image_url}
						alt=""
						className="img-fluid"
						width="47px"
					/>
					<p>{category.name}</p>
					<div className="selected_check"></div>
				</div>
			))
		);
	};

	render() {
		const {
			onHandleChooseCategorySubmit,
			gotoNextStep,
			selectedCategoryIds,
			categoriesLoading,
		} = this.props;

		return (
			<React.Fragment>
				<h4 className="text-center pt-5 pb-5">Service Categories</h4>

				{categoriesLoading ? (
					<div className="spinner-class">
						<i
							className="fa fa-spin fa-spinner"
							style={{ fontSize: '24px', color: 'green' }}
							aria-hidden="true"
						></i>
					</div>
				) : (
					<Formik initialValues={{}} onSubmit={onHandleChooseCategorySubmit}>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							submitCount,
						}) => {
							const showError = (key) =>
								displayFormErrors(key, errors, touched, submitCount);

							return (
								<form onSubmit={handleSubmit}>
									<div className="form_input mb-3">
										<div
											className="choose_service_sec choose_service_box"
											id="service_select"
										>
											{this.renderCategory()}
										</div>
									</div>
									<div className="submit_btn modal_footer text-right">
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => {
												gotoNextStep('setupType');
											}}
										>
											Back
										</button>
										<button
											type="button"
											className={`btn btn-primary ml-2 ${
												!selectedCategoryIds || selectedCategoryIds.length <= 0
													? 'disabled'
													: ''
											}`}
											onClick={handleSubmit}
										>
											Next
										</button>
									</div>
								</form>
							);
						}}
					</Formik>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	let categoryList = [];

	if (state.categories.data && state.categories.data.responseData) {
		categoryList = state.categories.data.responseData;
	}
	return {
		categoriesLoading: state.categories.isLoading,
		categoriesData: state.categories.data,
		categoryList,
	};
};

const mapDispatchToProps = {
	fetchCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCategory);
