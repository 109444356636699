export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const FETCH_STATE_REQUEST = 'FETCH_STATE_REQUEST';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_STATE_FAILURE = 'FETCH_STATE_FAILURE';

export const FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE';

export const FETCH_ADMIN_AUTHORITY_REQUEST = 'FETCH_ADMIN_AUTHORITY_REQUEST';
export const FETCH_ADMIN_AUTHORITY_SUCCESS = 'FETCH_ADMIN_AUTHORITY_SUCCESS';
export const FETCH_ADMIN_AUTHORITY_FAILURE = 'FETCH_ADMIN_AUTHORITY_FAILURE';

export const FETCH_LANGUAGE_REQUEST = 'FETCH_LANGUAGE_REQUEST';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGE_FAILURE = 'FETCH_LANGUAGE_FAILURE';

export const FETCH_CURRENCY_REQUEST = 'FETCH_CURRENCY_REQUEST';
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
export const FETCH_CURRENCY_FAILURE = 'FETCH_CURRENCY_FAILURE';

export const FETCH_CANCEL_JOB_REASONS_REQUEST = 'FETCH_CANCEL_JOB_REASONS_REQUEST';
export const FETCH_CANCEL_JOB_REASONS_SUCCESS = 'FETCH_CANCEL_JOB_REASONS_SUCCESS';
export const FETCH_CANCEL_JOB_REASONS_FAILURE = 'FETCH_CANCEL_JOB_REASONS_FAILURE';