export const FETCH_BRANCH_LIST_REQUEST = 'FETCH_BRANCH_LIST_REQUEST';
export const FETCH_BRANCH_LIST_SUCCESS = 'FETCH_BRANCH_LIST_SUCCESS';
export const FETCH_BRANCH_LIST_FAILURE = 'FETCH_BRANCH_LIST_FAILURE';

export const FETCH_BRANCH_DETAIL_REQUEST = 'FETCH_BRANCH_DETAIL_REQUEST';
export const FETCH_BRANCH_DETAIL_SUCCESS = 'FETCH_BRANCH_DETAIL_SUCCESS';
export const FETCH_BRANCH_DETAIL_FAILURE = 'FETCH_BRANCH_DETAIL_FAILURE';


export const FETCH_ADD_BRANCH_REQUEST = 'FETCH_ADD_BRANCH_REQUEST';
export const FETCH_ADD_BRANCH_SUCCESS = 'FETCH_ADD_BRANCH_SUCCESS';
export const FETCH_ADD_BRANCH_FAILURE = 'FETCH_ADD_BRANCH_FAILURE';


export const FETCH_UPDATE_BRANCH_REQUEST = 'FETCH_UPDATE_BRANCH_REQUEST';
export const FETCH_UPDATE_BRANCH_SUCCESS = 'FETCH_UPDATE_BRANCH_SUCCESS';
export const FETCH_UPDATE_BRANCH_FAILURE = 'FETCH_UPDATE_BRANCH_FAILURE';

export const FETCH_CHANGE_BRANCH_STATUS_REQUEST = 'FETCH_CHANGE_BRANCH_STATUS_REQUEST';
export const FETCH_CHANGE_BRANCH_STATUS_SUCCESS = 'FETCH_CHANGE_BRANCH_STATUS_SUCCESS';
export const FETCH_CHANGE_BRANCH_STATUS_FAILURE = 'FETCH_CHANGE_BRANCH_STATUS_FAILURE';

export const FETCH_ADD_BRANCH_HEAD_REQUEST = 'FETCH_ADD_BRANCH_HEAD_REQUEST';
export const FETCH_ADD_BRANCH_HEAD_SUCCESS = 'FETCH_ADD_BRANCH_HEAD_SUCCESS';
export const FETCH_ADD_BRANCH_HEAD_FAILURE = 'FETCH_ADD_BRANCH_HEAD_FAILURE';

export const FETCH_UPDATE_BRANCH_HEAD_REQUEST = 'FETCH_UPDATE_BRANCH_HEAD_REQUEST';
export const FETCH_UPDATE_BRANCH_HEAD_SUCCESS = 'FETCH_UPDATE_BRANCH_HEAD_SUCCESS';
export const FETCH_UPDATE_BRANCH_HEAD_FAILURE = 'FETCH_UPDATE_BRANCH_HEAD_FAILURE';

export const FETCH_REMOVE_BRANCH_HEAD_REQUEST = 'FETCH_REMOVE_BRANCH_HEAD_REQUEST';
export const FETCH_REMOVE_BRANCH_HEAD_SUCCESS = 'FETCH_REMOVE_BRANCH_HEAD_SUCCESS';
export const FETCH_REMOVE_BRANCH_HEAD_FAILURE = 'FETCH_REMOVE_BRANCH_HEAD_FAILURE';