import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import LaddaButton, { SLIDE_UP } from '@zumper/react-ladda';
import { fetchForgetPassword } from '../../actions/user/forgetPassword';
import {
	displayFormErrors,
	forgotPasswordValidations,
} from '../../modules/validations';
import { Link } from 'react-router-dom';
import logoSvg from '../../assets/images/logo-sidebar.svg';
import emailSvg from '../../assets/images/images/email.svg';
import "../../assets/css/login_signup/style.css"

class ForgetPassword extends React.Component {
	constructor(props) {
		super(props);

		this.onHandleSubmit = this.onHandleSubmit.bind(this);
	}

	onHandleSubmit = (values) => {
		this.props.fetchForgetPassword(values).then(() => {
			const { history, forgetPasswordData } = this.props;
			if (forgetPasswordData) {
				history.push('/');
			}
		});
	};

	render() {
		const { forgetPasswordLoading = false, history } = this.props;

		return (
			<div className="wrapper-login">
				<div className="d-flex justify-content-center flex-wrap">
					<div className="col-lg-6 login-content">
						<div >
							<Link to='/' title="FieldCamp" className="logo"><img src={logoSvg} alt="FieldCamp" /></Link>
							<div className="login-box">
								<div className="d-flex justify-content-between align-items-center login-header">
									<div className="login-title">
										Forgot your password?
									</div>
								</div>
								<p className="font-weight-light pb-1 text-left f-family-text">
									Enter your email and we will send you instructions to reset your
									password.
								</p>
								<Formik
									initialValues={{
										email: '',
									}}
									validationSchema={forgotPasswordValidations()}
									onSubmit={this.onHandleSubmit}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										submitCount,
									}) => {
										const showError = (key) =>
											displayFormErrors(key, errors, touched, submitCount);
										return (
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label htmlFor="email">Email</label>
															<div className="input-wrap">
																<span><img src={emailSvg} alt="Email" /></span>
																<input
																	type="text"
																	name="email"
																	className={errors.email ? "invalidFormInput form-control" : "form-control"}
																	placeholder="admin@mybusiness.com"
																	value={values.email}
																	onChange={handleChange}
																	onBlur={handleBlur}
																/>
																{showError('email')}
															</div>
														</div>
													</div>
													<div className="col-md-12">
														<div className="bottom-block">
															<LaddaButton
																type="submit"
																className="btn btn-blue"
																loading={forgetPasswordLoading}
																style={SLIDE_UP}
																spinnerSize={20}
															>
																Reset password
															</LaddaButton>
															<Link to={`/login`} className="float-right forgot-link d-hide">
																Go back to Login
															</Link>
														</div>
													</div>
												</div>
												{/* <p className="pt-4 mt-1 text-center">
														<Link to={`/login`} className="btn-link f-family-text">
															Go back to login
														</Link>
													</p> */}
											</form>
										);
									}}
								</Formik>
							</div>
						</div>
					</div>
					<div className="col-lg-6 pd-0 bg-image-block">
					</div>
				</div>
			</div>

		);
	}
}

const mapStateToProps = (state) => {
	return {
		forgetPasswordLoading: state.forgetPassword.isLoading,
		forgetPasswordData: state.forgetPassword.data,
	};
};

const mapDispatchToProps = {
	fetchForgetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
