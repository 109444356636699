export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const FETCH_REGISTER_REQUEST = 'FETCH_REGISTER_REQUEST';
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS';
export const FETCH_REGISTER_FAILURE = 'FETCH_REGISTER_FAILURE';

export const FETCH_SOCIAL_LOGIN_REQUEST = 'FETCH_SOCIAL_LOGIN_REQUEST';
export const FETCH_SOCIAL_LOGIN_SUCCESS = 'FETCH_SOCIAL_LOGIN_SUCCESS';
export const FETCH_SOCIAL_LOGIN_FAILURE = 'FETCH_SOCIAL_LOGIN_FAILURE';

export const FETCH_VERIFY_USER_REQUEST = 'FETCH_VERIFY_USER_REQUEST';
export const FETCH_VERIFY_USER_SUCCESS = 'FETCH_VERIFY_USER_SUCCESS';
export const FETCH_VERIFY_USER_FAILURE = 'FETCH_VERIFY_USER_FAILURE';

export const FETCH_RESEND_OTP_REQUEST = 'FETCH_RESEND_OTP_REQUEST';
export const FETCH_RESEND_OTP_SUCCESS = 'FETCH_RESEND_OTP_SUCCESS';
export const FETCH_RESEND_OTP_FAILURE = 'FETCH_RESEND_OTP_FAILURE';

export const FETCH_FORGET_PASSWORD_REQUEST = 'FETCH_FORGET_PASSWORD_REQUEST';
export const FETCH_FORGET_PASSWORD_SUCCESS = 'FETCH_FORGET_PASSWORD_SUCCESS';
export const FETCH_FORGET_PASSWORD_FAILURE = 'FETCH_FORGET_PASSWORD_FAILURE';

export const FETCH_UPDATE_PROFILE_REQUEST = 'FETCH_UPDATE_PROFILE_REQUEST';
export const FETCH_UPDATE_PROFILE_SUCCESS = 'FETCH_UPDATE_PROFILE_SUCCESS';
export const FETCH_UPDATE_PROFILE_FAILURE = 'FETCH_UPDATE_PROFILE_FAILURE';

export const FETCH_UPDATE_PROFILE_ALL_USER_REQUEST =
	'FETCH_UPDATE_PROFILE_ALL_USER_REQUEST';
export const FETCH_UPDATE_PROFILE_ALL_USER_SUCCESS =
	'FETCH_UPDATE_PROFILE_ALL_USER_SUCCESS';
export const FETCH_UPDATE_PROFILE_ALL_USER_FAILURE =
	'FETCH_UPDATE_PROFILE_ALL_USER_FAILURE';

export const FETCH_PROFILE_DETAIL_REQUEST = 'FETCH_PROFILE_DETAIL_REQUEST';
export const FETCH_PROFILE_DETAIL_SUCCESS = 'FETCH_PROFILE_DETAIL_SUCCESS';
export const FETCH_PROFILE_DETAIL_FAILURE = 'FETCH_PROFILE_DETAIL_FAILURE';

export const ACTION_LOGOUT = 'ACTION_LOGOUT';

export const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE';

export const FETCH_CHANGE_PASSWORD_REQUEST = 'FETCH_CHANGE_PASSWORD_REQUEST';
export const FETCH_CHANGE_PASSWORD_SUCCESS = 'FETCH_CHANGE_PASSWORD_SUCCESS';
export const FETCH_CHANGE_PASSWORD_FAILURE = 'FETCH_CHANGE_PASSWORD_FAILURE';


export const FETCH_GET_TEAM_MEMBERS_REQUEST = 'FETCH_GET_TEAM_MEMBERS_REQUEST';
export const FETCH_GET_TEAM_MEMBERS_SUCCESS = 'FETCH_GET_TEAM_MEMBERS_SUCCESS';
export const FETCH_GET_TEAM_MEMBERS_FAILURE = 'FETCH_GET_TEAM_MEMBERS_FAILURE';


export const FETCH_GENERAL_DATA_REQUEST = 'FETCH_GENERAL_DATA_REQUEST';
export const FETCH_GENERAL_DATA_SUCCESS = 'FETCH_GENERAL_DATA_SUCCESS';
export const FETCH_GENERAL_DATA_FAILURE = 'FETCH_GENERAL_DATA_FAILURE';

export const FETCH_FC_SETUP_REQUEST = 'FETCH_FC_SETUP_REQUEST';
export const FETCH_FC_SETUP_SUCCESS = 'FETCH_FC_SETUP_SUCCESS';
export const FETCH_FC_SETUP_FAILURE = 'FETCH_FC_SETUP_FAILURE';