import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSocialLogin } from '../../../../actions/user/socialLogin';
import { getQueryParam , decryptLoginData,DecreptionEvents, setEventsData} from '../../../../modules/utils';
export class PartyReturn extends Component {
    constructor(props) {
		super(props);

		this.state = {};
	}
    componentDidMount() {
        console.log(window.location.href);
        console.log("****" , getQueryParam(this.props , 'id_token'));
        console.log("****" , getQueryParam(this.props , 'client_secret'));
        console.log("****" , getQueryParam(this.props , 'code'));
        console.log("****" , getQueryParam(this.props , 'state'));
        console.log("****" , getQueryParam(this.props , 'success'));
        console.log(getQueryParam(this.props , 'user'));


        if(getQueryParam(this.props , 'success')){
            let userdata = getQueryParam(this.props , 'user');
       
            const users = JSON.parse(userdata)
            
            let apiVal = {
                auth_token : getQueryParam(this.props , 'id_token'),
                provider: 'Apple',
            }
            if(userdata){
                apiVal = { ...apiVal ,  
                    first_name : users?.name.firstName,
                    last_name:users?.name.lastName, 
                    email: users?.email
                }
            }
            
            this.props.fetchSocialLogin(apiVal).then((data) => {
                console.log(data);
                this.props.history.push('/')
            })
        }else{
            this.props.history.push({
                pathname: '/register',
                state: { openDiv: 'chooseType' },
            });
        }
        

    }
    render(){
       return(<></>)
    }
}
const mapStateToProps = (state) => {
	return {
		socialLoginLoading: state.socialLogin.isLoading,
		socialLoginData: state.socialLogin.data,
		socialLoginError: state.socialLogin.error,
	};
};

const mapDispatchToProps = {

	fetchSocialLogin,
};

export default  connect(mapStateToProps, mapDispatchToProps)(PartyReturn);

