// import AppleSignin from 'react-apple-signin-auth';

// /** Apple Signin button */
// const MyAppleSigninButton = ({ ...rest }) => (
//   <AppleSignin
//     /** Auth options passed to AppleID.auth.init() */
//     authOptions={{
//       clientId: 'com.fieldcamp.fieldservicesoftware',
//       scope: 'email name',
//       redirectURI: 'https://dev7.spaceo.in/servicecall/api/v1/auth/apple',
//       state: 'apple_user_d7e45993754e171121c0e6bb6506448a9dfd3bd8537476ac9e0fa0169a7e',
//       nonce: 'nonce',
//       responseType:"code%20id_token",
//       responseMode:"form_post",
//       usePopup: true,
//     }}
//     /** General props */
//     uiType="dark"
//     /** className */
//     className="apple-auth-btn"
//     /** Allows to change the button's children, eg: for changing the button text */
//     buttonExtraChildren="Continue with Apple"
//     /** Checkout README.md for further customization props. */
//     /** Spread rest props if needed */
//     onSuccess={(response) => console.log(response)} // default = undefined
//     /** Called upon signin error */
//     onError={(error) => console.log("er***",error)} // default = undefined
//     /** Skips loading the apple script if true */
//     skipScript={false} // default = undefined
//     /** Apple image props */
//     iconProp={{ style: { marginTop: '10px' } }} // default = undefined
//     /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
//     render={(props) => <button {...props}>My Custom Button</button>}
//   />
// );

// export default MyAppleSigninButton;


import AppleSignin from 'react-apple-signin-auth';

/** Apple Signin button */
const MyAppleSigninButton = ({ ...rest }) => (
  <div className="social_media">


    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        clientId: 'com.fieldcamp.fieldservicesoftware',
        scope: 'email name',
        redirectURI: `${process.env.REACT_APP_API_ENDPOINT}/auth/apple`,
        state: 'apple_user_d7e45993754e171121c0e6bb6506448a9dfd3bd8537476ac9e0fa0169a7e',
        nonce: 'nonce',
        responseType: "code%20id_token",
        responseMode: "form_post",
        usePopup: false,
      }}
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Checkout README.md for further customization props. */
      /** Spread rest props if needed */
      // onSuccess={(response) => console.log(response)} // default = undefined
      /** Called upon signin error */
      // onError={(error) => console.log("er***",error)} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      iconProp={{ style: { marginTop: '10px' } }} // default = undefined
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(props) => <button {...props} className="apple_btn btn btn-block btn-google"><i className="icon"></i> Continue with Apple</button>}
    />
  </div>
);

export default MyAppleSigninButton;

