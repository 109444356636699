import {
	FETCH_CREATE_JOB_FORM_REQUEST,
	FETCH_CREATE_JOB_FORM_SUCCESS,
	FETCH_CREATE_JOB_FORM_FAILURE,
} from '../../types/jobForm';

const initialState = {
	data: null,
	error: null,
	isLoading: false,
};

const CreateJobFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CREATE_JOB_FORM_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_CREATE_JOB_FORM_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_CREATE_JOB_FORM_FAILURE:
			return {
				...state,
				isLoading: false,
				data: null,
				error: action.error.data,
			};
		default:
			return state;
	}
};

export default CreateJobFormReducer;
