import React, { Component } from 'react';

class ErrorBoundary extends Component {
	state = {
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo,
		});
	}

	render() {
		if (this.state.error) {
			return <div className="d-none">Something went wrong!</div>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
