import React, { Component } from 'react';
import companyImg from '../../assets/images/company.png';
import individualImg from '../../assets/images/individual.png';

export default class ChooseType extends Component {
	render() {
		const { selectedType, selectType, gotoNextStep } = this.props;
		return (
			<React.Fragment>
				<h4 className="text-center pt-5 pb-5">What is your account type?</h4>
				<div className="signup_card" id="signup_card">
					<div className="row">
						<div className="col-sm-6 col-12">
							<a
								onClick={() => selectType('individual')}
								className={`card_box ${
									selectedType == 'individual' ? 'selected' : ''
								}`}
							>
								<div className="icon">
									<img
										src={individualImg}
										alt=""
										className="img-fluid"
										width="48px"
									/>
								</div>
								<div className="title bold">Individual</div>
								<p className="font14">
									Are you an individual with expertise in services like repairs,
									maintenance, cleaning, plumbing, or any other service? yes?
									Create your individual account.
								</p>
								<div className="check_circle"></div>
							</a>{' '}
						</div>
						<div className="col-sm-6 col-12">
							<a
								onClick={() => selectType('company')}
								className={`card_box ${
									selectedType == 'company' ? 'selected' : ''
								}`}
							>
								<div className="icon">
									<img
										src={companyImg}
										alt=""
										className="img-fluid"
										width="66px"
									/>
								</div>
								<div className="title bold">Company</div>
								<p className="font14">
									Are you a service-based company with a dedicated team of field
									technicians and has a number of customer for service-booking
									on daily basis, then you have landed at the right place.
								</p>
								<div className="check_circle"></div>
							</a>
						</div>
					</div>
				</div>
				<div className="submit_btn modal_footer text-right">
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							gotoNextStep();
						}}
					>
						Back
					</button>
					<button
						type="button"
						className="btn btn-primary ml-2"
						onClick={() => {
							gotoNextStep('setupType');
						}}
					>
						Next
					</button>
				</div>
			</React.Fragment>
		);
	}
}
