export const FETCH_TAX_LIST_REQUEST = 'FETCH_TAX_LIST_REQUEST';
export const FETCH_TAX_LIST_SUCCESS = 'FETCH_TAX_LIST_SUCCESS';
export const FETCH_TAX_LIST_FAILURE = 'FETCH_TAX_LIST_FAILURE';

export const FETCH_ADD_TAX_REQUEST = 'FETCH_ADD_TAX_REQUEST';
export const FETCH_ADD_TAX_SUCCESS = 'FETCH_ADD_TAX_SUCCESS';
export const FETCH_ADD_TAX_FAILURE = 'FETCH_ADD_TAX_FAILURE';

export const FETCH_UPDATE_TAX_REQUEST = 'FETCH_UPDATE_TAX_REQUEST';
export const FETCH_UPDATE_TAX_SUCCESS = 'FETCH_UPDATE_TAX_SUCCESS';
export const FETCH_UPDATE_TAX_FAILURE = 'FETCH_UPDATE_TAX_FAILURE';

export const FETCH_DELETE_TAX_REQUEST = 'FETCH_DELETE_TAX_REQUEST';
export const FETCH_DELETE_TAX_SUCCESS = 'FETCH_DELETE_TAX_SUCCESS';
export const FETCH_DELETE_TAX_FAILURE = 'FETCH_DELETE_TAX_FAILURE';
