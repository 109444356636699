import { SLIDE_UP } from '@zumper/react-ladda/lib/constants';
import LaddaButton from '@zumper/react-ladda/lib/LaddaButton';
import { Formik } from 'formik';
import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import Timer from './Timer';
// import "../../../../assets/css/design.css"

export default class OtpVerificationForm extends Component {
	render() {
		const {
			onSubmitForm,
			otp,
			resendOtp,
			onCompleteTimer,
			userData,
			handleOtpChange,
			isEnableResendBtn,
			isTimerRunning,
			verifyUserLoading
		} = this.props;
		return (
			<Formik initialValues={{}} onSubmit={onSubmitForm}>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					submitCount,
				}) => {
					return (
						<form onSubmit={handleSubmit}>
							<span className="text-center pl-3 pr-3 font16" style={{ fontFamily: "Roboto" }}>
								To verify your email address please type 6 digit verification
								code which is sent to{' '}
								<span className="cyan_text" style={{ fontFamily: "Roboto" }}>
									{userData && userData.email}
								</span>
							</span>
							<div className="verifiction_block" style={{ fontFamily: "Roboto" }}>
								<div className="row">
									<OtpInput
										value={otp}
										onChange={handleOtpChange}
										numInputs={6}
										inputStyle="otp-input"
									/>
								</div>
								<div className="row">
									<div className="col-12 text-center">
										{isEnableResendBtn && (
											<a
												onClick={resendOtp}
												className="blue_text bold text-uppercase"
												style={{ fontFamily: "Roboto" }}
											>
												Resend Code
											</a>
										)}

										{isTimerRunning && (
											<span className="cyan_text bold ml-3">
												<Timer
													timeInSec={300}
													onCompleteTimer={onCompleteTimer}
													isEnableResendBtn={isEnableResendBtn}
												/>
											</span>
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-12 verification_text" style={{ fontFamily: "Roboto !important" }}>
										{!isTimerRunning && (
											<span className="red_text font14">
												Note: Haven't received the verification code? Please tap
												on Resend code button to get new verification code.
											</span>
										)}
									</div>
								</div>
							</div>
							{console.log("verifyUserLoading", verifyUserLoading)}
							<div className="submit_btn modal-footer text-right">
								<LaddaButton
									loading={verifyUserLoading}
									style={SLIDE_UP}
									spinnerSize={20}
									className="font14 btn btn-primary"
									onClick={handleSubmit}>
									Verify
								</LaddaButton>

							</div>
						</form>
					);
				}}
			</Formik>
		);
	}
}
