import {
  FETCH_MARK_AS_READ_ALL_NOTIFICATION_REQUEST,
  FETCH_MARK_AS_READ_ALL_NOTIFICATION_SUCCESS,
  FETCH_MARK_AS_READ_ALL_NOTIFICATION_FAILURE,
} from "../../types/notification";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
};

const MarkAsReadAllNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARK_AS_READ_ALL_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_MARK_AS_READ_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
      };
    case FETCH_MARK_AS_READ_ALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error.data,
      };
    default:
      return state;
  }
};

export default MarkAsReadAllNotificationReducer;
