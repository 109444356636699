export const FETCH_CUSTOMER_LIST_REQUEST = 'FETCH_CUSTOMER_LIST_REQUEST';
export const FETCH_CUSTOMER_LIST_SUCCESS = 'FETCH_CUSTOMER_LIST_SUCCESS';
export const FETCH_CUSTOMER_LIST_FAILURE = 'FETCH_CUSTOMER_LIST_FAILURE';

export const FETCH_CUSTOMER_ADD_REQUEST = 'FETCH_CUSTOMER_ADD_REQUEST';
export const FETCH_CUSTOMER_ADD_SUCCESS = 'FETCH_CUSTOMER_ADD_SUCCESS';
export const FETCH_CUSTOMER_ADD_FAILURE = 'FETCH_CUSTOMER_ADD_FAILURE';

export const FETCH_CUSTOMER_DELETE_REQUEST = 'FETCH_CUSTOMER_DELETE_REQUEST';
export const FETCH_CUSTOMER_DELETE_SUCCESS = 'FETCH_CUSTOMER_DELETE_SUCCESS';
export const FETCH_CUSTOMER_DELETE_FAILURE = 'FETCH_CUSTOMER_DELETE_FAILURE';

export const FETCH_CUSTOMER_DETAILS_REQUEST = 'FETCH_CUSTOMER_DETAILS_REQUEST';
export const FETCH_CUSTOMER_DETAILS_SUCCESS = 'FETCH_CUSTOMER_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_DETAILS_FAILURE = 'FETCH_CUSTOMER_DETAILS_FAILURE';

export const FETCH_CUSTOMER_UPDATE_REQUEST = 'FETCH_CUSTOMER_UPDATE_REQUEST';
export const FETCH_CUSTOMER_UPDATE_SUCCESS = 'FETCH_CUSTOMER_UPDATE_SUCCESS';
export const FETCH_CUSTOMER_UPDATE_FAILURE = 'FETCH_CUSTOMER_UPDATE_FAILURE';

