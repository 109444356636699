export const FETCH_MASTER_TRANSACTION_ADD_REQUEST = 'FETCH_MASTER_TRANSACTION_ADD_REQUEST';
export const FETCH_MASTER_TRANSACTION_ADD_SUCCESS = 'FETCH_MASTER_TRANSACTION_ADD_SUCCESS';
export const FETCH_MASTER_TRANSACTION_ADD_FAILURE = 'FETCH_MASTER_TRANSACTION_ADD_FAILURE';

export const FETCH_PAYMENT_CONFIG_ADD_REQUEST = 'FETCH_PAYMENT_CONFIG_ADD_REQUEST';
export const FETCH_PAYMENT_CONFIG_ADD_SUCCESS = 'FETCH_PAYMENT_CONFIG_ADD_SUCCESS';
export const FETCH_PAYMENT_CONFIG_ADD_FAILURE = 'FETCH_PAYMENT_CONFIG_ADD_FAILURE';

export const FETCH_SUBSCRIPTION_ADD_REQUEST = 'FETCH_SUBSCRIPTION_ADD_REQUEST';
export const FETCH_SUBSCRIPTION_ADD_SUCCESS = 'FETCH_SUBSCRIPTION_ADD_SUCCESS';
export const FETCH_SUBSCRIPTION_ADD_FAILURE = 'FETCH_SUBSCRIPTION_ADD_FAILURE';

export const FETCH_GENERATE_INVOICE_PAID_REQUEST = 'FETCH_GENERATE_INVOICE_PAID_REQUEST';
export const FETCH_GENERATE_INVOICE_PAID_SUCCESS = 'FETCH_GENERATE_INVOICE_PAID_SUCCESS';
export const FETCH_GENERATE_INVOICE_PAID_FAILURE = 'FETCH_GENERATE_INVOICE_PAID_FAILURE';
