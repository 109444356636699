import React from 'react';
import { connect } from 'react-redux';
import { fetchRegister } from '../../actions/user/register';
import { fetchSocialLogin } from '../../actions/user/socialLogin';
import { fetchUpdateProfile } from '../../actions/user/updateProfile';
import ChooseType from './ChooseType';
import SetupType from './SetupType';
import ChooseCategory from './ChooseCategory';
import UserForm from './UserForm';
import {
	formatPhoneNumber,
	getCountryCodeFromDialCode,
	getProviderName,
	isObjectIsEmpty,
	timeZones,
} from '../../modules/utils';
import logoSvg from '../../assets/images/logo-sidebar.svg';
import OtpVerification from './OtpVerification';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { dialog } from 'bootbox';
import BounceLoader from "react-spinners/BounceLoader";
import { fetchIp2Loc } from '../../actions/staticContent/getIp2Loc'

class Register extends React.Component {
	cookies = new Cookies();
	constructor(props) {
		super(props);

		this.state = {
			openDiv:
				props.location && props.location.state && props.location.state.openDiv
					? props.location.state.openDiv
					: '',
			role_type: 'individual',
			userFormValues: {},
			selectedCategoryIds: [],
		};

		this.onHandleSubmit = this.onHandleSubmit.bind(this);
		this.selectType = this.selectType.bind(this);
		this.onSocialLoginSuccess = this.onSocialLoginSuccess.bind(this);
		this.onSocialLoginFailure = this.onSocialLoginFailure.bind(this);
	}
	componentDidMount() {
		this.props.fetchIp2Loc().then(() => {
			if (this.props.ip2Loc) {
				console.log(this.props.ip2Loc)
			}
		})
		this.props.location.search &&
			this.cookies.set('signup_urlParam', this.props.location.search, {
				path: '/',
			});
		console.log(
			'URL PARAM INDEX',
			queryString.parse(this.cookies.get('signup_urlParam')),
		);
	}

	onHandleSubmit = (values) => {
		const timeZone = timeZones.find(
			(d) => d?.value === Intl.DateTimeFormat().resolvedOptions().timeZone,
		);
		console.log(values);
		const apiVal = {
			email: values.email,
			first_name: values.first_name,
			language_code: values.language_code || "en",
			last_name: values.last_name,
			password: values.password,
			phone: values.phone,
			phone_country_code: getCountryCodeFromDialCode(values.phone_country_code),
			website: values.website,
			language_code: 'en',
			currency_code: 'USD',
			date_format: '',
			timezone:
				timeZone?.value === 'Asia/Calcutta' ? 'Asia/Kolkata' : timeZone?.value,
		};
		this.props.fetchRegister(apiVal, this.props.ip2Loc).then(() => {
			const { registerData, history } = this.props;
			if (registerData) {
				const userData =
					registerData &&
						registerData.responseData &&
						registerData.responseData.user
						? registerData.responseData.user
						: {};
				if (userData && !isObjectIsEmpty(userData.user_profile_details)) {
					history.push('/');
				} else {
					this.gotoNextStep('chooseType');
				}
			}
		});
	};

	onHandleSetupTypeDataSubmit = (values) => {
		this.setState({ userFormValues: values }, () => {
			this.gotoNextStep('chooseCategory');
		});
	};

	onHandleChooseCategorySubmit = (values) => {
		const { userFormValues, selectedCategoryIds, role_type } = this.state;

		let formValues = {
			...userFormValues,
			user_category_ids: selectedCategoryIds.join(','),
			role_type: role_type,
			company_name: role_type == 'company' ? userFormValues.name : '',
			individual_name: role_type == 'individual' ? userFormValues.name : '',
			alternate_no: formatPhoneNumber(
				userFormValues.alternate_phone_country_code,
				userFormValues.alternate_no,
			),
			alternate_phone_country_code: getCountryCodeFromDialCode(
				userFormValues.alternate_phone_country_code,
			),
			phone: formatPhoneNumber(
				userFormValues.phone_country_code,
				userFormValues.phone,
			),
			phone_country_code: getCountryCodeFromDialCode(
				userFormValues.phone_country_code,
			),
		};

		this.props.fetchUpdateProfile(formValues).then(() => {
			const { history, updateProfileData } = this.props;

			if (updateProfileData) {
				history.push('/');
			}
		});
	};

	selectType = (role_type) => {
		this.setState({ role_type });
	};

	gotoNextStep = (openDiv) => {
		this.setState({ openDiv });
	};

	selectCategory = (item) => {
		var newArray = [...this.state.selectedCategoryIds];
		var indexItem = newArray.indexOf(item);
		indexItem === -1 ? newArray.push(item) : newArray.splice(indexItem, 1);
		this.setState({ selectedCategoryIds: newArray });
	};

	onSocialLoginSuccess = (user) => {
		const { history } = this.props;

		let values = {
			provider: getProviderName(user._provider),
			access_code: user._token.accessToken,
			first_name: user._profile.firstName,
			last_name: user._profile.lastName,
			email: user._profile.email,
		};
		this.props.fetchSocialLogin(values).then(() => {
			const { socialLoginData, socialLoginError } = this.props;
			if (socialLoginData) {
				const userData =
					socialLoginData &&
						socialLoginData.responseData &&
						socialLoginData.responseData.user
						? socialLoginData.responseData.user
						: {};
				if (userData && !isObjectIsEmpty(userData.user_profile_details)) {
					history.push('/');
				} else {
					this.gotoNextStep('chooseType');
				}
			} else if (
				socialLoginError &&
				socialLoginError.responseData &&
				socialLoginError.responseData.user
			) {
				if (
					socialLoginError.responseData.user.is_verification_required &&
					!socialLoginError.responseData.user.is_verfied
				) {
					this.gotoNextStep('otpVerification');
				} else {
					this.gotoNextStep('chooseType');
				}
			}
		});
	};

	onSocialLoginFailure(err) {
		console.log('TCL ~ UserForm ~ onLoginFailure ~ err', err);
	}
	render() {
		const { history, registerLoading, socialLoginError } = this.props;
		const {
			openDiv,
			role_type: selectedType,
			selectedCategoryIds,
		} = this.state;
		// $(".updates").css({ "height": $(window).height() });

		// $(window).resize(function () {
		// 	$(".updates").css({ "height": $(window).height() });
		// });

		return (
			<div>
				<div className="wrapper-login">
					<div className="d-flex justify-content-center flex-wrap">
						<div className="col-lg-6 login-content">
							<div>
								<Link to="/" title="FieldCamp" className="logo">
									<img src={logoSvg} alt="FieldCamp" />
								</Link>
								{this.props.ip2LocLoading ?
									<div className="preLoader text-center" style={{ marginRight: "33%", marginTop: "15rem" }}>
										<BounceLoader color="#0054D9" />
									</div> :
									<div className="login-box">
										<div className="d-flex justify-content-between align-items-center login-header">
											<div className="login-title login-title2">
												Sign Up
												<p
													className="font-weight-light pt-2"
													style={{ color: '#a1a3b4' }}
												>
													7 days free trial, no credit card required
												</p>
											</div>
											<p>
												<span className="account-text">
													Already have an account?
												</span>{' '}
												<Link
													to="/login"
													title="Login"
													className="swap-link swap-link2"
												>
													Login
												</Link>
											</p>
										</div>
										{openDiv == 'chooseType' ? (
											<ChooseType
												selectType={this.selectType}
												gotoNextStep={this.gotoNextStep}
												selectedType={selectedType}
											/>
										) : openDiv == 'setupType' ? (
											<SetupType
												selectedType={selectedType}
												onHandleSetupTypeDataSubmit={
													this.onHandleSetupTypeDataSubmit
												}
												gotoNextStep={this.gotoNextStep}
											/>
										) : openDiv == 'chooseCategory' ? (
											<ChooseCategory
												onHandleChooseCategorySubmit={
													this.onHandleChooseCategorySubmit
												}
												selectCategory={this.selectCategory}
												selectedCategoryIds={selectedCategoryIds}
												gotoNextStep={this.gotoNextStep}
											/>
										) : openDiv == 'otpVerification' ? (
											<OtpVerification
												userData={
													socialLoginError &&
													socialLoginError.responseData &&
													socialLoginError.responseData.user &&
													socialLoginError.responseData.user
												}
												gotoNextStep={this.gotoNextStep}
												history={history}
											/>
										) : (
											<UserForm
												onHandleSubmit={this.onHandleSubmit}
												onSocialLoginSuccess={this.onSocialLoginSuccess}
												onSocialLoginFailure={this.onSocialLoginFailure}
												registerLoading={registerLoading}
												history={history}
												ip2Loc={this.props.ip2Loc}
											/>
										)}
									</div>}
							</div>
						</div>
						<div className="col-lg-6 pd-0 bg-image-block"></div>
					</div>
				</div>
				{/* <div className="login">
				<div className="d-flex flex-wrap h-100">
					<div className="col-lg-6 p-5 d-flex bg-light">
						<div className="form m-auto">
							<p className="my-4 text-center d-block d-lg-none">
								<img className="header-logo" alt="FieldCamp" src={logoSvg} />
							</p>
							{openDiv == 'chooseType' ? (
								<ChooseType
									selectType={this.selectType}
									gotoNextStep={this.gotoNextStep}
									selectedType={selectedType}
								/>
							) : openDiv == 'setupType' ? (
								<SetupType
									selectedType={selectedType}
									onHandleSetupTypeDataSubmit={this.onHandleSetupTypeDataSubmit}
									gotoNextStep={this.gotoNextStep}
								/>
							) : openDiv == 'chooseCategory' ? (
								<ChooseCategory
									onHandleChooseCategorySubmit={
										this.onHandleChooseCategorySubmit
									}
									selectCategory={this.selectCategory}
									selectedCategoryIds={selectedCategoryIds}
									gotoNextStep={this.gotoNextStep}
								/>
							) : openDiv == 'otpVerification' ? (
								<OtpVerification
									userData={
										socialLoginError &&
										socialLoginError.responseData &&
										socialLoginError.responseData.user &&
										socialLoginError.responseData.user
									}
									gotoNextStep={this.gotoNextStep}
									history={history}
								/>
							) : (
												<UserForm
													onHandleSubmit={this.onHandleSubmit}
													onSocialLoginSuccess={this.onSocialLoginSuccess}
													onSocialLoginFailure={this.onSocialLoginFailure}
													registerLoading={registerLoading}
													history={history}
												/>
											)}
						</div>
					</div>
					<div className="col-lg-6 updates-wrapper">
						<div className="updates">
							<p className="logo-bar text-center d-none d-lg-block">
								<img
									className="custom-logo img-fluid"
									alt="FieldCamp"
									src={logoSvg}
								/>
							</p>
						</div>
					</div>
				</div>
			</div>
			 */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		registerLoading: state.register.isLoading,
		registerData: state.register.data,

		updateProfileLoading: state.updateProfile.isLoading,
		updateProfileData: state.updateProfile.data,

		socialLoginLoading: state.socialLogin.isLoading,
		socialLoginData: state.socialLogin.data,
		socialLoginError: state.socialLogin.error,

		ip2LocLoading: state.ip2Loc.isLoading,
		ip2Loc: state.ip2Loc?.data || null,
	};
};

const mapDispatchToProps = {
	fetchRegister,
	fetchUpdateProfile,
	fetchSocialLogin,
	fetchIp2Loc
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
