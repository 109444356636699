import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo@2x.png';

class SiteHeader extends Component {
	render() {
		return (
			<React.Fragment>
				<header>
					<nav className="navbar navbar-expand-sm fixed-top" id="mainNav">
						<div className="container-fluid">
							<Link to="/home" className="navbar-brand">
								<img src={logo} alt="Logo" className="img-fluid" />
							</Link>
							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#collapsibleNavbar"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="collapsibleNavbar">
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										<Link className="nav-link" to={`/services`}>
											Services
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`/features`}>
											Features
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`/pricing`}>
											Pricing
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" target="_blank" href="https://support.fieldcamp.com/contact-form">
											Support
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`/blog`}>
											Blog
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link header_btn" to={`/login`}>
											Login
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link signup_btn" to={`/register`}>
											Sign up
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</nav>
				</header>
			</React.Fragment>
		);
	}
}

export default SiteHeader;
