import React, { Component } from 'react';

import SiteHeader from '../common/site/Header';
import SiteWelcome from '../common/site/Welcome';
import SiteSupportMail from '../common/site/SupportMail';
import SiteFooter from '../common/site/Footer';

import blog from '../../assets/images/blog.png';
import blog2 from '../../assets/images/blog2.png';

import '../../assets/css/bootstrap.min.css';
// import '../../assets/fonts/maisonNeue/font.css';
import '../../assets/css/style.css';

export default class Blog extends Component {
	render() {
		return (
			<React.Fragment>
				<div className="wrapper">
					<SiteHeader />

					<div className="page_content">
						<SiteWelcome />
						<section className="center_blog_sec pb-80 pt-80">
							<div className="custom-container">
								<div className="row">
									<div className="col-12 col-lg-10 col-xl-8 mx-auto">
										<div className="content">
											<h3 className="bold text-center">Blogs</h3>
											<p className="mb-4 text-center">
												Lorem ipsum dolor sit amet, vel in sint delectus, eu mei
												stet cotidieque referrentur. Cu mea mucius docendi,
												veniam periculis eu cum, quo stet cotidieque
												referrentur.
											</p>
										</div>
									</div>
								</div>
								<div className="blog_detail">
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="blog_box">
												<div
													className="img"
													style={{ backgroundImage: `url(${blog2})` }}
												></div>
												<div className="desc">
													<h5 className="bold">Blogs</h5>
													<p>
														Lorem ipsum dolor sit amet, vel in sint delectus, eu
														mei stet cotidieque referrentur. Cu mea mucius
														docendi, veniam periculis eu cum, quo stet
														cotidieque referrentur. Cu mea mucius docendi
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<SiteSupportMail />
					</div>
				</div>
				<SiteFooter />
			</React.Fragment>
		);
	}
}
