import { combineReducers } from 'redux';

import LoginReducer from './user/login';
import LogoutReducer from './user/logout';
import RegisterReducer from './user/register';
import ForgetPasswordReducer from './user/forgetPassword';
import GenerateInvoicePaidReducer from './payment/bulkPayment';
import ThemeReducer from './theme';
import CategoriesReducer from './services/categories';
import ServicesReducer from './services/services';
import UpdateProfileReducer from './user/updateProfile';
import SocialLoginReducer from './user/socialLogin';
import VerifyUserReducer from './user/verifyUser';
import ResendOtpReducer from './user/resendOtp';
import ProfileDetailReducer from './user/getProfileDetail';
import JobCountReducer from './dashboard/jobCount';
import DashboardReducer from './dashboard/dashboard';
import ActiveStaffListReducer from './dashboard/activeStaffList';
import PaidUnpaidInvoiceReducer from './invoice/paidUnpaidInvoice';
import UnpaidInvoiceReducer from './invoice/UnpaidInvoice';
import EarningReducer from './earning/earning';
import BranchListReducer from './branches/list';
import CustomerListReducer from './customer/list';
import CountriesReducer from './common/countries';
import StateReducer from './common/state';
import CityReducer from './common/city';
import AddCustomerReducer from './customer/add';
import DeleteCustomerReducer from './customer/delete';
import CustomerDetailReducer from './customer/detail';
import UpdateCustomerReducer from './customer/update';
import TaxListReducer from './tax/list';
import JobListReducer from './jobs/list';
import DeleteJobReducer from './jobs/delete';
import AddJobReducer from './jobs/add';
import UpdateJobReducer from './jobs/update';
import UserCategoryReducer from './company/userCategory';
import CategoryStaffReducer from './jobs/categoryStaff';
import ServicesListReducer from './company/servicesList';
import JobDetailReducer from './jobs/detail';
import SendAttachmentReducer from './contract/sendAttachment';
import ManageJobReducer from './jobs/manageJob';
import StaffListReducer from './staff/list';
import DeleteStaffReducer from './staff/delete';
import AddStaffReducer from './staff/add';
import UpdateStaffReducer from './staff/update';
import StaffDetailReducer from './staff/detail';
import staffCompletedJobsReducer from './staff/completedJobs';
import ActiveInactiveStaffReducer from './staff/activeInactiveStaff';
import AdminAuthoritiesReducer from './common/adminAuthorities';
import PropertyListReducer from './property/list';
import AddPropertyReducer from './property/add';
import UpdatePropertyReducer from './property/update';
import DeletePropertyReducer from './property/delete';
import PropertyDetailReducer from './property/detail';
import LanguageReducer from './common/language';
import CurrencyReducer from './common/currency';
import UpdateProfileAllUserReducer from './user/updateProfileAllUser';
import CustomerSettingReducer from './setting/customerSetting';
import UpdateCustomerSettingReducer from './setting/updateCustomerSetting';
import UpdateJobSettingReducer from './setting/updateJobSetting';
import BranchDetailReducer from './branches/detail';
import AddBranchReducer from './branches/add';
import UpdateBranchReducer from './branches/update';
import ChangeBranchStatusReducer from './branches/changeStatus';
import AddBranchHeadReducer from './branches/addBranchHead';
import UpdateBranchHeadReducer from './branches/updateBranchHead';
import RemoveBranchHeadReducer from './branches/removeBranchHead';
import UpdateVerificationSettingReducer from './setting/updateVerificationSetting';
import AddTaxReducer from './tax/add';
import UpdateTaxReducer from './tax/update';
import DeleteTaxReducer from './tax/delete';
import UpdateStaticContentReducer from './staticContent/update';
import GetStaticContentReducer from './staticContent/getContent';
import AddServiceReducer from './services/add';
import UpdateServiceReducer from './services/update';
import ServiceDetailReducer from './services/detail';
import DeleteServiceReducer from './services/delete';
import DeleteCategoryReducer from './company/deleteCategory';
import UpdateUserCategoryReducer from './company/updateUserCategory';
import NotificationListReducer from './notification/list';
import DestroyAllNotificationReducer from './notification/destroyAll';
import MarkAsReadNotificationReducer from './notification/markAsRead';
import MarkAsReadAllNotificationReducer from './notification/markAsReadAll';
import EarningGraphReducer from './earning/earningGraph';
import EarningListReducer from './earning/earningList';
import ContactSupportReducer from './contact/contact';
import StripeCodeReducer from './setting/stripeCode';
import ChangePasswordReducer from './user/changePassword';
import TeamMemberReducer from './user/teamMember';
import CompanyDetailReducer from './company/comapnyDetail';
import AddGenereateInvoiceReducer from './invoice/generateInvoice';
import UpdateJobHistoryReducer from './jobs/jobHistryUpdate';
import AddMasterTransactionReducer from './payment/addMasterTransaction';
import AddPaymentConfigReducer from './payment/paymentConfig';
import CancelJobReasonsReducer from './common/cancelJobReasons';
import PlanListReducer from './subscription/getPlan';
import AddSubscriptionReducer from './payment/addSubscription';
import GeneralUserDataReducer from './user/generalData';
import VisitDetailReducer from './visit/detail';
import ScheduleJobListReducer from './jobs/scheduleJob';
import NotificationSettingReducer from './companyNotification/getSettings';
import EnableReminderReducer from './companyNotification/enableReminder';
import TemplateVariableReducer from './companyNotification/templateVariable';
import SettingDetailReducer from './companyNotification/settingDetail';
import ResetTemplateReducer from './companyNotification/resetTemplate';
import UpdateSettingReducer from './companyNotification/updateSetting';
import UpdateCustomerNotificationReducer from './customerNotification/updateSetting';
import CustomerNotificationSettingReducer from './customerNotification/getSettings';
import CreateJobFormReducer from './jobForms/createJobForm';
import DeleteJobFormReducer from './jobForms/deleteJobForm';
import GetJobFormReducer from './jobForms/getJobForm';
import ListJobFormReducer from './jobForms/listJobForm';
import UpdateJobFormReducer from './jobForms/updateJobForm';
import SubmitJobFormReducer from './jobForms/submitForm';
import GetTimezoneArrayReducer from './setting/getTimezone';
import GetEmailJobFormReducer from './jobForms/emailJobForm';
import getDownloadJobFormReducer from './jobForms/downloadJobForm';
import SendEmailJobFormReducer from './jobForms/sendEmailJobForm';
import DispatchMapReducer from './schedule/dispatchMap';
import CalendarSettingsReducer from './schedule/calendarSettings';
import Ip2LocReducer from './staticContent/ip2Loc';
import InvoiceDueDaysReducer from './invoice/invoiceDueDays';
import FCSetupReducer from './user/fcSetup';
import ChangeStaffPasswordReducer from './staff/changeStaffPassword';
import UpdateTeamSettingReducer from './setting/updateTeamSetting';
const rootReducer = combineReducers({
	login: LoginReducer,
	logout: LogoutReducer,
	register: RegisterReducer,
	socialLogin: SocialLoginReducer,
	forgetPassword: ForgetPasswordReducer,
	theme: ThemeReducer,
	updateProfileAllUser: UpdateProfileAllUserReducer,
	changePassword: ChangePasswordReducer,
	teamMembers: TeamMemberReducer,
	generalUserData: GeneralUserDataReducer,
	fcSetup: FCSetupReducer,

	categories: CategoriesReducer,
	services: ServicesReducer,
	updateProfile: UpdateProfileReducer,
	profileDetail: ProfileDetailReducer,
	verifyUser: VerifyUserReducer,
	resendOtp: ResendOtpReducer,

	// common
	countriesList: CountriesReducer,
	stateList: StateReducer,
	cityList: CityReducer,
	adminAuthorities: AdminAuthoritiesReducer,
	language: LanguageReducer,
	currency: CurrencyReducer,
	cancelJobReasons: CancelJobReasonsReducer,

	// dashboard
	jobCount: JobCountReducer,
	dashboard: DashboardReducer,
	activeStaffList: ActiveStaffListReducer,

	//invoice
	paidUnpaidInvoice: PaidUnpaidInvoiceReducer,
	getInvoice: AddGenereateInvoiceReducer,
	unPaidInvoice: UnpaidInvoiceReducer,
	invoiceDueDays: InvoiceDueDaysReducer,

	//earning
	earning: EarningReducer,
	earningGraph: EarningGraphReducer,
	earningList: EarningListReducer,

	//branches
	branchList: BranchListReducer,
	branchDetail: BranchDetailReducer,
	addBranch: AddBranchReducer,
	updateBranch: UpdateBranchReducer,
	changeBranchStatus: ChangeBranchStatusReducer,
	addBranchHead: AddBranchHeadReducer,
	updateBranchHead: UpdateBranchHeadReducer,
	removeBranchHead: RemoveBranchHeadReducer,

	//job
	jobList: JobListReducer,
	deleteJob: DeleteJobReducer,
	addJob: AddJobReducer,
	updateJob: UpdateJobReducer,
	categoryStaff: CategoryStaffReducer,
	jobDetail: JobDetailReducer,
	visitDetail: JobDetailReducer,
	manageJob: ManageJobReducer,
	jobHistory: UpdateJobHistoryReducer,
	scheduleJobList: ScheduleJobListReducer,
	// tax
	taxList: TaxListReducer,
	addTax: AddTaxReducer,
	updateTax: UpdateTaxReducer,
	deleteTax: DeleteTaxReducer,

	//company
	userCategory: UserCategoryReducer,
	servicesList: ServicesListReducer,
	deleteCategory: DeleteCategoryReducer,
	updateUserCategory: UpdateUserCategoryReducer,
	companyDetail: CompanyDetailReducer,

	// service
	addService: AddServiceReducer,
	updateService: UpdateServiceReducer,
	serviceDetail: ServiceDetailReducer,
	deleteService: DeleteServiceReducer,

	// customer
	customerList: CustomerListReducer,
	addCustomer: AddCustomerReducer,
	updateCustomer: UpdateCustomerReducer,
	deleteCustomer: DeleteCustomerReducer,
	customerDetail: CustomerDetailReducer,

	// property
	propertyList: PropertyListReducer,
	addProperty: AddPropertyReducer,
	updateProperty: UpdatePropertyReducer,
	deleteProperty: DeletePropertyReducer,
	propertyDetail: PropertyDetailReducer,
	generateInvoicePaid: GenerateInvoicePaidReducer,

	// contract
	sendAttachment: SendAttachmentReducer,

	// staff
	staffList: StaffListReducer,
	deleteStaff: DeleteStaffReducer,
	addStaff: AddStaffReducer,
	updateStaff: UpdateStaffReducer,
	staffDetail: StaffDetailReducer,
	staffCompletedJobs: staffCompletedJobsReducer,
	activeInactiveStaff: ActiveInactiveStaffReducer,
	changeStaffPassword: ChangeStaffPasswordReducer,

	//schedule
	dispatchMap: DispatchMapReducer,
	calendarSettings: CalendarSettingsReducer,

	// setting
	customerSetting: CustomerSettingReducer,
	updateCustomerSetting: UpdateCustomerSettingReducer,
	updateTeamSetting: UpdateTeamSettingReducer,
	updateJobSetting: UpdateJobSettingReducer,
	updateVerificationSetting: UpdateVerificationSettingReducer,
	StripeCodeData: StripeCodeReducer,
	getTimezoneArray: GetTimezoneArrayReducer,

	// static content
	updateStaticContent: UpdateStaticContentReducer,
	getStaticContent: GetStaticContentReducer,
	ip2Loc: Ip2LocReducer,

	// notification
	notificationList: NotificationListReducer,
	destroyAllNotification: DestroyAllNotificationReducer,
	markAsReadNotification: MarkAsReadNotificationReducer,
	markAsReadAllNotification: MarkAsReadAllNotificationReducer,

	// Contact Support
	ContactSupport: ContactSupportReducer,

	//payment
	addMasterTransaction: AddMasterTransactionReducer,
	paymentConfig: AddPaymentConfigReducer,
	addSubscription: AddSubscriptionReducer,

	//subscription
	getPlan: PlanListReducer,

	//visit
	visitDetail: VisitDetailReducer,

	//notification setting
	notificationSetting: NotificationSettingReducer,
	enableReminder: EnableReminderReducer,
	templateVariable: TemplateVariableReducer,
	settingDetail: SettingDetailReducer,
	resetTemplate: ResetTemplateReducer,
	updateSetting: UpdateSettingReducer,
	updateCustomerNotification: UpdateCustomerNotificationReducer,
	customerNotificationSetting: CustomerNotificationSettingReducer,
	// Job Form
	createJobForm: CreateJobFormReducer,
	deleteJobForm: DeleteJobFormReducer,
	getJobForm: GetJobFormReducer,
	getEmailJobForm: GetEmailJobFormReducer,
	sendEmailJobForm: SendEmailJobFormReducer,
	getDownloadJobForm: getDownloadJobFormReducer,
	listJobForm: ListJobFormReducer,
	updateJobForm: UpdateJobFormReducer,
	submitJobForm: SubmitJobFormReducer
});

export default rootReducer;
